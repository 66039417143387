import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import useAppStore from "../../../appStore";
import React, { useState } from 'react';


const TraceToolbar = (props) => {

  const {
    onStatusFilterSelected,
    onHttpMethodFilterSelected
  } = props
  const { whiteLabelProps } = useAppStore();

  const [selected, setSelected] = useState('All');
  const [httpMethodSelected, setHttpMethodSelected] = useState('All');

  const handleSelection = (event, newSelection) => {

      setSelected(newSelection);
      onStatusFilterSelected(newSelection);

  };

  const handleHttpMethodSelection = (event, newSelection) => {

    setHttpMethodSelected(newSelection);
    onHttpMethodFilterSelected(newSelection);

  };

  return (
    <>
      <ToggleButtonGroup
        value={httpMethodSelected}
        exclusive
        onChange={handleHttpMethodSelection}
        aria-label="Basic button group"
        sx={{
          height: '35px',
          marginRight: '15px',
        }}
      >
        <ToggleButton value="All" aria-label="All">
          All
        </ToggleButton>
        <ToggleButton value="get" aria-label="get">
          GET
        </ToggleButton>
        <ToggleButton value="post" aria-label="post">
          POST
        </ToggleButton>
        <ToggleButton value="put" aria-label="put">
          PUT
        </ToggleButton>
        <ToggleButton value="del" aria-label="del">
          DEL
        </ToggleButton>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        value={selected}
        exclusive
        onChange={handleSelection}
        aria-label="Basic button group"
        sx={{
          height: '35px',
        }}
      >
        <ToggleButton value="All" aria-label="All">
          All
        </ToggleButton>
        <ToggleButton value="Success" aria-label="Success">
          Success
        </ToggleButton>
        <ToggleButton value="Failed" aria-label="Failed">
          Failed
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default TraceToolbar;

