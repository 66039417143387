import React from 'react';
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

const SageScoreSummary = ({ failingCount, details }) => {

  const { t } = useTranslation();

  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
      <tr style={{ borderStyle: 'none', borderWidth: 0 }}>
        <td colSpan={2}>
          <Divider textAlign="center" className="pt-2 mt-8 fw-bold">{t('summary')}</Divider>
        </td>
      </tr>
      </thead>
      <tbody>
      {failingCount === 0 && (
        <tr>
          <td colSpan={2}>
              <span className="ps-2 text-muted">
                Everything looks good
              </span>
          </td>
        </tr>
      )}
      {details && (
        <tr style={{ borderStyle: 'none', borderWidth: 0 }}>
          <td colSpan={2} className="ps-2 pt-3">
            <ul style={{ listStyleType: 'disc', paddingLeft: '15px' }}>
              {details.map((detail, index) => (
                <li key={index} className="text-muted fst-italic">
                  {detail.message}
                </li>
              ))}
            </ul>
          </td>
        </tr>
      )}
      </tbody>
    </table>
  );
};

export default SageScoreSummary;





