import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { AppBar, IconButton, Toolbar, Typography, Button, TextField } from "@mui/material";
import { useSnapshot } from "valtio";
import { projectStore } from "../../../projectsStore";
import ModalTransition from "../../../../../shared/components/page/transitions/ModalTransition";

const CreateProjectPlan = ({ open, onActionClicked }) => {
  const snap = useSnapshot(projectStore);
  const { selectedPlan } = snap;

  const handleNameChange = (e) => {
    if (projectStore.selectedPlan) {
      projectStore.selectedPlan.name = e.target.value;
    }
  };

  const handleDescriptionChange = (e) => {
    if (projectStore.selectedPlan) {
      projectStore.selectedPlan.description = e.target.value;
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => onActionClicked('close')}
      TransitionComponent={ModalTransition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6">
            Create Plan
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 4 }}>

        {/* Name Field */}
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>Name</Typography>
          <TextField
            fullWidth
            value={selectedPlan?.name || ''}
            onChange={handleNameChange}
          />
        </Box>

        {/* Description Field */}
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>Description</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={selectedPlan?.description || ''}
            onChange={handleDescriptionChange}
          />
        </Box>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={() => onActionClicked('save')}>Save</Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateProjectPlan;


