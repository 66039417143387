import { CommandGetInspections } from "./commands/cmdGetInspections";
import { CommandCreateInspection } from "./commands/cmdCreateInspection";
import { CommandUpdateInspection } from "./commands/cmdUpdateInspection";
import { CommandCreateInspectedEntity } from "./commands/cmdCreateInspectedEntity";
import { CommandGetInspectedEntities } from "./commands/cmdGetInspectedEntities";
import { CommandGetInspectionDetails } from "./commands/cmdGetInspectionDetails";
import { CommandUpdateInspectedEntity } from "./commands/cmdUpdateInspectedEntity";
import { CommandGetInspectionSummary } from "./commands/cmdGetInspectionSummary";
import { CommandDeleteInspection } from "./commands/cmdDeleteInspection";
import { CommandDeleteInspectedEntity } from "./commands/cmdDeleteInspectedEntity";
import { Inspection } from "../../models/inspection.model";


export class InspectionService {

    async getInspections(provider_id: number | undefined,
                         location_id: number | undefined,
                         offset=0,
                         limit: number | undefined=undefined,
                         include_regular=true,
                         include_joint=true,
                         include_baseline=true,
                         employee: number | undefined = undefined,
                         last_x_days: number | undefined = undefined,
                         date_from: string | undefined = undefined,
                         date_to: string | undefined = undefined,
                         space: number | undefined = undefined,
                         space_type: number | undefined=undefined) {
        const cmd = new CommandGetInspections();
        return await cmd.run(provider_id, location_id, offset, limit, include_regular, include_joint, include_baseline, employee, last_x_days, date_from, date_to, space, space_type)
    }

    async createInspection(inspection: Inspection) {
        const cmd = new CommandCreateInspection()
        return await cmd.run(inspection)
    }

    async getInspectionDetails(inspection_id) {
        const cmd = new CommandGetInspectionDetails()
        return await cmd.run(inspection_id)
    }

    async updateInspection(inspection) {
        const cmd = new CommandUpdateInspection()
        return await cmd.run(inspection)
    }

    async getInspectedEntities(provider_id, location_id, offset=0, limit=10, search=null) {
        const cmd = new CommandGetInspectedEntities()
        return await cmd.run(provider_id, location_id)
    }

    async createInspectedEntity(inspection, inspectedEntity) {
        const cmd = new CommandCreateInspectedEntity()
        return await cmd.run(inspection, inspectedEntity)
    }

    async updateInspectedEntity(inspection, inspectedEntity) {
        const cmd = new CommandUpdateInspectedEntity()
        return await cmd.run(inspection, inspectedEntity)
    }

    async getInspectionSummary(provider_id, location_id) {
        const cmd = new CommandGetInspectionSummary()
        return await cmd.run(provider_id, location_id)
    }

    async deleteInspection(inspection_id) {
        const cmd = new CommandDeleteInspection()
        return await cmd.run(inspection_id)
    }

    async deleteInspectedEntity(inspection_id, entity_id) {
        const cmd = new CommandDeleteInspectedEntity()
        return await cmd.run(inspection_id, entity_id)
    }


}