import React, { useEffect, useState } from "react";
import { Comments } from "../social/comments/Comments";
import { Comment } from "../../models/comment.model";
import useAppStore from "../../../appStore";
import CustomLoadingDots from "../page/popup-dialog/CustomLoadingDots";
import { Divider } from "@mui/material";
import { CommentsService } from "../../services/comments/commentsService";
import { useTranslation } from "react-i18next";

const CommentsSection = (props) => {

    const {
        item,
        newComments,
        setNewComments,
        type='workitems'
    } = props

    const [comments, setComments] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const {t} = useTranslation();
    const service = new CommentsService();

    useEffect(() => {
        const fetchComments = async () => {
            setIsLoading(true);
            setComments([]);
            setNewComments([]);
            try {
                const items = await service.getComments(item, type);
                setComments(items);
            } catch (error) {
                if (error instanceof Error) {
                    console.log(error.message);
                }
            } finally {
                setIsLoading(false);
            }
        };
        fetchComments().then();
    }, []);

    if (isLoading) {
        return <CustomLoadingDots />;
    }

    const handleSubmitComment = async (commentText) => {
        let newComment = new Comment();
        newComment.comment_text = commentText;
        newComment.created_by = user.employee.person;
        newComment.organization = user?.organization;

            try {
                const service = new CommentsService();
                newComment = await service.postComment(item.id, newComment, type)
            } catch (e) {
                console.error('Error in handleSubmit:', e);
            }
        const updatedComments = [...newComments, newComment];
        setNewComments(updatedComments);
    };

    const combinedComments = [...comments, ...newComments];


    return (

        <>
            <div className="flex-column-fluid mb-10">
                <div className="flex-row-fluid">
                     <div style={{marginBottom: '25px'}}>
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-gray-700 mb-5">{t('comments')}</span>
                        </h3>
                        <Divider />
                    </div>
                </div>

                <div className="table-responsive flex-row-fluid mt-5">
                    <Comments
                      onSubmitComment={handleSubmitComment}
                      comments={combinedComments}
                    />
                </div>
            </div>
        </>
    )

}

export default CommentsSection
