import React from 'react';
import useAppStore from "../../../../../appStore";
import { useSnapshot } from "valtio";
import { suggestionsStore } from "../suggestionsStore";

const SuggestionProgress = () => {
  const { whiteLabelProps } = useAppStore();
  const snap = useSnapshot(suggestionsStore)

  return (
    <div className="progress-bar-container">
      <div className={`d-flex align-items-center flex-column`}>
        <div className={`d-flex justify-content-between fs-7 w-100 mb-1`}>
          <span className="fw-semibold" style={{color: 'gray'}}>{snap.suggestions.length} of {snap.suggestions.length} items remaining for today</span>
        </div>
        <div className={`mx-3 w-100 rounded`} style={{height: '25px', backgroundColor: '#e8e8e8'}}>
          <div className={`rounded`} role="progressbar" style={{ width: `${0}%`, height: '25px', backgroundColor: whiteLabelProps.primary_color}}></div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionProgress;