import React, { useEffect } from "react";
import { AppBar, Dialog, IconButton, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import { inspectionsStore } from "../../../../../inspections/inspectionsStore";
import { InspectionService } from "../../../../../../shared/services/inspections/inspectionService";
import useAppStore from "../../../../../../appStore";
import InspectionsCardList from "../../../../../inspections/components/inspections-list/InspectionsCardList";
import LoadMoreButton from "../../../../../../shared/components/buttons/LoadMoreButton";
import EditAreaInspectionPage from "../../../../../inspections/components/edit-inspection/EditAreaInspection";
import { useSnapshot } from "valtio";


const JointInspectionModal = ({ open, onActionClicked }) => {

  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const {selectedLocation, masterRefresh} = useAppStore();
  const snap = useSnapshot(inspectionsStore)
  const service = new InspectionService();
  const [page, setPage] = React.useState<number>(0);

  const fetchData = async () => {
    const limit = 10;
    const offset = page * limit;
    inspectionsStore.isLoading = true;
    if (page === 0) {inspectionsStore.inspections = []}
    try {
      const summaryData = await service.getInspectionSummary(user.organization.id, selectedLocation.id);
      const inspectionsData = await service.getInspections(user.organization.id, selectedLocation.id, offset, limit, false, true, false);
      inspectionsStore.summary = summaryData;
      inspectionsStore.inspections = [...inspectionsStore.inspections, ...inspectionsData];;
      inspectionsStore.isLoading = false;
    } catch (error) {
      console.error(error);
      inspectionsStore.isLoading = false;
    }
  };

  useEffect(() => {
    if (selectedLocation.id !== 0) {
      fetchData().then();
    }
  }, [user, page, masterRefresh]);

  return (
    <Dialog fullScreen open={open} onClose={() => onActionClicked('close')} TransitionComponent={ModalTransition} PaperProps={{ style: { overflowX: 'hidden' } }}>
      <AppBar sx={{ position: 'relative', mb: 2 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div style={{padding: '25px'}}>
        <InspectionsCardList />
        {snap.inspections.length % 10 === 0 &&
          <LoadMoreButton onClick={() => setPage(page + 1)} isLoading={snap.isLoading} />
        }
        {user.employee.id &&
          <EditAreaInspectionPage />
        }
      </div>

    </Dialog>
  );
};

export default JointInspectionModal;