import { Organization } from "./organization.model";
import { LocationModel } from "./location.model"
import { AccessLevel } from "./accessLevel.model";
import { Role } from "./role.model";
import { GroupEntry } from "./groupEntry.model";

export const PermissionLevels = {
    NONE: 0b0000,   // 0 - None
    VIEW: 0b0001,   // 1 - View
    UPDATE: 0b0010, // 2 - Update
    CREATE: 0b0100, // 4 - Create
    DELETE: 0b1000, // 8 - Delete

    /*
      Checking Permissions
      user?.permissions.hasAccess("Employees", PermissionLevels.UPDATE);

      Checking Mult Permissions
      user?.permissions.hasAccess("Employees", PermissionLevels.VIEW | PermissionLevels.CREATE);

     */
};



export class UserPermissions {
    customer_orgs: Organization[];
    role: Role;
    locations: LocationModel[];
    departments: GroupEntry[];
    access_levels: AccessLevel[];
    is_super_user : undefined | boolean;

    constructor() {
        this.customer_orgs = [];
        this.role = new Role();
        this.locations = [];
        this.departments = [];
        this.access_levels = [];
        this.is_super_user = undefined;

    }

    public hasAccess(feature, level= PermissionLevels.VIEW){

        this.setIsSuperUser()

        if(this.is_super_user)
            return true

        const filteredArray = this.access_levels.filter(obj => obj.feature.toLowerCase() === feature.toLowerCase());

        if (filteredArray.length === 0)
            return false

        let access_level = filteredArray[0].access_level

        let hasPermission = (access_level & level) !== 0;

        return hasPermission
    }

    private setIsSuperUser(){

        if (this.is_super_user !== undefined){
            return
        }

        const filteredArray = this.access_levels.filter(obj => obj.feature === '*');

        if(filteredArray.length > 0){
            this.is_super_user = true;
        }else{
            this.is_super_user = false;
        }

    }

}

