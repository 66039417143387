import { Box, useMediaQuery, useTheme } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React from "react";
import {
  formatSlashedDate,
  formatDateTime,
  getTimeDifferenceString, formatWrittenDate
} from "../../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import { useTranslation } from "react-i18next";
import useAppStore from "../../../../../../../appStore";
import { selected } from "@syncfusion/ej2-pivotview";
import ItemProperty from "../../../../../../../shared/components/forms/read-only/ItemProperty";

const CaseProperties = (props) => {
  const { caseToEdit } = props;
  const {t} = useTranslation();
  const submitInfo = getTimeDifferenceString(caseToEdit.create_date) + " (" + formatWrittenDate(caseToEdit.create_date) + ")";
  const author = (caseToEdit?.created_by.person.first_name || caseToEdit?.created_by.person.last_name)
    ? `${caseToEdit?.created_by.person.first_name} ${caseToEdit?.created_by.person.last_name}`
    : "You"

  return (
    <div className="mb-3">
      <Box className="d-flex flex-row mt-8 w-100">
        <div className="d-flex align-items-start" style={{ flexDirection: "column" }}>
          <ItemProperty colorClass={"symbol-label bg-light-success"} fontIcon={"fa-solid fa-pencil"}
                        name={t('created-by')} value={author} />
          <ItemProperty colorClass={"symbol-label bg-gray"} fontIcon={"fa-solid fa-location-dot"}
                        name={t('location')} value={caseToEdit?.location?.name || '--'} />
          <ItemProperty colorClass={"symbol-label bg-light-warning"} fontIcon={"fa-solid fa-folder"}
                        name={t('case-type')} value={caseToEdit?.case_type?.name} />
          <ItemProperty colorClass={"symbol-label bg-light-danger"} fontIcon={"fa-solid fa-calendar-days"}
                        name={t('submitted')} value={submitInfo} />
          <ItemProperty colorClass={"symbol-label bg-light-primary"} fontIcon={"fa-solid fa-bars-progress"}
                        name={t('status')} value={caseToEdit.status || "Created"} />
        </div>
      </Box>
    </div>
  );
};

export default CaseProperties;

