import React, { useState } from "react";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import WorkItemList from './components/work-item-list/WorkItemList';
import EditWorkItem from './components/edit-work-item/EditWorkItem';
import { WorkItem } from "../../shared/models/workItem.model";
import { WorkItemsService } from "../../shared/services/work-items/workItemsService";
import { ConfirmationDialog } from "../../shared/components/page/popup-dialog/ConfirmationDialog";
import { Box, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import useAppStore from "../../appStore";
import PrintableWorkItems from "./components/print-work-items/PrintableWorkItems";
import CloseWorkItemModal from "../dashboard/components/user-work-items/components/components/CloseWorkItemModal";
import { useTranslation } from "react-i18next";
import { workItemStore } from "./workItemStore";
import { useSnapshot } from "valtio";
import EmployeeQuickSelect from "../../shared/components/forms/widgets/selection-forms/EmployeeQuickSelect";
import WorkItemHistory from "./components/history/WorkItemHistory";


const WorkItemsPage = () => {

    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const snap = useSnapshot(workItemStore)
    const service = new WorkItemsService();
    const { t } = useTranslation();
    const { toggleMasterRefresh } = useAppStore();
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [workItemToDelete, setWorkItemToDelete] = useState<WorkItem | null>(null);

    const handleNewWorkItem = (e) => {
        workItemStore.workItemToEdit = new WorkItem();
        workItemStore.isEditing = true;
        workItemStore.open = true;
    }

    const handleCloseHistory = () => {
        workItemStore.historyOpen = false;
    }

    const handleCloseModals = (action) => {
        workItemStore.isEditing = false;
        workItemStore.open = false;
        workItemStore.closingDialogOpen = false;
        workItemStore.workItemToEdit = new WorkItem();
        if (action === 'save') {
            workItemStore.snackbarOpen = true;
        }
    };

    const handleCloseSnackbar = () => {
        workItemStore.snackbarOpen = false;
    };

    const handleConfirmDelete = async () => {
        if (workItemToDelete) {
            try {
                await service.deleteWorkItem(workItemToDelete.id);
                setConfirmDeleteOpen(false);
                toggleMasterRefresh();
            } catch (error) {
                console.error(error);
            }
        }
    };



    return (
      <>
          {!snap.isEditing && (
            <div>
                <PageHeader
                  title={t('work-items')}
                  actionButtonName={t('new-work-item')}
                  onActionClicked = {handleNewWorkItem}
                />
                <Box mt={3} mb={'120px'}>
                    <WorkItemList />
                </Box>
            </div>
          )}
          {snap.open && snap.workItemToEdit &&
            <EditWorkItem
              open={snap.open}
              onActionClicked={handleCloseModals}
            />
          }
          {snap.printModalOpen &&
            <PrintableWorkItems
              open={snap.printModalOpen}
              onActionClicked={() => workItemStore.printModalOpen = false}
            />
          }
          <Snackbar
            open={snap.snackbarOpen}
            autoHideDuration={800}
            onClose={handleCloseSnackbar}
            message="Work Item Saved"
          />
          {workItemToDelete && confirmDeleteOpen && (
              <ConfirmationDialog
              isOpen={confirmDeleteOpen}
              setIsOpen={setConfirmDeleteOpen}
              message="Delete Work Item?"
              description={`Are you sure you want to delete this work item? This action cannot be undone.`}
              onConfirmClicked={handleConfirmDelete}
              />
          )}
          {snap.closingDialogOpen && snap.workItemToEdit && user.employee.id &&
            <CloseWorkItemModal
              open={snap.closingDialogOpen}
              onActionClicked={() => workItemStore.closingDialogOpen = false}
            />
          }
          {snap.historyOpen &&
            <WorkItemHistory
              open={snap.historyOpen}
              onActionClicked={handleCloseHistory}
            />
          }
      </>
    )

}

export default WorkItemsPage
