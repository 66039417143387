import {
  calculateProgress,
  formatSlashedDate,
  getBuildingName, getEmployee, getEntityTypeName,
  getFloor
} from "../../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import React from "react";
import ProgressBar
  from "../../../../../../analytics/components/site-health/components/tabs/corp-overview/components/card/components/ProgressBar";
import { Tooltip, Chip } from '@mui/material';
import ActionButton from "../../../../../../../shared/components/buttons/ActionButton";
import { workItemStore } from "../../../../../workItemStore";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/helpers";

const WorkItemRow = ({ item }) => {
  const { value, colorClass, label } = calculateProgress(item.urgency);
  const isUnassigned = item.assigned_to_employees?.length === 0;

  const serviceItems = item.where_and_what?.[0]?.service_items || [];
  const displayedItems = serviceItems.slice(0, 2);
  const hiddenItems = serviceItems.slice(2);

  return (
    <tr>

      <td style={{ width: '25%' }}>
        <div className="d-flex flex-row align-items-center ps-5">
          {isUnassigned ? (
            <img src={toAbsoluteUrl("/white-labeling/verde/media/menu-icons/assignButton.png")} alt={"Employee"}
                 style={{
                   width: "45px",
                   height: "45px",
                   marginRight: "6px",
                   border: "1px solid #c4c4c4",
                   borderRadius: "50%",
                   cursor: "pointer",
                 }}
                 onClick={(e) => {
                   e.preventDefault();
                   workItemStore.handleAssignEmployees(item);
                 }}
            />
          ) : (
            <img src={toAbsoluteUrl("/white-labeling/verde/media/menu-icons/inspectorProfile.png")} alt={"Employee"}
                 style={{
                   width: "45px",
                   height: "45px",
                   marginRight: "6px",
                   border: "1px solid #c4c4c4",
                   borderRadius: "50%"
                 }} />
          )}

          <div className="fs-7 d-flex flex-column">
            <div>
              <span className="fw-bold">
              {item.assigned_to_employees?.length ? getEmployee(item) : "Unassigned"}
            </span>
            </div>
            <div>
              <span className="text-muted">
                assigned to
              </span>
            </div>
          </div>
        </div>

      </td>

      <td style={{ width: '20%' }}>
        <div className="d-flex align-items-center fs-7">
          <div className="d-flex justify-content-start flex-column">
            <span className="fw-bold">{getBuildingName(item)}</span>
            <span className="text-muted fst-italic">
              {getFloor(item)} / {getEntityTypeName(item)}
            </span>
          </div>
        </div>
      </td>

      {/* Chips Section */}
      <td style={{ paddingLeft: "10px", width: "28%" }}>
        <div className="d-flex flex-wrap" style={{ gap: "5px" }}>
          {displayedItems.map((serviceItem, index) => (
            <Chip
              key={index}
              label={serviceItem.entity_type?.name || "Unknown"}
              size="small"
              sx={{ backgroundColor: "#EFFDEE", color: "#006064" }}
            />
          ))}

          {/* If there's more than 2, show +X Chip */}
          {hiddenItems.length > 0 && (
            <Tooltip
              title={
                <div>
                  {hiddenItems.map((hItem, i) => (
                    <div key={i}>
                      {hItem.entity_type?.name || "Unknown"}
                    </div>
                  ))}
                </div>
              }
              placement="top"
              arrow
            >
              <Chip
                label={`+${hiddenItems.length}`}
                size="small"
                sx={{
                  backgroundColor: "#EFFDEE",
                  color: "#006064",
                  cursor: "pointer"
                }}
              />
            </Tooltip>
          )}
        </div>
      </td>

      {/* Progress Bar Section */}
      <td className={'ps-10'} style={{ width: "15%" }}>
        <ProgressBar
          progress={item.status === "Closed" ? 100 : value}
          label={item.status === "Closed" ? "Completed" : label}
          colorClass={item.status === "Closed" ? "bg-success" : colorClass}
          subtext={item.status === "Closed" ? "" : `Due: ${formatSlashedDate(item.due_date).slice(0, -5)}`}
        />
      </td>

      <td>
        <div className="d-flex justify-content-end flex-shrink-0 me-5">
          {item.status !== "Closed" &&
            <ActionButton
              iconType={"done"}
              onClick={(e) => {
                e.preventDefault();
                workItemStore.handleOpenClosingModal(item);
              }}
            />
          }
          <ActionButton
            iconType="view"
            onClick={(e) => {
              e.preventDefault();
              workItemStore.handleViewWorkItem(item);
            }}
          />
        </div>
      </td>

    </tr>
  );
};

export default WorkItemRow;
