import React from "react";
import { Box } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MediaRowSquares from "../../../../../shared/components/media/media-viewers/photos/MediaRowSquares";
import { formatSlashedDate, formatDateTime, getEmployee } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import WhereAndWhatRow from "../../edit-work-item/components/where-and-what/WhereAndWhatRow";
import useAppStore from "../../../../../appStore";

const PrintableWorkItemPage = (props) => {
  const { item } = props;
  const { appImages } = useAppStore();

  const isGoodCatch = item?.is_good_catch;
  const isOverdue = item?.is_overdue;
  const timeOverdue = item?.time_overdue;
  const timeRemaining = item?.time_remaining;
  const notes = item?.description;

  return (
    <div
      className="printable-work-item-page"
      style={{
        pageBreakAfter: 'always',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
      }}
    >
      {/* Header */}
      <header style={{ textAlign: "center", marginBottom: "30px" }}>
        <img src={appImages.iconWorkItems} alt="Company Logo" style={{ height: "50px", marginTop: "10px" }} />
        <h1 style={{ margin: 0 }}>Work Item Summary</h1>
        <p style={{ margin: 0 }}>Created {formatDateTime(item.create_date)}</p>
      </header>

      {/* Content */}
      <div className="flex-column-fluid" style={{ flex: 1 }}>
        {/* Where and What Section */}
        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>Where and What</h2>
          <div className="table-responsive flex-row-fluid mt-5 pe-3">
            <table className="table table-row-solid gs-0 gy-3 my-0" style={{ borderBottom: "1px solid #c7c7c7" }}>
              <tbody>
              {item.where_and_what.length === 0 ? (
                <tr>
                  <td colSpan={3} style={{ textAlign: "left", padding: "10px" }}>
                    Nothing specified
                  </td>
                </tr>
              ) : (
                item.where_and_what.map((entry, index) => (
                  <WhereAndWhatRow
                    key={index}
                    entry={entry}
                    isMobile={false}
                    isEditing={false}
                    onDelete={() => console.log('')}
                  />
                ))
              )}
              </tbody>
            </table>
          </div>
        </section>

        {/* Description Section */}
        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>Description</h2>
          <div>
            <p>{notes || "No additional information"}</p>
          </div>
        </section>

        {/* Details Section */}
        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>Details</h2>
          <Box
            className="d-flex mt-1 w-100"
            sx={{
              display: 'flex',
              justifyContent: 'space-between', // Ensures equal distribution across the row
              flexWrap: 'wrap', // Ensures responsiveness
            }}
          >
            {/* Status */}
            <div className="d-flex" style={{ width: '25%', alignItems: 'center' }}>
              <AccessTimeIcon sx={{ color: "#1976d2", fontSize: '32px', marginRight: '10px' }} />
              <div>
                <span className="text-gray-400 fw-semibold d-block fs-7">Work Status</span>
                <span className="text-gray-800 fw-bold mb-1 fs-6">{item.status}</span>
              </div>
            </div>

            {/* Urgency */}
            <div className="d-flex" style={{ width: '25%', alignItems: 'center' }}>
              <AccessTimeIcon sx={{ color: "#d32f2f", fontSize: '32px', marginRight: '10px' }} />
              <div>
                <span className="text-gray-400 fw-semibold d-block fs-7">Urgency</span>
                <span className="text-gray-800 fw-bold mb-1 fs-6">
          {isOverdue ? `${timeOverdue} Overdue` : `${timeRemaining} Remaining`}
        </span>
              </div>
            </div>

            {/* Marked Good Catch */}
            <div className="d-flex" style={{ width: '25%', alignItems: 'center' }}>
              <BookmarkIcon sx={{ color: "#ffa000", fontSize: '32px', marginRight: '10px' }} />
              <div>
                <span className="text-gray-400 fw-semibold d-block fs-7">Marked Good Catch</span>
                <span className="text-gray-800 fw-bold mb-1 fs-6">{isGoodCatch ? "Yes" : "No"}</span>
              </div>
            </div>

            {/* Assigned To */}
            <div className="d-flex" style={{ width: '25%', alignItems: 'center' }}>
              <AccountCircleIcon sx={{ color: "#388e3c", fontSize: '32px', marginRight: '10px' }} />
              <div>
                <span className="text-gray-400 fw-semibold d-block fs-7">Assigned To</span>
                <span className="text-gray-800 fw-bold mb-1 fs-6">
          {item.assigned_to_employees?.length ? getEmployee(item) : 'Unassigned'}
        </span>
              </div>
            </div>
          </Box>
        </section>


        {/* Photos Section */}
        {item.media && (
          <section style={{ marginBottom: '30px' }}>
            <h2 style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>Photos</h2>
            <div className="d-flex mt-10">
              <div className="d-flex justify-content-start flex-column">
                <MediaRowSquares itemMedia={item.media} captions={item.status === 'Closed'} size={225} />
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default PrintableWorkItemPage;
