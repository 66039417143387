import { CommandGetPlans } from "./commands/cmdGetPlans";
import { CommandCreatePlan } from "./commands/cmdCreatePlan";
import { CommandGetProjects } from "./commands/cmdGetProjects";
import { CommandGetMetrics } from "./commands/cmdGetMetrics";
import { CommandGetCategories } from "./commands/cmdGetCategories";
import { CommandCreateProject } from "./commands/cmdCreateProject";
import { CommandUpdateProject } from "./commands/cmdUpdateProject";
import { CommandDeleteProject } from "./commands/cmdDeleteProject";
import { CommandGetProject } from "./commands/cmdGetProject";


export class ProjectsService {

  async createPlan(plan) {
    const cmd = new CommandCreatePlan()
    const result = cmd.run(plan)
    return result
  }

  async getProject(plan_id, project_id) {
    const cmd = new CommandGetProject()
    const result = cmd.run(plan_id, project_id)
    return result
  }

  async createProject(project, plan_id) {
    const cmd = new CommandCreateProject()
    const result = cmd.run(project, plan_id)
    return result
  }

  async updateProject(project, plan_id) {
    const cmd = new CommandUpdateProject()
    const result = cmd.run(project, plan_id)
    return result
  }

  async deleteProject(project_id, plan_id) {
    const cmd = new CommandDeleteProject()
    const result = cmd.run(project_id, plan_id)
    return result
  }

  async getPlans(organization, location) {
    const cmd = new CommandGetPlans()
    const results = cmd.run(organization, location)
    return results
  }

  async getProjects(plan_id) {
    const cmd = new CommandGetProjects()
    const results = cmd.run(plan_id)
    return results
  }

  async getCategories(organization) {
    const cmd = new CommandGetCategories()
    const results = cmd.run(organization)
    return results
  }

  async getMetrics(category_id) {
    const cmd = new CommandGetMetrics()
    const results = cmd.run(category_id)
    return results
  }

}