import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import useAppStore from "../../../../../../appStore";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import SubHeader from "../../../../../../shared/components/page/headers/SubHeader";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { inspectionsStore } from "../../../../../inspections/inspectionsStore";
import { InspectionService } from "../../../../../../shared/services/inspections/inspectionService";
import InspectionCard from "../../../../../inspections/components/inspections-list/components/card/InspectionCard";
import { workItemStore } from "../../../../../work-items/workItemStore";
import { WorkItem } from "../../../../../../shared/models/workItem.model";
import EditWorkItem from "../../../../../work-items/components/edit-work-item/EditWorkItem";
import EditAreaInspectionPage from "../../../../../inspections/components/edit-inspection/EditAreaInspection";

const SuggestedInspectionsList = () => {

  const {selectedLocation} = useAppStore();
  const snap = useSnapshot(inspectionsStore);
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { masterRefresh } = useAppStore();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const service = new InspectionService();
  const [page, setPage] = useState(0);

  const workItemsSnap = useSnapshot(workItemStore);

  const handleCloseWorkItem = (action) => {
    workItemStore.isEditing = false;
    workItemStore.open = false;
    workItemStore.workItemToEdit = new WorkItem();
    if (action === 'save') {
      workItemStore.snackbarOpen = true;
    }
  }


  const fetchData = async () => {
    const limit = 10;
    const offset = page * limit;
    inspectionsStore.isLoading = true;
    if (page === 0) {inspectionsStore.inspections = []}
    try {
      const summaryData = await service.getInspectionSummary(user.organization.id, selectedLocation.id);
      const inspectionsData = await service.getInspections(user.organization.id, selectedLocation.id, offset, limit, true, true, true);
      inspectionsStore.summary = summaryData;
      inspectionsStore.inspections = [...inspectionsStore.inspections, ...inspectionsData];;
      inspectionsStore.isLoading = false;
    } catch (error) {
      console.error(error);
      inspectionsStore.isLoading = false;
    }
  };

  useEffect(() => {
    fetchData().then();

  }, [masterRefresh, page, selectedLocation.id]);



  function renderInspections() {
    return (
      <div>
        <div style={{marginTop: '25px'}}>
          <SubHeader title={t('inspections')} color="#777" margin={false} showAction={false} />
          <Divider sx={{ bgcolor: "black", marginBottom: "10px" }} />
          {snap.isLoading && (
            <div style={{ minHeight: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CustomLoadingDots />
            </div>
          )}

          {inspectionsStore.inspections.length === 0 ? (
            <div style={{ marginTop: '25px', marginBottom: '10px' }}>Nothing to show</div>
          ) : (
            inspectionsStore.inspections.map((inspection) => (
              <InspectionCard key={inspection.id} inspection={inspection} />
            ))
          )}

        </div>

      </div>
    );
  }

  return (
    <div>

      {error && <CustomError error={error} />}

      {!error && (
        <>
          <div>
            <>
              {renderInspections()}
            </>
          </div>

        </>
      )}

      {workItemsSnap.open && workItemsSnap.workItemToEdit &&
        <EditWorkItem
          open={workItemsSnap.open}
          onActionClicked={handleCloseWorkItem}
        />
      }

      {user.employee.id &&
        <EditAreaInspectionPage />
      }
    </div>
  );
};

export default SuggestedInspectionsList;