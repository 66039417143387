import React from "react";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import EmployeeList from "./components/employee-list/EmployeeList";
import { Employee } from "../../shared/models/employee.model";
import useAppStore from "../../appStore";
import EditEmployee from "./components/edit-employee/EditEmployee";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { employeeStore } from "./employeeStore";
import { EmployeeService } from "../../shared/services/employee/employeeService";
import { ConfirmationDialog } from "../../shared/components/page/popup-dialog/ConfirmationDialog";
import { PermissionLevels } from "../../shared/models/userPermissions.model";

const EmployeesPage = () => {
  const employeeService = new EmployeeService();
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const snap = useSnapshot(employeeStore)
  const { toggleMasterRefresh } = useAppStore();
  const {t} = useTranslation();
  const canEdit = user?.permissions.hasAccess("Employees", PermissionLevels.UPDATE);

  const handleNewEmployee = () => {
    employeeStore.editing = true;
    employeeStore.employeeToEdit = new Employee();
    employeeStore.open = true;
    employeeStore.mode = 'basic';
  };

  const handleCloseEditModal = (action) => {
    employeeStore.open = false;
    employeeStore.employeeToEdit = new Employee();
    employeeStore.editing = false;
    employeeStore.mode = 'basic';
    if (action === 'save') {
      toggleMasterRefresh();
    }
  };

  const handleViewEmployee = async (emp) => {
    if (!emp?.id) return;

    try {
      employeeStore.editing = false;
      employeeStore.employeeToEdit = await employeeService.getEmployee(emp?.id);
      employeeStore.mode = 'basic';
    } catch (error) {
      console.error('Failed to fetch employee:', error);
    } finally {
      employeeStore.open = true;
    }
  };


  const handleDeleteEmployeeClicked = (employee) => {
    employeeStore.employeeToEdit = employee;
    employeeStore.deleteOpen = true;
  };

  const handleConfirmDelete = async () => {
    if (snap.employeeToEdit) {
      try {
        await employeeService.deleteEmployee(snap.employeeToEdit.id);
        employeeStore.deleteOpen = false;
        toggleMasterRefresh();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSetDeleteOpen = (value: boolean) => {
    employeeStore.deleteOpen = value
  }

  return (
    <>
      <PageHeader
        title={t('employees')}
        actionButtonName={t('new-employee')}
        onActionClicked={handleNewEmployee}
        showAction={canEdit}
      />

      <EmployeeList onEdit={handleViewEmployee} onDelete={handleDeleteEmployeeClicked} />

      <EditEmployee
        open={snap.open}
        onActionClicked={handleCloseEditModal}
      />
      {snap.employeeToEdit.id !== -1 && snap.deleteOpen && (
        <ConfirmationDialog
          isOpen={snap.deleteOpen}
          setIsOpen={handleSetDeleteOpen}
          message="Delete Employee?"
          description={`Are you sure you want to delete ${snap.employeeToEdit.person.first_name + ' ' + snap.employeeToEdit.person.last_name}? This action cannot be undone.`}
          onConfirmClicked={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default EmployeesPage;

