import React, { useEffect, useState } from "react";
import { RelationshipService } from "../../../../../shared/services/relationships/relationshipService";
import useAppStore from "../../../../../appStore";
import EditContact from "./EditContact";
import { LocationContact } from "../../../../../shared/models/locationContact.model";
import SubHeader from "../../../../../shared/components/page/headers/SubHeader";
import ActionButton from "../../../../../shared/components/buttons/ActionButton";
import { RelationshipContact } from "../../../../../shared/models/relationshipContact.model";

const ContactSelect = ({ contact, setContact, item }) => {

  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const [availableContacts, setAvailableContacts] = useState<RelationshipContact[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(item.id !== 0 ? false : true);
  const [contactToEdit, setContactToEdit] = useState(contact)

  const service = new RelationshipService();

  const fetchContacts = async () => {
    setIsLoading(true);
    try {
      const contactsData = await service.getRelationshipContact(user?.organization.id);
      setAvailableContacts(contactsData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts().then();
  }, [refresh]);


  const handleContactSelected = (event) => {
    const selectedContactId = event.target.value;
    const selectedContact = availableContacts.find(c => c.id === parseInt(selectedContactId));

    if (selectedContact) {
      setContact(selectedContact);
    } else {
      setContact(null);
    }
  };

  const handleAddContact = () => {
    setContactToEdit(new LocationContact())
    setOpen(true);
  }

  const handleEditContact = () => {
    setContactToEdit(contact)
    setOpen(true);
  }

  const handleSaveAndRefresh = (response) => {
    console.log('handleSaveAndRefresh called with', response);
    setOpen(false);
    setRefresh(!refresh);
    setContact(response);
  };


  return (
    <div className="d-flex flex-column w-100">
      <SubHeader title={'Relationship Contact'} actionButtonName={'New'} onActionClicked={handleAddContact} />
      <div className="mb-5 mt-5">
        <div className="input-group d-flex flex-row align-items-center">
          <select
            className="form-select form-select-solid me-1"
            value={contact?.id || ""}
            onChange={handleContactSelected}
          >
            <option value="">Select...</option>
            {availableContacts.map((c) => (
              <option key={c.id} value={c.id}>
                {`${c.person.first_name} ${c.person.last_name} (Level ${c.relationship_level})`}
              </option>
            ))}
          </select>

          {contact && contact?.id !== 0 &&
            <ActionButton iconType="pencil" onClick={(e) => {
              e.preventDefault();
              handleEditContact();
            }} />
          }

        </div>
      </div>

      <EditContact contactToEdit={contactToEdit} open={open} onClose={() => setOpen(false)} onSave={handleSaveAndRefresh} />

    </div>
  );

}

export default ContactSelect;
