import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { ProjectSerializer } from "../../../models/serializers/projectSerializer";


export class CommandCreateProject {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(plan_id) {
    return `${this.SERVICE}/project_plans/${plan_id}/projects/`;
  }

  async run(project, plan_id) {

    try {
      const url = this.getUrl(plan_id)
      const data = ProjectSerializer.serialize(project)
      const response = await httpClient.post(url, data);
      return this.deserialize(response.data);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }

  }

  deserialize(data) {
    const serializer = new ProjectSerializer()
    const plan = serializer.deserialize(data)
    return plan
  }

}