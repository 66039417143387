import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { ProjectCategorySerializer } from "../../../models/serializers/projectCategorySerializer";
import { Organization } from "../../../models/organization.model";

export class CommandGetCategories {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE}/project_plans/ranking_categories/`;
  }

  async run(organization: Organization) {

    const url = this.getUrl()

    const params = {
      params: {
        organization: organization.id,
      }
    }
    try {
      const response = await httpClient.get(url, params);
      return this.deserializePosts(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserializePosts(data) {
    const serializer = new ProjectCategorySerializer()
    const cats = serializer.deserializeList(data)
    return cats
  }

}