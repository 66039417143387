import React from "react";
import { Box, Paper, Typography } from "@mui/material";


const bubbleStyle = {
  backgroundColor: "#f6f6f6",
  marginTop: 4,
  padding: 4,
  borderRadius: '5px',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '100%',
    left: '50%',
  }
};

const avatarStyle = {
  position: 'absolute',
  top: -35,
  left: 5,
  width: 75,
  height: 65,
};

const InfoPrompter = ({ prompt }) => {

  return (
    <Box sx={{ padding: 1, paddingTop: 3 }}>
      <Paper elevation={3} sx={bubbleStyle}>
        <Box sx={{
          ...avatarStyle,
          borderRadius: '50%',
          overflow: 'hidden'
        }}>
          <img
            src={"/white-labeling/verde/media/menu-icons/priorityIcon.png"}
            alt="Priority"
            style={{
              marginLeft: '3px',
              height: '100%',
              width: '85%',
              scale: '85%',
              objectPosition: 'center'
            }}
          />
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {prompt}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default InfoPrompter;