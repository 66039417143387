import React, { ReactElement, useState } from "react";
import useAppStore from "../../appStore";
import { Grid, Box, Typography, Divider, IconButton, useMediaQuery, useTheme } from "@mui/material";
import NavigationTile from "../../shared/components/page/tiles/NavigationTile";
import ComponentViewer from "../../shared/components/page/modal/ComponentViewer";
import SafetyDataSheets from "./components/SafetyDataSheets";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import PdfViewer from "../../shared/components/media/media-viewers/PdfViewer";
import { Case } from "../../shared/models/case.model";
import SafetyHeader from "./components/SafetyHeader";
import Slide from "@mui/material/Slide";
import { Close } from "@mui/icons-material";
import TellSageModal from "../cases/components/tell-sage/TellSageModal";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import casesStore from "../cases/caseStore";

const SafetyPage: React.FC = () => {
  const snapshot = useSnapshot(casesStore);
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { appImages } = useAppStore();
  const {t} = useTranslation();
  const [selectedPage, setSelectedPage] = useState<ReactElement | null>(null);
  const [incidentFormOpen, setIncidentFormOpen] = useState(false);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const handleOpenPdf = (facilities: boolean) => {
    const pdfUrl = facilities
      ? "/white-labeling/verde/media/documents/facilities_claim.pdf"
      : "/white-labeling/verde/media/documents/clean_claim.pdf";

    setSelectedPage(
      <PdfViewer open={true} url={pdfUrl} onClose={() => setSelectedPage(null)} />
    );
  };

  const handleNewIncident = () => {
    casesStore.caseToEdit = new Case();
    setIncidentFormOpen(true);
  };

  const handleFormAction = () => {
    setIncidentFormOpen(false);
    casesStore.caseToEdit = new Case();
  };


  return (
    <div style={{ padding: !user ? '15px' : '', marginTop: !user ? '0px' : '-20px' }}>
      <PageHeader title={t('safety')} showAction={false} divider={false} />
      <Divider sx={{ marginBottom: '15px', marginTop: '10px', backgroundColor: 'black' }} />
      <SafetyHeader handleNewIncident={handleNewIncident} />
      <>
        <div style={styles.tilesContainer}>
          <Box style={styles.tileSection}>
            <Typography variant="h6" style={styles.sectionHeader}>
              Verde
            </Typography>
            <Divider sx={{ marginBottom: '15px', backgroundColor: 'black' }} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} onClick={() => setSelectedPage(<SafetyDataSheets />)}>
                <NavigationTile title={t('safety-data-sheets')} image={appImages.iconSafety} width={40} height={44} />
              </Grid>
              <Grid item xs={12} md={6} onClick={handleNewIncident}>
                <NavigationTile title={t('incident-form')} image={appImages.iconAlert} width={40} height={40} />
              </Grid>
            </Grid>
          </Box>
          <Box style={styles.tileSection}>
            <Typography variant="h6" style={styles.sectionHeader}>
              {t('resources')}
            </Typography>
            <Divider sx={{ marginBottom: '15px', backgroundColor: 'black' }} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} onClick={() => handleOpenPdf(false)}>
                <NavigationTile title="Verde Clean LLC" subTitle={t('claim-card')} image={appImages.iconPdfLarge} width={40} height={40} />
              </Grid>
              <Grid item xs={12} md={4} onClick={() => handleOpenPdf(true)}>
                <NavigationTile title="Verde Facilities LLC" subTitle={t('claim-card')} image={appImages.iconPdfLarge} width={40} height={40} />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                onClick={() => window.open('https://www.concentra.com/urgent-care-centers/', '_blank', 'noopener,noreferrer')}
                style={{ cursor: 'pointer' }}
              >
                <NavigationTile title="" image={appImages.iconHospital} width={150} height={50} />
              </Grid>
            </Grid>
          </Box>
        </div>
      </>
      {selectedPage && (
        <ComponentViewer selectedPage={selectedPage} onActionClicked={() => setSelectedPage(null)} />
      )}
      <Slide direction="up" in={incidentFormOpen} mountOnEnter unmountOnExit timeout={{ enter: 300, exit: 300 }}>
        <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, top: 0, zIndex: 998 }}>
          {incidentFormOpen && <TellSageModal onClose={() => setIncidentFormOpen(false)} incident={true} />}
        </div>
      </Slide>
      {incidentFormOpen && (
        <IconButton
          aria-label="menu"
          onClick={() => setIncidentFormOpen(false)}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
            width: !isMobile ? 80 : 62,
            height: !isMobile ? 78 : 61,
            padding: 0,
            boxShadow: '0px 7px 11px rgba(0, 0, 0, 0.33)',
            borderRadius: '50%',
            border: '2px solid #077c5e',
            backgroundColor: '#18aa79',
            '&:hover': {
              backgroundColor: '#18aa79',
            }
          }}
        >
          <Close sx={{ color: 'white' }} />
        </IconButton>
      )}

    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  tilesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: 20,
  },
  tileSection: {
    borderRadius: '5px',
    marginBottom: '20px'
  },
  sectionHeader: {
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#999'
  },
};

export default SafetyPage;

