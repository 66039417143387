import React from 'react';
import DummyGanttChart from "./DummyGanttChart";

const ProjectsPlanTab = () => {

    return (
      <>
        <DummyGanttChart />
      </>
    );
};

export default ProjectsPlanTab;
