import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { CommentSerializer } from "../../../models/serializers/commentSerializer";

export class CommandPostComment {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(itemId, type) {
    return  `${this.SERVICE}/${type}/${itemId}/comments/`;
  }

  async run(itemId, comment, type) {
    const url = this.getUrl(itemId, type);

    const tracer = useTracer.getState();
    tracer.trace('Comments', 'Api', `POST ${url}`);

    try {
      let data = CommentSerializer.serialize(comment);
      const response = await httpClient.post(url, data);

      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Comments', 'Error', `${error}`);
      console.error('Error when posting comment:', error);
      throw error;
    } finally {
      tracer.trace('Comments', 'Api', 'Success');
    }
  }

  deserialize(data) {
    const serializer = new CommentSerializer();
    const comment = serializer.deserialize(data); // Ensure this method exists and works as expected
    return comment;
  }


}