import { Organization } from "./organization.model";
import { LocationModel } from "./location.model";

export class ProjectPlan {
  id: number;
  name: string;
  description: string;
  provider: Organization;
  location: LocationModel;

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.provider = new Organization();
    this.location = new LocationModel();
  }

}