import React from "react";
import { Box, Card, CardContent, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  getEmployee,
  getFloor, getEntityTypeName, getBuildingName, formatSlashedDate, calculateProgress
} from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import { workItemStore } from "../../../../workItemStore";
import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import ProgressBar
  from "../../../../../analytics/components/site-health/components/tabs/corp-overview/components/card/components/ProgressBar";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { format } from "date-fns";
import MediaRowSquares from "../../../../../../shared/components/media/media-viewers/photos/MediaRowSquares";


const WorkItemCard = (props) => {
  const {
    item,
    useSmallVersion = false,
  } = props;

  const { t } = useTranslation();
  const snap = useSnapshot(workItemStore);
  const condensedCard = useMediaQuery(useTheme().breakpoints.only('xs')) || useSmallVersion;
  const isClosed = (item.status === "Closed");
  const serviceItems = item.where_and_what?.[0]?.service_items || [];
  const formattedDate = format(new Date(item.create_date), "MMM d, yyyy");
  const isUnassigned = item.assigned_to_employees?.length === 0;
  const { value, colorClass, label } = calculateProgress(item.urgency);


  return (
    <>
      <Card variant="outlined" sx={{
        marginBottom: "25px",
        boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
        position: "relative",
        paddingBottom: 0
      }}>

        <Box sx={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "#f6f6f6",
          color: "black",
          padding: "5px 8px",
          borderRadius: "0 0 6px 0",
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}>
          {isUnassigned ? (
            <img src={toAbsoluteUrl("/white-labeling/verde/media/menu-icons/assignButton.png")} alt={"Employee"}
                 style={{
                   width: "40px",
                   height: "40px",
                   marginRight: "6px",
                   border: "1px solid #c4c4c4",
                   borderRadius: "50%",
                   cursor: "pointer",
                 }}
                 onClick={(e) => {
                   e.preventDefault();
                   workItemStore.handleAssignEmployees(item);
                 }}
            />
          ) : (
            <img src={toAbsoluteUrl("/white-labeling/verde/media/menu-icons/inspectorProfile.png")} alt={"Employee"}
                 style={{
                   width: "40px",
                   height: "40px",
                   marginRight: "6px",
                   border: "1px solid #c4c4c4",
                   borderRadius: "50%"
                 }} />
          )}
          <Typography variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        color: "#555",
                        marginRight: "8px"
                      }}>
            {(item.assigned_to_employees?.length) ? getEmployee(item) : `${t("unassigned")}`}
          </Typography>

        </Box>

        <Box sx={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: "8px 8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end"
        }}>
          <div className="d-flex flex-row align-items-center">
            {item.status !== "Closed" &&
              <ActionButton
                iconType={"done"}
                onClick={(e) => {
                  e.preventDefault();
                  workItemStore.handleOpenClosingModal(item);
                }}
              />
            }
            <ActionButton
              iconType={"view"}
              onClick={(e) => {
                e.preventDefault();
                workItemStore.handleViewWorkItem(item);
              }}
            />
          </div>
        </Box>

        <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px dashed #c6c6c6",
            width: "100%",
            paddingTop: "50px",
            paddingBottom: "0px"
          }}>
            {/* Left Column */}
            <Box sx={{ width: "50%", padding: "16px" }}>
              <div className="d-flex flex-column" style={{ width: !condensedCard ? "350px" : "" }}>
                <ProgressBar
                  progress={isClosed ? 100 : value}
                  label={isClosed ? `${t("completed")}` : `${t("urgency")}: ` + label}
                  colorClass={isClosed ? "bg-success" : colorClass}
                  subtext={isClosed ? "" : `Due: ${formatSlashedDate(item.due_date).slice(0, -5)}`} />
              </div>
            </Box>

            {/* Right Column */}
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", width: condensedCard ? "65%" : "100%", textAlign: "right", padding: "16px" }}>
              <Box sx={{ display: "flex", flexDirection: "column", textAlign: "right", alignItems: "flex-end" }}>
                <Box sx={{ display: "flex", flexDirection: condensedCard ? "column" : "row", alignItems: condensedCard ? "" : "center", justifyContent: "flex-end" }}>
                  <Typography variant="h4"
                              sx={{ fontWeight: "bold", color: "#555", fontSize: condensedCard ? "0.9rem" : "1.2rem", marginRight: condensedCard ? "" : "3px" }}>
                    {getBuildingName(item)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ fontSize: condensedCard ? "0.9rem" : "1.2rem", marginTop: "2px" }}>
                    {!condensedCard && '/'} {getFloor(item)} / {getEntityTypeName(item)}
                  </Typography>
                </Box>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginTop: "2px"
                }}>
                  <Typography variant="body2" color="primary" sx={{ fontSize: condensedCard ? "0.8rem" : "1.1rem" }}>
                    {serviceItems[0]?.entity_type?.name || "No Service Selected"}
                    {serviceItems && serviceItems.length > 1 && (
                      <Typography component="span" sx={{ marginLeft: "5px", fontSize: condensedCard ? "0.8rem" : "1.1rem" }}>
                        +{serviceItems.length - 1}
                      </Typography>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: condensedCard ? "center" : "space-between",
            alignItems: "center",
            paddingBottom: "15px"
          }}>
            {/* Left Column */}
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              padding: "16px",
              paddingBottom: 0,
              paddingTop: 0
            }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: condensedCard ? "center" : "start", marginRight: condensedCard ? "" : "10px" }}>
                <MediaRowSquares itemMedia={item.media} captions={false} size={condensedCard ? 85 : 55} />
              </Box>
            </Box>

            {/* Right Column */}
            {!condensedCard &&
              <Box sx={{ textAlign: "right", marginTop: "5px", padding: "16px", paddingBottom: 0, paddingTop: 0 }}>
                <div className="d-flex flex-column"
                     style={{
                       maxWidth: "350px",
                       overflow: "hidden",
                       textOverflow: "ellipsis",
                       whiteSpace: "nowrap"
                     }}>
                  <Typography variant="h4" sx={{ fontWeight: "bold", color: "#555", fontSize: "1.2rem" }}>
                    {item.created_by.person.first_name} {item.created_by.person.last_name}
                  </Typography>
                  <Typography variant="h4" color="textSecondary" sx={{ fontSize: "1.1rem", marginTop: "2px" }}>
                    {t("created")} {formattedDate}
                  </Typography>
                </div>
              </Box>
            }
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default WorkItemCard;

