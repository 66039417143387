import React, { useEffect, useState } from "react";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { ProjectsService } from "../../../../../../shared/services/projects/projectsService";
import { useSnapshot } from "valtio";
import { projectStore } from "../../../../projectsStore";
import ProjectForm from "./ProjectForm";
import InsightsFeed from "./InsightsFeed";
import { Project } from "../../../../../../shared/models/project.model";
import ProjectButtons from "./ProjectButtons";
import { ConfirmationDialog } from "../../../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import { employeeStore } from "../../../../../employees/employeeStore";

const EditProject = () => {
  const service = new ProjectsService();
  const snap = useSnapshot(projectStore);
  const [activeTab, setActiveTab] = useState(0);
  const [parent, setParent] = useState<Project | undefined>(projectStore.selectedProject?.parent || undefined);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSave = async () => {
    if (projectStore.selectedProject && projectStore.selectedPlan) {
      try {
        projectStore.selectedProject.parent = parent;
        const isCreating = projectStore.selectedProject.id === 0;
        if (isCreating) {
          await service.createProject(projectStore.selectedProject, projectStore.selectedPlan.id);
        } else {
          await service.updateProject(projectStore.selectedProject, projectStore.selectedPlan.id);
        }
      } catch (e) {
        console.error('Error saving relationship:', e);
      } finally {
        projectStore.refreshProjects = !projectStore.refreshProjects;
      }
    }
  };

  const handleCancel = () => {
    projectStore.selectedProject = null;
  }

  const handleDeleteClicked = () => {
    projectStore.deleteOpen = true;
  }

  const handleDeleteProject = async () => {
    if (snap.selectedProject && snap.selectedPlan) {
      try {
        await service.deleteProject(snap.selectedProject.id, snap.selectedPlan.id);
        projectStore.deleteOpen = false;
        projectStore.refreshProjects = !projectStore.refreshProjects;
      } catch (error) {
        console.error(error);
      }
    }
  }

  const handleSetDeleteOpen = () => {
    projectStore.deleteOpen = !projectStore.deleteOpen;
  }

  return (
    <div className="d-flex flex-column flex-row-fluid">
      {snap.selectedProject ? (
        <>
          <div className="d-flex flex-row justify-content-end align-items-center mb-5" style={{ gap: '10px', paddingRight: '10px' }}>
            <ProjectButtons
              handleCancel={handleCancel}
              handleSave={handleSave}
              handleDeleteProject={handleDeleteClicked}
            />
          </div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="project item tabs">
              <Tab label="DETAILS" />
              <Tab label="INSIGHTS" />
            </Tabs>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            {activeTab === 0 && <ProjectForm parent={parent} setParent={setParent}/>}
            {activeTab === 1 && <InsightsFeed />}
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          textAlign="center"
          p={2}
          mt={20}
        >
          <div>
            <p
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                marginBottom: '15px',
              }}
            >
              No Project Selected
            </p>
            <p>Select a Project to view/edit details</p>
          </div>
        </Box>
      )}
      {snap.selectedProject && snap.deleteOpen && (
        <ConfirmationDialog
          isOpen={snap.deleteOpen}
          setIsOpen={handleSetDeleteOpen}
          message="Delete Employee?"
          description={`Are you sure you want to delete ${snap.selectedProject.name}? This action cannot be undone.`}
          onConfirmClicked={handleDeleteProject}
        />
      )}
    </div>
  );
};

export default EditProject;
