import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EmployeeService } from "../../../../shared/services/employee/employeeService";
import EmployeeForm from "./components/form/EmployeeForm";
import useAppStore from "../../../../appStore";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import ErrorBoundary from "../../../../shared/components/error-boundary/ErrorBoundary";
import { SageError } from "../../../../shared/models/sageError.model";
import { useSnapshot } from "valtio";
import { employeeStore } from "../../employeeStore";
import { Employee } from "../../../../shared/models/employee.model";
import { GroupEntry } from "../../../../shared/models/groupEntry.model";
import { PermissionLevels } from "../../../../shared/models/userPermissions.model";

const EditEmployee = (props) => {
  const { open, onActionClicked } = props;
  const snap = useSnapshot(employeeStore);
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { selectedLocation } = useAppStore();
  const [error, setError] = useState("");
  const employeeService = new EmployeeService();
  const [positions, setPositions] = useState<GroupEntry[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const canEdit = user?.permissions.hasAccess("Employees", PermissionLevels.UPDATE);

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      setIsLoading(true);
      try {
        if (snap.employeeToEdit.id === -1) {
          employeeStore.employeeToEdit = new Employee();
        } else {
          const service = new EmployeeService();
          employeeStore.employeeToEdit = await service.getEmployee(snap.employeeToEdit.id);
        }
      } catch (error) {
        console.error("Failed to fetch employee:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchPositions = async () => {
      try {
        const positionsData = await employeeService.getPositions(user.organization?.id);
        setPositions(positionsData);
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };

    fetchEmployeeDetails();
    if (positions.length === 0) {
      fetchPositions();
    }
  }, [user.organization]);

  const handleSave = async () => {
    try {
      if (!snap.employeeToEdit) {
        throw new Error("No employee data to save");
      }

      let response;

      // UPDATE
      if (snap.employeeToEdit.id !== -1 && employeeStore.employeeToEdit) {
        response = await employeeService.updateEmployee(employeeStore.employeeToEdit);
        onActionClicked("save");
      }
      // CREATE
      else {
        if (user?.organization && employeeStore.employeeToEdit) {
          employeeStore.employeeToEdit.organization = user.organization;
          response = await employeeService.createEmployee(
            employeeStore.employeeToEdit,
            user.organization.id,
            selectedLocation.id
          );
          onActionClicked("save", response);
        } else {
          throw new Error("Organization data is missing for the new employee");
        }
      }
    } catch (e) {
      const errorMessage = e instanceof SageError ? e.message : JSON.stringify(e);
      setError(`Failed to update employee: ${errorMessage}`);
    }
  };

  const handleValidateAndSave = () => {
    // Basic validation
    if (!snap.employeeToEdit.person.first_name) {
      employeeStore.formError = "Name is required.";
      return;
    }
    if (!snap.employeeToEdit.person.last_name) {
      employeeStore.formError = "Last name is required.";
      return;
    }
    if (!snap.employeeToEdit.person.phone_number) {
      employeeStore.formError = "Phone Number is required.";
      return;
    }
    if (!snap.employeeToEdit.works_at[0].position) {
      employeeStore.formError = "Position is required.";
      return;
    }
    if (
      snap.employeeToEdit.user.is_active &&
      snap.employeeToEdit.user.permission_profiles[0].role.id === 0
    ) {
      employeeStore.formError = "Role is required.";
      return;
    }
    employeeStore.formError = "";
    handleSave().then();
  };

  if (isLoading) {
    return <CustomLoadingDots />;
  }

  return (
    <ErrorBoundary>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => onActionClicked("close")}
        TransitionComponent={ModalTransition}
      >
        {/* AppBar at the top */}
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onActionClicked("close")}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            {/* If not editing, show an "Edit" button on the right */}
            {(!snap.editing) &&  (
              <Button
                color="inherit"
                variant="contained"
                onClick={() => (employeeStore.editing = true)}
                sx={{ ml: "auto", color: "#444444" }}
                disabled={!canEdit}
              >
                Edit
              </Button>
            )}
          </Toolbar>
        </AppBar>

        <DialogContent dividers sx={{ maxHeight: "70vh", overflowY: "auto" }}>
            <EmployeeForm
              positions={positions}
              error={error}
            />
        </DialogContent>

        {snap.editing &&
          <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button onClick={() => onActionClicked("close")}>
                Close
              </Button>
              <Button
                variant="contained"
                onClick={handleValidateAndSave}
              >
                Save
              </Button>
          </DialogActions>
        }

      </Dialog>
    </ErrorBoundary>
  );
};

export default EditEmployee;


