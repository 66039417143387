import { BaseSerializer } from "./baseSerializer";
import { Project } from "../project.model";
import { ProjectMetricModel } from "../projectMetric.model";


export class ProjectMetricSerializer extends BaseSerializer {

  public deserialize( data ): any {

    let metric = new ProjectMetricModel();

    this._copyAttributes(metric, data);

    return metric;

  }

}