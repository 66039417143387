import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Divider,
  Toolbar,
  IconButton,
  AppBar,
  Button
} from "@mui/material";
import { TraceService } from "../../../shared/services/trace-thread/traceService";
import traceStore from "../traceStore";
import CloseIcon from "@mui/icons-material/Close";
import { useSnapshot } from "valtio";

const TraceDetails = ({ open, onClose }) => {
  const snap = useSnapshot(traceStore)
  const [expandRequest, setExpandRequest] = useState(false);
  const [expandResponse, setExpandResponse] = useState(false);

  useEffect(() => {
    if (snap.selectedTrace) {
      const fetchTraceDetails = async () => {
        try {
          const service = new TraceService();
          const detailTrace = await service.getTrace(snap.selectedTrace.id);
          traceStore.selectTrace(detailTrace);
        } catch (error) {
          console.error("Failed to fetch trace details:", error);
        }
      };

      fetchTraceDetails().then();
    }
  }, [snap.selectedTrace?.id]);

  const getStatusColor = (status) => {
    if (status?.startsWith("S")) return "green";
    return "red";
  };

  if (!snap.selectedTrace) return null;

  // Helper function to get short snippet of JSON
  const getShortJson = (data, maxLen = 150) => {
    const full = JSON.stringify(data, null, 2);
    if (full.length <= maxLen) {
      return full;
    } else {
      return full.slice(0, maxLen) + "...";
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <AppBar sx={{ position: "relative", background: "#333" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogTitle sx={{ pb: 0 }}>
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            padding: "16px",
            borderRadius: "8px",
            overflowX: "auto",
            fontFamily: "monospace",
            fontSize: "14px",
            whiteSpace: "pre-wrap",
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* Status indicator box */}
          <Box
            sx={{
              backgroundColor: getStatusColor(snap.selectedTrace.status),
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginRight: "8px",
              flexShrink: 0
            }}
          />
          <Typography
            variant="body1"
            sx={{ fontFamily: "monospace", fontSize: "14px", fontWeight: 600 }}
          >
            {snap.selectedTrace.http_method} {snap.selectedTrace.url}
          </Typography>
        </Box>
      </DialogTitle>
      <Divider sx={{ backgroundColor: "#000", mt: 1, mb: 2 }} />

      <DialogContent sx={{ paddingBottom: "24px" }}>
        {/* Query Parameters Section */}
        {snap.selectedTrace.query_params && Object.keys(snap.selectedTrace.query_params).length > 0 && (
          <Box mb={4}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mb: 1,
                textTransform: "uppercase",
                color: "#555"
              }}
            >
              Parameters
            </Typography>
            <Box
              sx={{
                backgroundColor: "#fafafa",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px"
              }}
            >
              {Object.entries(snap.selectedTrace.query_params).map(([key, value], index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    borderBottom:
                      index === Object.entries(snap.selectedTrace.query_params).length - 1
                        ? "none"
                        : "1px solid #eee",
                    py: 1
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {key}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#333" }}>
                    {String(value)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {/* Messages Section */}
        {snap.selectedTrace.messages && snap.selectedTrace.messages.length > 0 && (
          <Box mb={4}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mb: 1,
                textTransform: "uppercase",
                color: "#555"
              }}
            >
              Messages
            </Typography>
            <Box
              sx={{
                backgroundColor: "#fafafa",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "16px",
                maxHeight: "200px",
                overflowY: "auto"
              }}
            >
              {snap.selectedTrace.messages.map((message, index) => (
                <Box key={index} mb={1}>
                  <Typography variant="body2" sx={{ color: "#333" }}>
                    - {message}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {/* REQUEST Section */}
        <Box mb={4}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              mb: 1,
              textTransform: "uppercase",
              color: "#555"
            }}
          >
            Request
          </Typography>
          <Box
            sx={{
              backgroundColor: "#fafafa",
              border: "1px dashed #c7c7c7",
              padding: "16px",
              borderRadius: "8px",
              overflowX: "auto",
              whiteSpace: "pre-wrap"
            }}
          >
            {snap.selectedTrace.request && Object.keys(snap.selectedTrace.request).length > 0 ? (
              <>
                {!expandRequest ? (
                  <>
                    <pre style={{ margin: 0 }}>
                      {getShortJson(snap.selectedTrace.request, 150)}
                    </pre>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                      onClick={() => setExpandRequest(true)}
                    >
                      Expand
                    </Button>
                  </>
                ) : (
                  <>
                    <pre style={{ margin: 0 }}>
                      {JSON.stringify(snap.selectedTrace.request, null, 2)}
                    </pre>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                      onClick={() => setExpandRequest(false)}
                    >
                      Collapse
                    </Button>
                  </>
                )}
              </>
            ) : (
              <Typography variant="body2" sx={{ color: "#888" }}>
                No request data available
              </Typography>
            )}
          </Box>
        </Box>

        {/* RESPONSE Section */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              mb: 1,
              textTransform: "uppercase",
              color: "#555"
            }}
          >
            Response
          </Typography>
          <Box
            sx={{
              backgroundColor: "#fafafa",
              border: "1px dashed #c7c7c7",
              padding: "16px",
              borderRadius: "8px",
              overflowX: "auto",
              whiteSpace: "pre-wrap"
            }}
          >
            {snap.selectedTrace.response && Object.keys(snap.selectedTrace.response).length > 0 ? (
              <>
                {!expandResponse ? (
                  <>
                    <pre style={{ margin: 0 }}>
                      {getShortJson(snap.selectedTrace.response, 150)}
                    </pre>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                      onClick={() => setExpandResponse(true)}
                    >
                      Expand
                    </Button>
                  </>
                ) : (
                  <>
                    <pre style={{ margin: 0 }}>
                      {JSON.stringify(snap.selectedTrace.response, null, 2)}
                    </pre>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                      onClick={() => setExpandResponse(false)}
                    >
                      Collapse
                    </Button>
                  </>
                )}
              </>
            ) : (
              <Typography variant="body2" sx={{ color: "#888" }}>
                No response data available
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TraceDetails;


