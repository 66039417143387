import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { ProjectSerializer } from "../../../models/serializers/projectSerializer";


export class CommandGetProject {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(plan_id, project_id) {
    return `${this.SERVICE}/project_plans/${plan_id}/projects/${project_id}/`;
  }

  async run(plan_id, project_id) {
    const url = this.getUrl(plan_id, project_id);
    const tracer = useTracer.getState()
    tracer.trace('Cases', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Projects', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Projects', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new ProjectSerializer()
    const workItem = serializer.deserialize(data)
    return workItem
  }

}