import React, { FC, useCallback, useEffect, useState } from "react";
import { Avatar, Select, MenuItem, FormControl } from "@mui/material";
import useAppStore from "../../../../app/appStore";
import { useTranslation } from "react-i18next";
import { LanguageService } from "../../../../app/shared/services/language/languageService";
import { LanguageModel } from "../../../../app/shared/models/language.model";
import EditEmployee from "../../../../app/modules/employees/components/edit-employee/EditEmployee";
import { AuthModel, useAuth } from "../../../../app/modules/auth";
import { Employee } from "../../../../app/shared/models/employee.model";
import { EmployeeService } from "../../../../app/shared/services/employee/employeeService";
import * as authHelper from "../../../../app/modules/auth/core/AuthHelpers";
import { getUserByToken } from "../../../../app/modules/auth/core/_requests";
import { UserSerializer } from "../../../../app/shared/models/serializers/userSerializer";
import EmployeeSelect from "../../../../app/shared/components/forms/widgets/selection-forms/EmployeeSelect";
import { employeeStore } from "../../../../app/modules/employees/employeeStore";
import { useSnapshot } from "valtio";

const HeaderUserMenu: FC = () => {
  const snap = useSnapshot(employeeStore);
  const employeeService = new EmployeeService();
  const { currentUser, logout } = useAuth();
  const [auth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const { i18n, t } = useTranslation();
  const { loggedInUser, whiteLabelProps, setShadowUser } = useAppStore();
  const langService = new LanguageService();
  const [languages, setLanguages] = useState<LanguageModel[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [employeeSelectOpen, setEmployeeSelectOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);

  useEffect(() => {
    langService
      .getLanguages(loggedInUser.organization.id)
      .then((data) => setLanguages(data))
      .catch((error) => console.log(error.message));

    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, [loggedInUser.organization.id, i18n]);

  const handleLanguageChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
    await i18n.changeLanguage(newLanguage);
  };

  const handleShadow = () => {
    setEmployeeSelectOpen(true);
  };

  const handleShadowSubmit = async () => {
    setEmployeeSelectOpen(false);
    if (selectedEmployee) {
      const userId = selectedEmployee.user.id;
      if (userId) {
        try {
          const { data } = await getUserByToken(auth?.token || "", userId.toString());
          if (data) {
            const serializer = new UserSerializer();
            const user = serializer.deserialize(data.user);
            setShadowUser(user);
            localStorage.setItem("shadowUser", user.id.toString());
            window.location.reload();
          }
        } catch (error) {
          console.error("Failed to fetch user data:", error);
        }
      }
    }
  };

  const handleEditProfile = async () => {
    if (!currentUser?.employee.id || currentUser?.employee.id === -1) return;
    try {
      const employee = await employeeService.getEmployee(currentUser?.employee.id);
      employeeStore.editing = false;
      employeeStore.employeeToEdit = employee;
      employeeStore.mode = 'profile';
    } catch (error) {
      console.error('Failed to fetch employee:', error);
    } finally {
      employeeStore.profileOpen = true;
    }
  };

  const handleCloseEditModal = useCallback(() => {
    employeeStore.employeeToEdit = new Employee();
    employeeStore.editing = false;
    employeeStore.profileOpen = false;
    employeeStore.mode = 'basic';
  }, []);

  return (
    <>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
        data-kt-menu="true" style={{zIndex: 999}}
      >
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-50px me-5">
              <Avatar
                sx={{ bgcolor: whiteLabelProps.primary_color_light, width: 50, height: 50 }}
                variant="rounded"
                src={currentUser?.photo_url}
                alt={`${currentUser?.first_name} ${currentUser?.last_name}`}
              />
            </div>
            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center fs-5">
                {currentUser?.first_name} {currentUser?.last_name}
              </div>
              <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
                {currentUser?.email}
              </a>
            </div>
          </div>
        </div>

        <div className="separator my-2"></div>

        {/* Language Selector */}
        <div
          className="menu-item px-5 my-2"
          data-kt-menu-dismiss="false"
          onClick={(e) => e.stopPropagation()} // Prevent the menu from closing
        >
          {languages.length > 0 ? (
            <select
              value={selectedLanguage}
              onChange={handleLanguageChange}
              style={{ width: '100%', padding: '5px', borderRadius: '4px' }}
            >
              {languages.map((language) => (
                <option key={language.id} value={language.code}>
                  {language.name}
                </option>
              ))}
            </select>
          ) : (
            <select style={{ width: '100%', padding: '5px', borderRadius: '4px' }}>
              <option value="">Loading...</option>
            </select>
          )}
        </div>

        {/* Profile and Logout Links */}
        <div className="menu-item px-5">
          <a onClick={handleEditProfile} className="menu-link px-5">
            {t("profile")}
          </a>
          {loggedInUser.permissions.hasAccess("Shadow") && (
            <a onClick={handleShadow} className="menu-link px-5">
              {"Shadow User"}
            </a>
          )}
          <a onClick={logout} className="menu-link px-5">
            {t("sign-out")}
          </a>
        </div>
      </div>

      <EditEmployee open={snap.profileOpen} onActionClicked={handleCloseEditModal} />
      {employeeSelectOpen && (
        <EmployeeSelect
          open={employeeSelectOpen}
          onClose={handleShadowSubmit}
          selection={selectedEmployee}
          setSelection={setSelectedEmployee}
          singleSelect={true}
          shadowMode={true}
        />
      )}
    </>
  );
};

export { HeaderUserMenu };



