import { AppDate } from "../utils/date-time/AppDate";

export class UrgencyContextModel {
  due_date: AppDate | null;
  start_date: AppDate | null;
  is_from_inspection: boolean;
  is_from_joint_inspection: boolean;
  is_inspection_weight: number;
  is_joint_inspection_weight: number;
  daily_increment_weight: number;


  constructor() {
    this.due_date = null;
    this.start_date = null;
    this.is_from_inspection = false;
    this.is_from_joint_inspection = false;
    this.is_inspection_weight = 0.0;
    this.is_joint_inspection_weight = 0.0;
    this.daily_increment_weight = 0.0;
  }

}