import React from "react";
import WorkItemRow from "./components/WorkItemRow";
import WorkItemCard from "../card-view/WorkItemCard";
import ReusableTable from "../../../../../../shared/components/page/tables/ReusableTable";
import { useSnapshot } from "valtio/index";
import { workItemStore } from "../../../../workItemStore";

interface WorkItemTableProps {
  workItems: any[];
}

const WorkItemTable: React.FC<WorkItemTableProps> = (props) => {
  const { workItems } = props;
  const snap = useSnapshot(workItemStore);

  const headers = [
    { label: 'WHO', className: 'ps-5 min-w-215px text-start' },
    { label: 'WHERE', className: 'min-w-60px text-start px-3' },
    { label: 'WHAT', className: 'min-w-150px text-start px-5' },
    { label: 'URGENCY', className: 'min-w-150px text-start ps-10' },
    { label: '', className: 'min-w-20px text-end' },
  ];

  return (
    <ReusableTable
      items={workItems}
      headers={headers}
      RowComponent={(props) => <WorkItemRow {...props} />}
      CardComponent={(props) => <WorkItemCard {...props} />}
      tableView={snap.tableView}
    />
  );

};

export default WorkItemTable;

