import React from "react";
import TextAreaField from "../tell-sage/case-creation-assist/reusable-fields/TextAreaField";
import SelectDropdownField from "../tell-sage/case-creation-assist/reusable-fields/SelectDropdownField";
import DateField from "../tell-sage/case-creation-assist/reusable-fields/DateField";
import RadioButtonField from "../tell-sage/case-creation-assist/reusable-fields/RadioButtonField";
import casesStore from "../../caseStore";
import { useSnapshot } from "valtio";

const DynamicFormSection = ({section}) => {

  const snap = useSnapshot(casesStore);

  const handleCustomFormInputChange = (fieldName, value) => {
    casesStore.caseToEdit.form_data[fieldName] = value;
  };

  return (
    <div style={{ marginTop: 15 }}>
      {section.fields.map((field, index) => {
        switch (field.type) {
          case "TextArea":
            return (
              <TextAreaField
                key={index}
                value={snap.caseToEdit.form_data[field.data_source] || ""}
                onChange={(e) =>
                  handleCustomFormInputChange(
                    field.data_source,
                    e.target.value
                  )
                }
                label={field.name}
                multiline={true}
                rows={5}
              />
            );
          case "TextField":
            return (
              <TextAreaField
                key={index}
                value={snap.caseToEdit.form_data[field.data_source] || ""}
                onChange={(e) =>
                  handleCustomFormInputChange(
                    field.data_source,
                    e.target.value
                  )
                }
                label={field.name}
                multiline={false}
                rows={1}
              />
            );
          case 'Select':
            return (
              <SelectDropdownField
                key={index}
                label={field.name}
                value={snap.caseToEdit.form_data[field.data_source] || ""}
                onChange={(event) => handleCustomFormInputChange(field.data_source, event.target.value)}
                options={[
                  { value: 'Abrasion, Scrapes, Cut', label: 'Abrasion, Scrapes, Cut' },
                  { value: 'Sprain, Strain', label: 'Sprain, Strain' },
                  { value: 'Burn', label: 'Burn' },
                  { value: 'Pain', label: 'Pain' },
                  { value: 'Nausea', label: 'Nausea' },
                  { value: 'Headache, Dizziness', label: 'Headache, Dizziness' },
                  { value: 'Difficulty Breathing', label: 'Difficulty Breathing' },
                  { value: 'Slip, Trip, or Fall', label: 'Slip, Trip, or Fall' },
                  { value: 'Heat Exhaustion', label: 'Heat Exhaustion' },
                  { value: 'Drug Use', label: 'Drug Use' },
                  { value: 'Eye / Vision', label: 'Eye / Vision' },
                  { value: 'Other', label: 'Other' },
                ]} />
            );
          case "Date":
            return (
              <DateField
                key={index}
                label={field.name}
                value={snap.caseToEdit.form_data[field.data_source] || ""}
                onChange={(newValue) =>
                  handleCustomFormInputChange(field.data_source, newValue)
                }
              />
            );
          case "Radio":
            return (
              <RadioButtonField
                key={index}
                label={field.name}
                value={snap.caseToEdit.form_data[field.data_source] || "No"}
                onChange={(event) =>
                  handleCustomFormInputChange(
                    field.data_source,
                    event.target.value
                  )
                }
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default DynamicFormSection;