import { BaseSerializer } from "./baseSerializer";
import { ProjectCategory } from "../projectCategory.model";


export class ProjectCategorySerializer extends BaseSerializer {

  public deserialize( data ): any {

    let category = new ProjectCategory();

    this._copyAttributes(category, data);

    return category;

  }

}