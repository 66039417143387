/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers/components/KTIcon'
import { useTranslation } from "react-i18next";
import TextareaAutosize from '@mui/material/TextareaAutosize';



const CommentsReply = (props) => {

    const {
        onSubmitComment
    } = props

    const {t} = useTranslation();
    const [text, setText] = useState('');

    const handleTextareaChange = (event) => {
        setText(event.target.value);
    }

    const submitCommentHandler = () => {
        onSubmitComment(text);
        setText('');
    };


    return (

        <form className='position-relative mb-6'>
            <TextareaAutosize
                className='form-control border resize-none min-h-25px '
                minRows={1}
                placeholder={t('reply')}
                value={text}
                onChange={handleTextareaChange}
            />
            <div className='position-absolute top-0 end-0 me-n5 d-flex align-items-center h-100'>
                <span
                  className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-5 d-flex align-items-center justify-content-center'
                  onClick={submitCommentHandler}
                >
                    <KTIcon iconName='send' className='fs-3' />
                </span>
            </div>
        </form>
    )

}

export {CommentsReply}
