import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSnapshot } from 'valtio';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { projectStore } from "../../../../../projectsStore";

const ImpactDifficultyChart = () => {
  const snap = useSnapshot(projectStore);
  const filteredProjects = snap.projects;

  const bubbleData = filteredProjects.map((project) => ({
    x: project.difficulty,
    y: project.impact,
    z: 1000,
    name: project.name
  }));


  const maxDifficulty = Math.max(...bubbleData.map(d => d.x));

  const options: ApexOptions = {
    chart: {
      type: 'bubble',
      toolbar: { show: false },
      zoom: {
        enabled: true,
        type: 'xy'
      },
    },
    grid: {
      padding: {
        left: 40,
        right: 40,
        top: 20,
        bottom: 20
      }
    },
    plotOptions: {
      bubble: {
        minBubbleRadius: 5,
        maxBubbleRadius: 30
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'numeric',
      min: 1,
      max: maxDifficulty < 10 ? 10 : maxDifficulty + 1,
      tickAmount: 5,
      title: {
        text: 'Difficulty',
        style: {
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      labels: {
        formatter: (val) => Math.round(Number(val)).toString()
      },
    },
    yaxis: {
      min: 1,
      max: 6,
      tickAmount: 5,
      title: {
        text: 'Impact',
        style: {
          fontSize: '14px',
          fontWeight: 'bold'
        },
        offsetX: 10
      },
      labels: {
        formatter: (val) => {
          return Math.round(Number(val)) === 6 ? '' : Math.round(Number(val)).toString();
        }
      },
    },
    legend: {
      show: false
    },
    tooltip: {
      shared: false,
      intersect: true,
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        const project = bubbleData[dataPointIndex];
        if (!project) {
          // If no project is found at this index, return an empty tooltip
          return '';
        }

        return `
      <div style="padding:10px;">
        <div style="font-weight:bold;font-size:14px;margin-bottom:5px;">${project.name}</div>
        <div><strong>Impact:</strong> ${project.y}</div>
        <div><strong>Difficulty:</strong> ${project.x}</div>
        <div><strong>Budget:</strong> $${project.z}</div>
      </div>
    `;
      }
    },
    fill: {
      opacity: 0.7
    },
    colors: ['#15aa78'],
  };

  const series = [{
    name: 'Projects',
    data: bubbleData
  }];

  return (
    <Card>
      <CardContent>
        <Box sx={{ p: 1, mt: 5, mb: 3 }}>
          <ReactApexChart
            options={options}
            series={series}
            type="bubble"
            height={400}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ImpactDifficultyChart;






