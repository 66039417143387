import React from "react";
import {
  getCaseAssignee, getCaseRelatedEmployee,
  getDaysAgo,
  getTimeDifferenceString
} from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import { format } from "date-fns";
import useAppStore from "../../../../../../appStore";
import DuotoneCustomIcon from "../../../../../../shared/components/page/row-icons/DuotoneCustomIcon";
import casesStore from "../../../../caseStore";
import { WorkItem } from "../../../../../../shared/models/workItem.model";
import { workItemStore } from "../../../../../work-items/workItemStore";

const CaseRow = (props) => {
  const { item } = props;
  const { appImages } = useAppStore();
  const formattedDate = format(new Date(item.create_date), "MMM d, yyyy");

  const caseTypeImageMap = {
    "General HR Questions": appImages.iconCaseQuestion,
    "Resignation": appImages.iconResignation,
    "Supervisor Incident Intake Form": appImages.iconSafetyCase,
    "Hey Sage General Help": appImages.iconCaseTellSage,
    "Complaint": appImages.iconComplaint,
    "Compliment": appImages.iconCompliment
  };

  const rowImage = caseTypeImageMap[item.case_type.name]|| appImages.iconGenericCase;
  const caseColor = item.case_type.name === 'Complaint' ? 'bg-light-danger' : 'bg-light-success';

  const urgent = getDaysAgo(item.create_date) > 3 && item.status !== 'Completed';

  const openWorkItem = (id) => {
    let workItem = new WorkItem();
    workItem.id = id;
    workItemStore.workItemToEdit = workItem;
    workItemStore.open = true;
  }

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center ps-5">
          <DuotoneCustomIcon
            photo={rowImage}
            photoHeight={"45px"}
            photoWidth={"50px"}
            colorClass={caseColor}
            itemNumber={item.case_number}
          />
          <div className="d-flex justify-content-start flex-column" style={{ marginLeft: '15px' }}>
            <a className="text-gray-800 fw-bold mb-1 fs-6">
              {item.case_type.name}
            </a>
            <span style={{ color: "gray" }} className="fw-semibold d-block fs-7">
              Case #{item.case_number} - ({item.location?.name})
            </span>
          </div>
        </div>
      </td>

      <td>
        <div className="d-flex justify-content-start flex-column">
          <a
            className="fw-bold mb-1 fs-6"
            style={{ color: urgent ? "#E54C38" : "inherit" }}
          >
            {getTimeDifferenceString(item.create_date)}
          </a>
          <span style={{ color: "gray" }} className="fw-semibold d-block fs-7">
            by {item.created_by.person.first_name} {item.created_by.person.last_name}
          </span>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <a
                href="../../demo1/dist/pages/user-profile/overview.html"
                className="text-gray-800 fw-bold fs-6"
              >
                {getCaseRelatedEmployee(item)}
              </a>
              <span style={{ color: "gray" }} className="fw-semibold d-block fs-7">
                {item.related_to_employees.length} Employee(s)
              </span>
            </div>
          </div>
        </div>
      </td>

      <td>
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <a
                href="../../demo1/dist/pages/user-profile/overview.html"
                className="text-gray-800 fw-bold fs-6"
              >
                {getCaseAssignee(item)}
              </a>
              <span style={{ color: "gray" }} className="fw-semibold d-block fs-7">
                {item.department.name}
              </span>
            </div>
          </div>
        </div>
      </td>

      <td className="text-center">
        {item.status === "Unassigned" && (
          <a
            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Assign
          </a>
        )}
      </td>
      <td>
        <div className="d-flex justify-content-end flex-shrink-0 me-5">
          {item.has_work_items &&
            <ActionButton
              iconType={item.work_item_status === 'Closed' ? "work_done" : "work_pending"}
              onClick={(e) => {
                e.preventDefault();
                openWorkItem(item.work_item_id);
              }}
            />
          }
          <ActionButton
            iconType="view"
            onClick={(e) => {
              e.preventDefault();
              casesStore.handleViewCase(item);
            }}
          />
        </div>
      </td>
    </tr>
  );
};

export default CaseRow;
