import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { workItemStore } from "../../../workItemStore";

const WorkItemSortBy = () => {

  const {t} = useTranslation();
  const snap = useSnapshot(workItemStore)

  const handleClick = (sortMode) => {
    workItemStore.sortBy = sortMode;
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <span style={{marginLeft: '5px'}}>{t('view-by')}: </span>
      <Button sx={{ marginLeft: '8px', marginRight: '4px', color: (snap.sortBy === 'urgency') ? '#888888' : '' }} size="small" onClick={() => handleClick('most_recent')}>{t('most-recent')}</Button>
      <Button sx={{color: (snap.sortBy === 'urgency') ? '' : '#888888' }} size="small" onClick={() => handleClick('urgency')}>{t('urgency')}</Button>
    </Box>
  );
};

export default WorkItemSortBy;