import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import useAppStore from "../../../../../../appStore";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import SubHeader from "../../../../../../shared/components/page/headers/SubHeader";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { Case } from "../../../../../../shared/models/case.model";
import CaseCard from "../../../../../cases/components/case-list/components/mobile/CaseCard";
import EditCase from "../../../../../cases/components/edit-case/EditCase";
import casesStore from "../../../../../cases/caseStore";
import { AnalyticsService } from "../../../../../../shared/services/analytics/analyticsService";
import { suggestionsStore } from "../../suggestionsStore";

const SuggestedCasesList = () => {

  const {selectedLocation} = useAppStore();
  const caseSnap = useSnapshot(casesStore);
  const suggestionSnap = useSnapshot(suggestionsStore);
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { masterRefresh } = useAppStore();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const suggestionService = new AnalyticsService();
  const [page, setPage] = useState(0);
  const [caseList, setCaseList] = useState<Case[]>([]);


  const fetchCases = async () => {
    setIsLoading(true);

    try {
      const active = await suggestionService.getSuggestionItems(
        user.organization?.id,
        selectedLocation.id,
        suggestionSnap.selectedSuggestion.type,
      );

      setCaseList(prev => (page === 0 ? active : [...prev, ...active]));
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
      fetchCases().then();

  }, [masterRefresh, user, selectedLocation.id]);



  function renderCases() {
    return (
      <div>
        <div style={{marginTop: '25px'}}>
          <SubHeader title={t('cases')} color="#777" margin={false} showAction={false} />
          <Divider sx={{ bgcolor: "black", marginBottom: "10px" }} />
          {isLoading && (
            <div style={{ minHeight: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CustomLoadingDots />
            </div>
          )}

          {caseList.map((c, index) => (
            <CaseCard key={index} item={c} />
          ))}

        </div>

      </div>
    );
  }

  return (
    <div>

      {error && <CustomError error={error} />}

      {!error && (
        <>
          <div>
            <>
              {renderCases()}
            </>
          </div>

        </>
      )}

      {caseSnap.open && caseSnap.caseToEdit && (
        <EditCase open={caseSnap.open} />
      )}
    </div>
  );
};

export default SuggestedCasesList;