import React, { useEffect, useState } from "react";
import useAppStore from "../../../../../../appStore";
import EditLocationContact from "./components/EditLocationContact";
import { ContactService } from "../../../../../../shared/services/location-contacts/contactService";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { Card } from "@mui/material";
import { Employee } from "../../../../../../shared/models/employee.model";
import { useTranslation } from "react-i18next";
import { LocationContact } from "../../../../../../shared/models/locationContact.model";
import { inspectionsStore } from "../../../../inspectionsStore";
import { useSnapshot } from "valtio";

const JointInspectedByCard = () => {

  const snap = useSnapshot(inspectionsStore);
  const { t } = useTranslation();
  const { whiteLabelProps, selectedLocation } = useAppStore();
  const [isAddContactOpen, setIsAddContactOpen] = useState<boolean>(false);
  const [contacts, setContacts] = useState<LocationContact[]>([]);
  const [contact, setContact] = useState<Employee | null>(null);
  const [contactToEdit, setContactToEdit] = useState<LocationContact>(new LocationContact());
  const [isJoint, setIsJoint] = useState<boolean>(snap.inspectionToEdit?.is_joint_inspection || false);
  const [isBaseline, setIsBaseline] = useState<boolean>(snap.inspectionToEdit?.is_baseline || false);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const contactService = new ContactService();

  const fetchContacts = async () => {
    setIsLoading(true);
    try {
      const contactsData = await contactService.getContacts(selectedLocation.id);
      setContacts(contactsData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (snap.inspectionToEdit && inspectionsStore.inspectionToEdit) {
      setIsJoint(snap.inspectionToEdit.is_joint_inspection)
      setIsBaseline(snap.inspectionToEdit.is_baseline)
      setContact(inspectionsStore.inspectionToEdit.joint_inspected_by)
    }
  }, [snap.inspectionToEdit]);

  useEffect(() => {
    fetchContacts();
  }, [refresh]);

  if (isLoading) {
    return <CustomLoadingDots />;
  }

  const handleJointRadioChange = (event) => {
    const value = event.target.value === 'yes';
    setIsJoint(value)
  };

  const handleBaselineRadioChange = (event) => {
    const value = event.target.value === 'yes';
    setIsBaseline(value);
    if (inspectionsStore.inspectionToEdit) {
      inspectionsStore.inspectionToEdit.is_baseline = value;
    }
  };

  const handleContactSelected = (event) => {
    const selectedContactId = event.target.value;
    const selectedContact = contacts.find(c => c.employee.id === parseInt(selectedContactId));

    if (selectedContact && inspectionsStore.inspectionToEdit) {
      setContact(selectedContact.employee);
      inspectionsStore.inspectionToEdit.joint_inspected_by = selectedContact.employee;
      inspectionsStore.inspectionToEdit.is_joint_inspection = true
    } else {
      setContact(null);
      if (inspectionsStore.inspectionToEdit) {
        inspectionsStore.inspectionToEdit.joint_inspected_by = null;
        inspectionsStore.inspectionToEdit.is_joint_inspection = false;
      }
    }

  };

  const handleAddContactOpen = () => {
    setContactToEdit(new LocationContact())
    setIsAddContactOpen(true);
  };

  const handleAddContactClose = () => {
    setIsAddContactOpen(false);
    setRefresh(!refresh)
  };

    return (
        <Card variant="outlined" sx={{
        marginTop: "15px",
        boxShadow: "0 1px 2px rgba(0,0,0,0.1)"
      }}>
        <div className="card-header p-8">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">{t('joint-inspection')}</span>
          </h3>
        </div>
        <div className="card-body p-7 pt-4">
          <div className="mb-10">
            <div className="d-flex align-items-center">
              <div className="form-check form-check-custom form-check-solid me-10">
                <input
                  className="form-check-input"
                  style={{ backgroundColor: isJoint ? whiteLabelProps.primary_color : undefined }}
                  type="radio"
                  id="yes"
                  name="joint-inspection"
                  value="yes"
                  checked={isJoint}
                  onChange={handleJointRadioChange} />
                <label className="form-check-label" htmlFor="yes">
                  {t('yes')}
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  style={{ backgroundColor: !isJoint ? whiteLabelProps.primary_color : undefined }}
                  type="radio"
                  id="no"
                  name="joint-inspection"
                  value="no"
                  checked={!isJoint}
                  onChange={handleJointRadioChange} />
                <label className="form-check-label" htmlFor="no">
                  {t('no')}
                </label>
              </div>
            </div>
          </div>
          {isJoint && (
            <div className="mb-10">
              <label className="form-label fw-bold">{t('contact')}</label>
              <div className="input-group">
                <select
                  className="form-select form-select-solid"
                  value={contact ? contact.id : ""}
                  onChange={handleContactSelected}
                >
                  <option value="">{t('select-contact')}...</option>
                  {contacts.map((c) => (
                    <option key={c.employee.id} value={c.employee.id}>
                      {`${c.employee.person.first_name} ${c.employee.person.last_name}`}
                    </option>
                  ))}
                </select>
                <button
                  className="btn"
                  style={{
                    backgroundColor: whiteLabelProps.primary_color,
                    color: "white",
                    fontSize: "12px"
                  }}
                  onClick={handleAddContactOpen}
                >
                  {t('add-contact')}
                </button>
              </div>
            </div>
          )}
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">{t('baseline')}</span>
          </h3>
        </div>
          <div className="card-body p-7 pt-4">
            <div className="mb-10">
              <div className="d-flex align-items-center">
                <div className="form-check form-check-custom form-check-solid me-10">
                  <input
                    className="form-check-input"
                    style={{ backgroundColor: isBaseline ? whiteLabelProps.primary_color : undefined }}
                    type="radio"
                    id="yes"
                    name="baseline"
                    value="yes"
                    checked={isBaseline}
                    onChange={handleBaselineRadioChange} />
                  <label className="form-check-label" htmlFor="yes">
                    {t('yes')}
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid">
                  <input
                    className="form-check-input"
                    style={{ backgroundColor: !isBaseline ? whiteLabelProps.primary_color : undefined }}
                    type="radio"
                    id="no"
                    name="baseline"
                    value="no"
                    checked={!isBaseline}
                    onChange={handleBaselineRadioChange} />
                  <label className="form-check-label" htmlFor="no">
                    {t('no')}
                  </label>
                </div>
              </div>
            </div>
        </div>
        <EditLocationContact contactToEdit={contactToEdit} setContactToEdit={setContactToEdit} open={isAddContactOpen} onClose={handleAddContactClose}
                             onSave={handleAddContactClose} />
      </Card>
    );
};

export default JointInspectedByCard;
