import React, { useEffect, useState } from "react";
import { Rating } from "@mui/material";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import appStore from "../../../../../../../../appStore";
import InspectionPoints from "./components/InspectionPoints";
import { useEntityValidateAndSave, ValidationResult } from "../../useEntityValidateAndSave";
import PhotoUploaderViewer
  from "../../../../../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import { inspectionLabels } from "../../../../../../../../shared/utils/modal-util/modalUtil";
import FractionalScoreSelector from "./components/FractionalScoreSelector";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { inspectionsStore } from "../../../../../../inspectionsStore";
import { inspectedEntityStore } from "../../../../../../inspectedEntityStore"
import { Media } from "../../../../../../../../shared/models/media.model";


const Scoring = () => {
  const inspectionSnap = useSnapshot(inspectionsStore);
  const inspectedEntitySnap = useSnapshot(inspectedEntityStore);
  const { biRef } = useEntityValidateAndSave();
  const {t} = useTranslation();
  const { appImages } = appStore();
  const [hover, setHover] = useState(-1);
  const [inspectionMedia, setInspectionMedia] = useState<any[]>([]);
  const [isValid, setIsValid] = useState(true);
  const [selectedFraction, setSelectedFraction] = useState(0);
  const [media, setMedia] = useState<Media[]>(inspectedEntityStore.inspectedEntity.media);


  const handleFractionChange = (newFraction) => {
    setSelectedFraction(prevFraction => (prevFraction === newFraction ? 0 : newFraction));
  };

  useEffect(() => {
    let newArray = inspectedEntityStore.inspectedEntity.media.map((obj) => ({
      ...obj,
      src: obj.url
    }));

    setInspectionMedia(newArray);
  }, [inspectedEntitySnap.inspectedEntity.media]);

  useEffect(() => {
    if (selectedFraction > 0) {
      inspectedEntityStore.inspectedEntity.score = Math.floor(inspectedEntitySnap.inspectedEntity.score) + selectedFraction;
    }
  }, [selectedFraction]);

  useEffect(() => {
    inspectedEntityStore.inspectedEntity.media = media
  }, [media]);

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${inspectionLabels[value]}`;
  }

  const handleOnChange = (event) => {
    inspectedEntityStore.inspectedEntity.comments = event.target.value;
  };

  biRef.scorePage.validate = () => {
    let valInfo: ValidationResult = { passed: true, messages: [] };

    if (inspectedEntitySnap.inspectedEntity.score <= 0) {
      setIsValid(false);
      valInfo.passed = false;
      valInfo.messages.push("Rating is Required.");
    } else {
      setIsValid(true);
    }

    return valInfo;
  };

  return (
    <div className="d-flex flex-column flex-row-fluid">
      <h1>{t('score-space')}</h1>

      {/* INSPECTION POINTS */}
      <div className="mt-10">
        <h3>{t('inspection-points')}</h3>
        <InspectionPoints />
      </div>

      {/* PHOTOS */}
      <div className="mt-10">
        <h3>{t('photos')}</h3>
        <PhotoUploaderViewer
          item={inspectedEntityStore.inspectedEntity}
          setMedia={setMedia}
          captions={false}
          module={"inspections"}
        />
      </div>

      {/* COMMENTS */}
      <div className="mt-8">
        <h3>{t('comments')}</h3>
        <textarea
          name="description"
          placeholder=""
          className="form-control mb-3 mb-lg-0"
          style={{ height: "150px" }}
          onChange={handleOnChange}
          value={inspectedEntityStore.inspectedEntity.comments}
        />
      </div>

      {/* RATING */}
      <div className="mb-10 mt-8">
        <h3 className="mb-5">{t('rating')}</h3>
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          alignItems: "center"
        }}>
          <Rating
            name="hover-feedback"
            size="large"
            sx={{
              fontSize: "4rem",
              padding: 1
            }}
            style={{
              border: !isValid ? "solid 1px red" : "none",
              borderRadius: "4px"
            }}
            icon={
              <img
                src={appImages.iconRating}
                alt="filled star"
                style={{ width: "48px", height: "48px" }}
              />
            }
            emptyIcon={
              <img
                src={appImages.iconGreyedRating}
                alt="filled star"
                style={{ width: "48px", height: "48px" }}
              />
            }
            value={Math.floor(inspectedEntitySnap.inspectedEntity.score)}
            precision={1.0}
            getLabelText={getLabelText}
            onChange={(event, newValue) => {
              inspectedEntityStore.inspectedEntity.score = newValue == null ? 0 : newValue;
              setSelectedFraction(0);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
          />
          {!isValid && <div style={{ color: "red" }}>Please enter a score.</div>}
        </div>
        {inspectedEntitySnap.inspectedEntity.score !== null && inspectedEntitySnap.inspectedEntity.score !== -1 && (
          <>
            {inspectedEntitySnap.inspectedEntity.score !== 5 &&
              <FractionalScoreSelector
                selectedFraction={selectedFraction}
                setSelectedFraction={handleFractionChange}
              />
            }
            <div className="mt-5" style={{ display: "flex", justifyContent: "center" }}>
              <b>{inspectionLabels[hover !== -1 ? hover : Math.floor(inspectedEntitySnap.inspectedEntity.score)]}</b>
            </div>
          </>
        )
        }
      </div>
    </div>
  );
};

export default Scoring;
