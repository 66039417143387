import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";

export class CommandDeleteProject {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(project_id, plan_id) {
    return `${this.SERVICE}/project_plans/${plan_id}/projects/${project_id}/`;
  }

  async run(project_id, plan_id) {
    const url = this.getUrl(project_id, plan_id);

    const tracer = useTracer.getState();
    tracer.trace('Projects', 'Api', `DELETE ${url}`);

    try {
      const response = await httpClient.delete(url);
      tracer.trace('Projects', 'Api', 'Success');
      return response;
    } catch (error) {
      tracer.trace('Projects', 'Error', `${error}`);
      console.error('Error:', error);
      throw error;
    }
  }
}