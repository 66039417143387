// stores/workItemStore.ts
import { proxy } from "valtio";
import { WorkItem } from "../../shared/models/workItem.model";
import { useState } from "react";
import { Case } from "../../shared/models/case.model";

interface StatusCounts {
  pending_count: number;
  assigned_count: number;
  closed_count: number;
}

interface WorkItemStore {
  workItems: WorkItem[];
  workItemToEdit: WorkItem;
  isLoading: boolean;
  isEditing: boolean;
  open: boolean;
  printModalOpen: boolean;
  assignModalOpen: boolean;
  historyOpen: boolean;
  deleteOpen: boolean;
  closingDialogOpen: boolean;
  tableView: boolean;
  error: string;
  snackbarOpen: boolean;
  sortBy: string;
  statusCounts: StatusCounts;
  activeStep: number;
  statusKeys: string[];

  handleDeleteOpenClose: () => void;
  handleOpenClosingModal: (item: WorkItem) => void;
  handleCloseClosingModal: () => void;
  handleViewWorkItem: (item: WorkItem) => void;
  handleAssignEmployees: (item: WorkItem) => void;

}

export const workItemStore = proxy<WorkItemStore>({
  workItems: [],
  workItemToEdit: new WorkItem(),
  isLoading: false,
  isEditing: false,
  open: false,
  printModalOpen: false,
  assignModalOpen: false,
  historyOpen: false,
  deleteOpen: false,
  closingDialogOpen: false,
  tableView: true,
  error: '',
  snackbarOpen: false,
  sortBy: "most_recent",
  statusCounts: { pending_count: 0, assigned_count: 0, closed_count: 0 },
  activeStep: parseInt(localStorage.getItem("activeStep") || '0', 10),
  statusKeys: ['pending_count', 'assigned_count', 'closed_count'],

  handleViewWorkItem: (item: WorkItem) => {
    workItemStore.workItemToEdit = item;
    workItemStore.isEditing = false;
    workItemStore.open = true;
  },

  handleAssignEmployees: (item: WorkItem) => {
    workItemStore.workItemToEdit = item;
    workItemStore.assignModalOpen = true;
  },

  handleDeleteOpenClose: () => {
    workItemStore.deleteOpen = !workItemStore.deleteOpen;
  },

  handleOpenClosingModal: (item: WorkItem) => {
    workItemStore.workItemToEdit = item;
    workItemStore.closingDialogOpen = true;
  },

  handleCloseClosingModal: () => {
    workItemStore.workItemToEdit = new WorkItem();
    workItemStore.closingDialogOpen = false;
  },

});
