import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import useAppStore from "../../../../../../appStore";
import { Media } from "../../../../../../shared/models/media.model";
import PhotoUploaderViewer from "../../../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import { LocationModel } from "../../../../../../shared/models/location.model";
import { useSnapshot } from "valtio";
import casesStore from "../../../../caseStore";

const BaseCaseDetails = () => {

  const snap = useSnapshot(casesStore);
  const {selectedLocation} = useAppStore();
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const [media, setMedia] = useState<Media[]>(casesStore.caseToEdit.media);

  useEffect(() => {
    if (casesStore.caseToEdit.related_to_employees?.length > 0) {
      casesStore.caseToEdit.location =
        casesStore.caseToEdit.related_to_employees?.[0]?.works_at?.[0]?.location
        ?? selectedLocation;
    }
  }, [snap.caseToEdit.related_to_employees]);


  useEffect(() => {
    casesStore.caseToEdit.media = media;
  }, [media]);

  const handleDescChange = (event) => {
    casesStore.caseToEdit.description = event.target.value;
  };

  const handleLocationChange = (location: LocationModel) => {
    casesStore.caseToEdit.location = location;
  };


  return (
    <>
      <FormControl fullWidth style={{ marginBottom: 15 }}>
        <span className="text-gray-700">Location</span>
        <Select value={snap.caseToEdit.location?.id || ''}>
          {user?.permissions.locations.map((location) => (
            <MenuItem key={location.id} value={location.id} onClick={() => handleLocationChange(location)}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ marginBottom: 15 }}>
        <span className="text-gray-700">Description</span>
        <TextField
          name="Description"
          value={snap.caseToEdit.description}
          onChange={handleDescChange}
          fullWidth
          multiline
          rows={5} />
      </div>
      <div style={{ marginTop: 15, marginBottom: 15 }}>
        <span className="text-gray-700">Attachments</span>
        <PhotoUploaderViewer
          item={casesStore.caseToEdit}
          setMedia={setMedia}
          captions={snap.caseToEdit.status === "Closed"}
          module={"cases"}
        />
      </div>
    </>

  );
};

export default BaseCaseDetails;
