import React, { useEffect } from "react";
import { AppBar, Dialog, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import Box from "@mui/material/Box";
import WorkItemListToolbar from "../../../../shared/components/buttons/WorkItemListToolbar";
import WorkItemTable from "../work-item-list/views/table-view/WorkItemTable";
import LoadMoreButton from "../../../../shared/components/buttons/LoadMoreButton";
import { WorkItem } from "../../../../shared/models/workItem.model";
import useAppStore from "../../../../appStore";
import { WorkItemsService } from "../../../../shared/services/work-items/workItemsService";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";

interface WorkItemHistoryProps {
  open: boolean;
  onActionClicked: (action: string) => void;
}

const WorkItemHistory: React.FC<WorkItemHistoryProps> = ({ open, onActionClicked }) => {

  const [workItemHistory, setWorkItemHistory] = React.useState<WorkItem[]>([])
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const service = new WorkItemsService();
  const { selectedLocation, masterRefresh } = useAppStore();
  const isMobile = useMediaQuery(useTheme().breakpoints.only("xs"));
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string>('');


  useEffect(() => {
    const fetchItems = async () => {
      const limit = 10;
      const offset = page * limit;
      if (page === 0) setWorkItemHistory([]);
      try {
        setIsLoading(true);
        const [items] = await Promise.all([service.getWorkItems(user.organization?.id, selectedLocation.id, 'Closed', limit, offset)]);
        setWorkItemHistory([...workItemHistory, ...items]);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          console.log(error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedLocation.id !== 0) {
      fetchItems().then();
    }

  }, [masterRefresh, selectedLocation, page]);


  const handleShowMore = () => {
    setPage(prevPage => prevPage + 1);
  };


  if (error) return <CustomError error={error} />;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onActionClicked('close')}
      TransitionComponent={ModalTransition}
      PaperProps={{
        style: {
          overflowX: 'hidden',
          backgroundColor: '#f9f9f9',
          overflowY: 'auto',
        },
      }}
    >
      <AppBar sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>

      <div style={{ marginBottom: 50, marginTop: 65, padding: '15px' }}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center'
            }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "15px"
                }}>
                <h2 style={{ paddingLeft: 5, color: '#444' }}>Closed Work Items</h2>
                {!isMobile && <WorkItemListToolbar />}
              </div>

              <>
                <WorkItemTable workItems={workItemHistory} />
                {(workItemHistory.length % 10 === 0 || isLoading) &&
                  <LoadMoreButton onClick={handleShowMore} isLoading={isLoading} />
                }
              </>
            </div>
          </div>
        </div>
      </div>

    </Dialog>
  );
};

export default WorkItemHistory;