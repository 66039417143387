import { Suspense, useEffect, useState } from "react";
import { Outlet } from 'react-router-dom';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { AuthInit, useAuth } from "./modules/auth";
import { ThemeModeProvider } from '../_metronic/partials';
import { createTheme, ThemeProvider } from '@mui/material';
import useAppStore from "./appStore";
import { SageAlert } from "./shared/components/page/popup-dialog/SageAlert";
import Hotjar from "./shared/components/hotjar/Hotjar";
import i18n from './translation/i18n';
import { I18nextProvider } from "react-i18next";
import { IntlProvider } from "react-intl";
import messagesEN from './translation/translations/en/translation.json';
import messagesES from './translation/translations/es/translation.json';


const App = () => {
  const { whiteLabelProps } = useAppStore();
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const language = i18n.language || 'en';

  const theme = createTheme({
    palette: {
      primary: {
        main: whiteLabelProps.primary_color,
        contrastText: '#fff',
      },
      secondary: {
        main: '#f1f1f2',
        contrastText: '#000',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            height: '40px',
            color: 'white',
          },
        },
      },
    },
    typography: {
      button: {
        fontWeight: 'bold',
      },
    },
  });

  const messages = {
    en: messagesEN,
    es: messagesES,
  };

  const handleCloseNotifications = () => {
    setNotificationsOpen(false);
  };

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nextProvider i18n={i18n}>
        <IntlProvider locale={language} messages={messages[language]}>
          <LayoutProvider>
          <ThemeProvider theme={theme}>
            <ThemeModeProvider>
              <AuthInit>
                <Hotjar />
                <SageAlert open={notificationsOpen} onClose={handleCloseNotifications} />
                  <Outlet />
                  <MasterInit />
              </AuthInit>
            </ThemeModeProvider>
          </ThemeProvider>
        </LayoutProvider>
        </IntlProvider>
      </I18nextProvider>
    </Suspense>
  );
}

export { App };
