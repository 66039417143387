
import React, { useState } from "react";
import {
  AppBar, Button,
  DialogContent,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import CaseWorkItem from "../details/case-work-item/CaseWorkItem";
import casesStore from "../../../../caseStore";
import { workItemStore } from "../../../../../work-items/workItemStore";
import { WorkItem } from "../../../../../../shared/models/workItem.model";
import { useTranslation } from "react-i18next";
import useAppStore from "../../../../../../appStore";


interface CaseActionModalProps {
  open: boolean;
  onClose: () => void;
}

const CaseActionModal: React.FC<CaseActionModalProps> = ({ open, onClose }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const {appImages} = useAppStore();
  const {t} = useTranslation();
  const [workItemOpen, setWorkItemOpen] = useState(false);


  const handleFinished = async () => {
    onClose();
  };

  const handleCreateWorkItem = () => {
    workItemStore.workItemToEdit = new WorkItem();
    setWorkItemOpen(true);
  };

  const handleAction = (action: string) => {
    setWorkItemOpen(false);
    if (action === 'save') {
      onClose();
    }
  }


  return (
    <Dialog
      fullWidth={!isMobile} fullScreen={isMobile}
      maxWidth={isMobile ? undefined : "sm"}
      open={open}
      onClose={handleFinished}
      TransitionComponent={ModalTransition}
    >

      <DialogContent dividers sx={{ maxHeight: isMobile ? "100vh" : "75vh", overflowY: "auto" }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ padding: "65px" }}
        >
          <img
            src={appImages.iconWorkDone}
            style={{ width: 120 }}
            alt={'done'}/>
          <h3 style={{ textAlign: "center", width: "100%" }}>
            Your case was submitted. You can create an associated work item if you would like.
          </h3>
        </div>
        <Button
          onClick={handleCreateWorkItem}
          style={{ marginBottom: "20px", fontSize: "1.2rem", width: '100%', height: '55px' }}
          variant="contained"
        >
          {t('create-a-work-item')}
        </Button>
        <Button
          onClick={handleFinished}
          style={{ marginBottom: "20px", fontSize: "1.2rem", width: '100%', height: '55px' }}
          variant="contained"
        >
          {t('finished')}
        </Button>
      </DialogContent>
      {workItemOpen &&
        <CaseWorkItem open={workItemOpen} onActionClicked={handleAction}
                      selectedCase={casesStore.caseToEdit} />
      }
    </Dialog>
  );
};

export default CaseActionModal;