import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField
} from "@mui/material";
import { Media } from "../../../../../../shared/models/media.model";
import PhotoUploaderViewer from "../../../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import WorkItemDatePicker from "../../../../../../shared/components/forms/date-time/date-picker/DatePickerButton";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { workItemStore } from "../../../../workItemStore";



const WorkItemForm = (props) => {

  const snap = useSnapshot(workItemStore);
  const isClosed = snap.workItemToEdit.status === 'Closed';
  const [media, setMedia] = useState<Media[]>(workItemStore.workItemToEdit.media);
  const {t} = useTranslation();

  const handleDateChange = (newValue) => {
    workItemStore.workItemToEdit.due_date = newValue;
    workItemStore.workItemToEdit.is_overdue = false;
  };

  useEffect(() => {
    if (snap.workItemToEdit.status !== "Closed") {
      workItemStore.workItemToEdit.status = snap.workItemToEdit.assigned_to_employees.length === 0 ? "Pending" : "In Progress";
    }
  }, [snap.workItemToEdit.assigned_to_employees]);


  useEffect(() => {
    workItemStore.workItemToEdit.media = media
  }, [media]);

  const handleDescChange = (event) => {
    workItemStore.workItemToEdit.description = event.target.value;
  };

  const handleGoodCatchCheckbox = (event) => {
    workItemStore.workItemToEdit.is_good_catch = !snap.workItemToEdit.is_good_catch;
  };


  return (

    <div className="flex-column-fluid me-10">
      <div style={{marginBottom: '15px'}}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-700">{t('details')}</span>
        </h3>
      </div>
      <Box sx={{
        display: 'flex',
        p: 0.5,
        marginTop: 1,
        width: '100%',
      }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={snap.workItemToEdit.is_good_catch}
              onChange={handleGoodCatchCheckbox}
              name="goodCatchCheckbox"
              color="primary"
            />
          }
          label={t('good-catch')}
        />
      </Box>
      <WorkItemDatePicker workItemToEdit={workItemStore.workItemToEdit} onDateChange={handleDateChange}/>
      <div style={{ marginTop: 15 }}>
        <PhotoUploaderViewer
          item={workItemStore.workItemToEdit}
          setMedia={setMedia}
          captions={snap.workItemToEdit.status === 'Closed'}
          module={'workitems'}
        />
      </div>
      <TextField
        label={t('additional-notes')}
        name='Description'
        value={snap.workItemToEdit.description}
        onChange={handleDescChange}
        fullWidth
        multiline
        rows={6}
        style={{marginTop: -15}}
      />

    </div>
  );

}

export default WorkItemForm
