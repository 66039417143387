import React, { useEffect, useRef, useState } from "react";
import {
  Dialog
} from "@mui/material";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import InspectTool from "./components/InspectTool";
import { useSnapshot } from "valtio";
import { inspectedEntityStore } from "../../../../inspectedEntityStore";

const CreateInspectedEntity = (props) => {

  const {
    open,
    onActionClicked,
  } = props

  const [step, setStep] = useState(0);
  const inspectedEntitySnap = useSnapshot(inspectedEntityStore);

  const handleCancel = () => {
    setStep(0);
    onActionClicked();
  }

  if (inspectedEntitySnap.isValidating) {
    return <CustomLoadingDots />;
  }
  if (inspectedEntitySnap.submissionError) {
    return <CustomError error={inspectedEntitySnap.submissionError} />;
  }

  return (

    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        TransitionComponent={ModalTransition}
        PaperProps={{
          style: {
            maxHeight: '90vh',
            overflowY: 'auto',
          }
        }}
      >

        <InspectTool onClose={handleCancel} step={step} setStep={setStep} />

      </Dialog>
    </>

  )

}

export default CreateInspectedEntity;
