import { proxy } from "valtio";
import { SuggestionModel } from "../../../../shared/models/suggestionModel";

interface SuggestionsStore {
  suggestions: SuggestionModel[];
  selectedSuggestion: SuggestionModel;
  isLoading: boolean;
  error: string;
  open: boolean;
}

export const suggestionsStore = proxy<SuggestionsStore>({
  suggestions: [],
  selectedSuggestion: new SuggestionModel(),
  isLoading: false,
  error: '',
  open: false,
});
