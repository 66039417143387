import React from 'react'
import { useSnapshot } from "valtio/index";
import ImpactDifficultyChart from "./components/ImpactDifficultyChart";
import { projectStore } from "../../../../projectsStore";
import ProjectHierDualViewer from "../../edit-plan/components/ProjectHierDualViewer";

const ProjectsRankTab = () => {

  const snap = useSnapshot(projectStore);

  return (
    <>
      <div style={{marginBottom: '20px'}}>
        <ImpactDifficultyChart />
      </div>

      <ProjectHierDualViewer />
    </>
  );

};

export default ProjectsRankTab
