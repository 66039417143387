import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { ProjectMetricSerializer } from "../../../models/serializers/projectMetricSerializer";

export class CommandGetMetrics {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(category_id: number) {
    return  `${this.SERVICE}/project_plans/ranking_categories/${category_id}/metrics/`;
  }

  async run(category_id: number) {

    const url = this.getUrl(category_id)
    try {
      const response = await httpClient.get(url);
      return this.deserializePosts(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserializePosts(data) {
    const serializer = new ProjectMetricSerializer()
    const metrics = serializer.deserializeList(data)
    return metrics
  }

}