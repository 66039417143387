import React from "react";
import InspectionCard from "./components/card/InspectionCard";
import { WorkItem } from "../../../../shared/models/workItem.model";
import EditWorkItem from "../../../work-items/components/edit-work-item/EditWorkItem";
import { useSnapshot } from "valtio/index";
import { workItemStore } from "../../../work-items/workItemStore";
import { inspectionsStore } from "../../inspectionsStore";

const InspectionsCardList = () => {

  const workItemsSnap = useSnapshot(workItemStore);

  const handleCloseWorkItem = (action) => {
    workItemStore.isEditing = false;
    workItemStore.open = false;
    workItemStore.workItemToEdit = new WorkItem();
    if (action === 'save') {
      workItemStore.snackbarOpen = true;
    }
  }

  return (
    <>
      {inspectionsStore.inspections.length === 0 ? (
        <div style={{ marginTop: '25px', marginBottom: '10px' }}>Nothing to show</div>
      ) : (
        inspectionsStore.inspections.map((inspection) => (
            <InspectionCard key={inspection.id} inspection={inspection} />
        ))
      )}
      {workItemsSnap.open && workItemsSnap.workItemToEdit &&
        <EditWorkItem
          open={workItemsSnap.open}
          onActionClicked={handleCloseWorkItem}
        />
      }
    </>
  );
};

export default InspectionsCardList;

