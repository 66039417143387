import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { GroupEntrySerializer } from "../../../models/serializers/groupEntrySerializer";

export class CommandGetPositions {

  POSITION_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.POSITION_SERVICE}/positions/`;
  }

  async run(provider_id: number) {
    const url = this.getUrl();

    const params = {
      params: {
        provider: provider_id
      }
    };

    const tracer = useTracer.getState();
    tracer.trace('Positions', 'Api', `GET ${url}`);

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Positions', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    }finally {
      tracer.trace('Positions', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new GroupEntrySerializer()
    return serializer.deserializeList(data)
  }
}
