import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { WorkItemSerializer } from "../../../models/serializers/workItemSerializer";
import { CaseSerializer } from "../../../models/serializers/caseSerializer";

export class CommandGetSuggestionItems {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE}/analytics/sage/suggestions/`;
  }


  async run(provider_id, location_id, type) {
    const url = this.getUrl();

    const params = {
      params: {
        provider: provider_id,
        location: location_id,
        type: type,
      }
    }

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data, type);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  deserialize(data, type) {
    if (type === 'work_items') {
      const serializer = new WorkItemSerializer();
      return serializer.deserializeList(data);
    } else if (type === 'things_i_told_sage') {
      const serializer = new CaseSerializer();
      return serializer.deserializeList(data);
    } else {
      return [];
    }
  }

}