import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { WorkItemSerializer } from "../../../models/serializers/workItemSerializer";
import { ErrorSerializer } from "../../../models/serializers/errorSerializer";


export class CommandCreateWorkItem {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/workitems/`;
  }

  async run(workitem) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('WorkItems', 'Api', `POST ${url}`)

    try {

      let data = WorkItemSerializer.serialize(workitem);
      const response = await httpClient.post(url, data);

      return this.deserialize(response.data);
    } catch (error) {
      const errorSerializer = new ErrorSerializer();
      const serializedError = errorSerializer.deserialize(error);
      tracer.trace("WorkItems", "Error", `${serializedError.message}`);
      console.error("Error:", serializedError);
      throw serializedError;
    }finally {
      tracer.trace('WorkItems', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new WorkItemSerializer()
    const item = serializer.deserialize(data)

    return item
  }

}