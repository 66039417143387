import { ProjectMetricModel } from "./projectMetric.model";

export class Project {
  id: number;
  name: string;
  description: string;
  parent: Project | undefined;
  children: Project[];
  metrics: ProjectMetricModel[];
  impact: number;
  difficulty: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.parent = undefined;
    this.children = [];
    this.metrics = [];
    this.impact = 0;
    this.difficulty = 0;
  }

}
