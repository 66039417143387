import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import React from "react";
import FormRow from "./FormRow";
import { dummyForms } from "./dummyForms";

const FormList = (props) => {

  const{
    onEdit,
  } = props

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  return (
    <>
      <div>
        <div>
          <div className="table-responsive w-100">
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0 w-100">
              <thead>
              <tr className="fs-7 fw-bold text-gray-400 border-bottom-0 mt-5">
                <th className="p-0 pb-3 min-w-200px text-start">FORM</th>
                <th className="p-0 pb-3 min-w-200px text-start">CASE TYPE</th>
                <th className="p-0 pb-3 min-w-200px text-start"># FIELDS</th>
              </tr>
              </thead>

              <tbody>
              {dummyForms.map((item) => (
                <FormRow key={item.id}
                            form={item}
                            onEdit={onEdit}
                            onDelete={null}
                />
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );

}

export default FormList