import React, { useEffect, useState } from "react";
import { WorkItemsService } from "../../../../shared/services/work-items/workItemsService";
import useAppStore from "../../../../appStore";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import WorkItemSortBy from "./components/WorkItemSortBy";
import LoadMoreButton from "../../../../shared/components/buttons/LoadMoreButton";
import { useSnapshot } from "valtio/index";
import { workItemStore } from "../../workItemStore";
import WorkItemListToolbar from "../../../../shared/components/buttons/WorkItemListToolbar";
import WorkItemTable from "./views/table-view/WorkItemTable";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import EmployeeQuickSelect from "../../../../shared/components/forms/widgets/selection-forms/EmployeeQuickSelect";
import { WorkItem } from "../../../../shared/models/workItem.model";

const WorkItemList = () => {

    const snap = useSnapshot(workItemStore);
    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const service = new WorkItemsService();
    const { selectedLocation, masterRefresh } = useAppStore();
    const [page, setPage] = useState(0);

    useEffect(() => {
        const fetchItems = async () => {
            const limit = 15;
            const offset = page * limit;
            if (page === 0) workItemStore.workItems = [];
            try {
                workItemStore.isLoading = true;
                const [items] = await Promise.all([service.getWorkItems(user.organization?.id, selectedLocation.id, undefined, limit, offset, snap.sortBy)]);
                workItemStore.workItems = [...workItemStore.workItems, ...items];
            } catch (error) {
                workItemStore.error = error instanceof Error ? error.message : String(error)
            } finally {
                workItemStore.isLoading = false;
            }
        };

        if (selectedLocation.id !== 0) {
            fetchItems().then();
        }

    }, [snap.activeStep, masterRefresh, snap.sortBy, selectedLocation, page]);


    const handleShowMore = () => {
        setPage(prevPage => prevPage + 1);
    };

  const handleCloseDialog = () => {
    workItemStore.assignModalOpen = false;
    workItemStore.workItemToEdit = new WorkItem();
  };

  const handleSaveEmployees = async (workItem, employees) => {
    workItem.assigned_to_employees = employees;
    try {
      await service.updateWorkItem(workItem);
      handleCloseDialog();
    } catch (e) {
      console.error('Error in handleSaveEmployees:', e);
    }
  };


    if (snap.error) return <CustomError error={snap.error} />;

    return (
      <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'center'
              }}>
                    <div
                      style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          marginBottom: "15px"
                      }}>
                        <WorkItemSortBy />
                        <WorkItemListToolbar />
                    </div>

                  <>
                    {snap.workItems.length === 0 && snap.isLoading &&
                      <CustomLoadingDots />
                    }
                    {snap.workItems.length > 0 &&
                      <WorkItemTable workItems={workItemStore.workItems} />
                    }
                    {snap.workItems.length > 0 && (snap.workItems.length % 15 === 0 || snap.isLoading) &&
                      <LoadMoreButton onClick={handleShowMore} isLoading={snap.isLoading} />
                    }
                    {snap.assignModalOpen && snap.workItemToEdit && (
                      <EmployeeQuickSelect open={snap.assignModalOpen} onClose={handleCloseDialog}
                                           handleSave={handleSaveEmployees} item={workItemStore.workItemToEdit} showSelectionBox={false} />
                    )}
                  </>
              </div>
          </div>
      </div>

    );
};

export default WorkItemList;



