import React, { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import TrackProjectsTable from "./table/TrackProjectsTable";
import { projectStore } from "../../../projectsStore";
import { ProjectsService } from "../../../../../shared/services/projects/projectsService";
import CustomLoadingDots from "../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../shared/components/page/popup-dialog/CustomError";



const ProjectsList = (props) => {
    const { onEdit, onDelete } = props;
    const snap = useSnapshot(projectStore);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        const fetchProjects = async () => {
            if (snap.selectedPlan) {
                setIsLoading(true);
                try {
                    setIsLoading(true);
                    const service = new ProjectsService();
                    const data = await service.getProjects(snap.selectedPlan?.id);
                    projectStore.projects = data;
                } catch (error) {
                    console.error('Error fetching projects:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchProjects().then();

    }, [snap.selectedPlan, snap.refreshProjects]);


    if (error) {
        return <CustomError error={error} />;
    }

    return (
      <>
          <TrackProjectsTable
            projects={snap.projects}
            onEdit={onEdit}
            onDelete={onDelete}
          />
      </>
    );
};

export default ProjectsList;
