import { proxy } from "valtio";
import { workItemStore } from "../work-items/workItemStore";
import { WorkItem } from "../../shared/models/workItem.model";
import { WorkItemsService } from "../../shared/services/work-items/workItemsService";
import { CasesService } from "../../shared/services/cases/casesService";
import casesStore from "../cases/caseStore";

interface SageStore {
  expanded: boolean;
  mode: string;
  prompt: string;
  isTyping: boolean;
  activeStep: number;
  restart: () => void;
  handleItemAction: (action: string) => void;
  showWorkItem: (id: number) => void;
  showCaseItem: (id: number) => void;
}

export const sageStore = proxy<SageStore>({
  expanded: false,
  mode: 'tell',
  prompt: '',
  isTyping: true,
  activeStep: 0,
  restart: () => {
    sageStore.activeStep = 0;
    sageStore.isTyping = true;
    sageStore.prompt = 'What action would you like to take?'
  },
  handleItemAction: (action: string) => {
    if (action === "cancel") {
      workItemStore.workItemToEdit = new WorkItem();
      sageStore.restart();
    } else if (action === "save") {
      sageStore.activeStep = 2;
    }
  },
  showWorkItem: async (id: number) => {
    sageStore.isTyping = true;
    sageStore.prompt = "Ok here is the item";
    sageStore.activeStep = 1;

    try {
      const service = new WorkItemsService();
      workItemStore.workItemToEdit = await service.getWorkItemDetails(id);
    } catch (err) {
      console.error("Failed to fetch work item details:", err);
    }
  },
  showCaseItem: async (id: number) => {
    sageStore.isTyping = true;
    sageStore.prompt = "Ok here is the case";
    sageStore.activeStep = 1;

    try {
      const service = new CasesService();
      casesStore.caseToEdit = await service.getDetailCase(id);
    } catch (err) {
      console.error("Failed to fetch case details:", err);
    }
  }

});

