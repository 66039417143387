import { PostSerializer } from "../../../models/serializers/postSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { ProjectPlanSerializer } from "../../../models/serializers/projectPlanSerializer";


export class CommandCreatePlan {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {

    return `${this.SERVICE}/project_plans/`;
  }

  async run(plan) {

    try {
      const url = this.getUrl()
      const data = ProjectPlanSerializer.serialize(plan)
      const response = await httpClient.post(url, data);
      return this.deserialize(response.data);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }

  }

  deserialize(data) {
    const serializer = new ProjectPlanSerializer()
    const plan = serializer.deserialize(data)
    return plan
  }

}