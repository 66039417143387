import { CommandGetTraces } from "./commands/cmdGetTraces";
import { CommandGetTraceDetails } from "./commands/cmdGetTraceDetails";
import { Employee } from "../../models/employee.model";


export class TraceService {

  async getTraces(search: string, offset: number, selectedEmployee: Employee | null, selectedStatus='All', selectedHttpMethod='All') {
    const cmd = new CommandGetTraces()
    const results = cmd.run(search, offset, selectedEmployee, selectedStatus, selectedHttpMethod)
    return results
  }

  async getTrace(id) {
    const cmd = new CommandGetTraceDetails()
    const results = cmd.run(id)
    return results
  }

}