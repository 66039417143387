import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  Select,
  MenuItem, useMediaQuery, useTheme
} from "@mui/material";
import useAppStore from "../../../../../../appStore";
import { CasesService } from "../../../../../../shared/services/cases/casesService";
import { GroupEntry } from "../../../../../../shared/models/groupEntry.model";
import { Media } from "../../../../../../shared/models/media.model";
import PhotoUploaderViewer from "../../../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import CaseInvolvedEmployees from "./employee-assignment/CaseInvolvedEmployees";
import CaseAssignees from "./employee-assignment/CaseAssignees";
import { CaseType } from "../../../../../../shared/models/caseType.model";
import { LocationModel } from "../../../../../../shared/models/location.model";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import casesStore from "../../../../caseStore";
import { Section } from "../../../../../../shared/models/caseForm.model";
import DynamicFormSection from "../../../dynamic-form/DynamicFormSection";
import CaseWorkItemCard from "./case-work-item/CaseWorkItemCard";

const CaseForm = (props) => {
  const {
    formErrors,
    incident=false
  } = props;

  const {t} = useTranslation();
  const snap = useSnapshot(casesStore);
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const service = new CasesService();
  const [departments, setDepartments] = useState<GroupEntry[]>([]);
  const [caseTypes, setCaseTypes] = useState<CaseType[]>([]);
  const [media, setMedia] = useState<Media[]>(casesStore.caseToEdit.media);
  const [customSections, setCustomSections] = useState<Section[]>([]);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));


  useEffect(() => {
    const fetchSections = async () => {
      try {
        const dynamicForm = await service.getDynamicForm(snap.caseToEdit.case_type);
        const fetchedSections = dynamicForm.form.sections;
        setCustomSections(fetchedSections);
      } catch (error) {
        console.error("Error fetching dynamic form:", error);
      }
    };

    if (snap.caseToEdit.case_type.id !== 0) {
      setCustomSections([]);
      fetchSections().then();
    }
  }, [snap.caseToEdit.case_type]);

  useEffect(() => {
    casesStore.caseToEdit.media = media;
  }, [media]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const depts = await service.getDepartments(user?.organization);
        setDepartments(depts);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    const fetchTypes = async (dept) => {
      try {
        const types = await service.getCaseTypes(user?.organization, dept);
        setCaseTypes(types);
      } catch (error) {
        console.error('Error fetching case types:', error);
      }
    };

    if (snap.caseToEdit.department?.id && snap.caseToEdit.department.id !== 0) {
      fetchTypes(casesStore.caseToEdit.department.id).then();
    }

    fetchDepartments().then();
  }, [user?.organization?.id, snap.caseToEdit.department]);

  const handleDescChange = (event) => {
    casesStore.caseToEdit.description = event.target.value;
  };

  const handleDepartmentChange = (dept: GroupEntry) => {
    casesStore.caseToEdit.department = dept;
  };

  const handleCaseTypeChange = (type: CaseType) => {
    casesStore.caseToEdit.case_type = type;
  };

  const handleLocationChange = (location: LocationModel) => {
    casesStore.caseToEdit.location = location;
  };

  return (
    <div className="flex-column-fluid" style={{ marginRight: isMobile ? '' : '75px' }}>
      <div style={{ marginBottom: "15px" }}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-700">{!incident ?  `${t('case-details')}` : 'Incident Details'}</span>
        </h3>
      </div>

      {snap.caseToEdit.id !== 0 &&
        <CaseWorkItemCard caseData={snap.caseToEdit} editing={true} />
      }

      <FormControl fullWidth style={{ marginBottom: 15 }}>
        <span className="text-gray-700">{t('location')}</span>
        <Select
          value={snap.caseToEdit.location?.id || ''}
        >
          {user?.permissions.locations.map((location) => (
            <MenuItem key={location.id} value={location.id} onClick={() => handleLocationChange(location)}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {!incident &&
        <>
          <FormControl fullWidth style={{ marginBottom: 15 }} error={formErrors.department}>
            <span className="text-gray-700">{t('department')}</span>
            <Select
              value={snap.caseToEdit.department?.name || ""}
            >
              {departments.map((dept) => (
                <MenuItem key={dept.id} value={dept.name} onClick={() => handleDepartmentChange(dept)}>{dept.name}</MenuItem>
              ))}
            </Select>
            {formErrors.department && <span style={{ color: 'red' }}>Department is required</span>}
          </FormControl>

          <FormControl fullWidth style={{ marginBottom: 15 }} disabled={snap.caseToEdit.department?.name === ''}
                       error={formErrors.caseType}>
            <span className={snap.caseToEdit.department?.name === '' ? "text-muted" : "text-gray-700"}>{t('case-type')}</span>
            <Select
              value={snap.caseToEdit.case_type?.name || ""}
            >
              {caseTypes.map((type) => (
                <MenuItem key={type.id} value={type.name} onClick={() => handleCaseTypeChange(type)}>{type.name}</MenuItem>
              ))}
            </Select>
            {formErrors.caseType && <span style={{ color: 'red' }}>Case Type is required</span>}
          </FormControl>
        </>
      }

      <span className="text-gray-700">{`${t('description')}`}</span>
      <TextField
        name="Description"
        value={snap.caseToEdit.description}
        onChange={handleDescChange}
        fullWidth
        multiline
        rows={5}
      />

      <div style={{ marginTop: 15 }}>
        <CaseInvolvedEmployees />
      </div>

      {customSections.map((section) => (
        <DynamicFormSection section={section} />
      ))}

      <div style={{ marginTop: 15 }}>
        <CaseAssignees />
      </div>

      <div style={{ marginTop: 15 }}>
        <span className="text-gray-700">{t('supporting-documents')}</span>
        <PhotoUploaderViewer
          item={casesStore.caseToEdit}
          setMedia={setMedia}
          captions={snap.caseToEdit.status === 'Closed'}
          module={'cases'}
        />
      </div>

    </div>
  );
};

export default CaseForm;


