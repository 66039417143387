import React from 'react';
import { formatPhoneNumber } from "../../../utils/data-transform/dataUtil";
import { employeeStore } from "../../../../modules/employees/employeeStore";
import { EmployeeService } from "../../../services/employee/employeeService";

const EmployeeTinyCard= ({ name, phone_number, emp_id }) => {

  const employeeService = new EmployeeService();

  const chipStyle: React.CSSProperties = {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '0',
    paddingRight: '0',
    margin: '4px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    textAlign: 'center',
    cursor: 'pointer',
    width: '150px',
  };


  const nameStyle: React.CSSProperties = {
    fontWeight: 600,
    fontSize: '0.875rem',
    marginBottom: '4px',
  };

  const phoneStyle: React.CSSProperties = {
    color: '#0d6efd',
    fontSize: '0.75rem',
  };

  const handleViewEmployee = async (emp_id) => {
    if (!emp_id) return;

    try {
      employeeStore.editing = false;
      employeeStore.employeeToEdit = await employeeService.getEmployee(emp_id);
      employeeStore.mode = 'basic';
    } catch (error) {
      console.error('Failed to fetch employee:', error);
    } finally {
      employeeStore.open = true;
    }
  };


  return (
    <div style={chipStyle} onClick={() => handleViewEmployee(emp_id)}>
      <span style={nameStyle}>
        {name}
      </span>
      <span style={phoneStyle}>
        {formatPhoneNumber(phone_number)}
      </span>
    </div>
  );
};

export default EmployeeTinyCard;