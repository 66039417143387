import { LocationModel } from "./location.model";
import { Employee } from "./employee.model";
import { Organization } from "./organization.model";
import { Media } from "./media.model";
import { AppDate } from "../utils/date-time/AppDate";
import { WhereAndWhatModel } from "./whereAndWhat.model";
import { SourceModel } from "./source.model";
import { UrgencyContextModel } from "./urgencyContext.model";

export class WorkItem {
  provider: Organization;
  location: LocationModel;
  id: number;
  title: string;
  type: string;
  description: string;
  created_by: Employee;
  create_date: AppDate;
  assigned_to_employees: Employee[];
  urgency: number;
  is_overdue: boolean;
  time_remaining: number;
  time_overdue: string;
  status: string;
  media: Media[];
  due_date: string;
  where_and_what: WhereAndWhatModel[];
  is_good_catch: boolean;
  is_viewed: boolean;
  source: SourceModel | null;
  urgency_context: UrgencyContextModel;


  constructor() {
    this.provider = new Organization();
    this.location = new LocationModel();
    this.id = 0;
    this.title = "";
    this.type = "";
    this.description = "";
    this.created_by = new Employee();
    this.assigned_to_employees = [];
    this.urgency = 0;
    this.is_overdue = false;
    this.time_remaining = 0;
    this.time_overdue = "0d";
    this.media = [];
    this.status = "";
    this.due_date = new AppDate().dateTime;
    this.create_date = new AppDate().dateTime;
    this.where_and_what = [];
    this.is_good_catch = false;
    this.is_viewed = false;
    this.source = null;
    this.urgency_context = new UrgencyContextModel();
  }

}
