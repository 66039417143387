import React, { useState, useEffect } from 'react';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  CircularProgress,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Chip,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import useAppStore from "../../../../../appStore";
import { EmployeeService } from "../../../../services/employee/employeeService";
import { Employee } from "../../../../models/employee.model";
import EditEmployee from "../../../../../modules/employees/components/edit-employee/EditEmployee";
import { Organization } from '../../../../models/organization.model';
import { OrganizationService } from "../../../../services/organizations/organizationService";
import { useSnapshot } from "valtio";
import { employeeStore } from "../../../../../modules/employees/employeeStore";

const EmployeeSelect = ({ open, onClose, selection, setSelection, singleSelect=false, shadowMode = false }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const snap = useSnapshot(employeeStore);
  const {appImages} = useAppStore();
  const managerPermissions = user?.permissions?.hasAccess('Manager Home') || false;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [employeeSearchResult, setEmployeeSearchResult] = useState<Employee[]>([]);
  const [searchFor, setSearchFor] = useState('');
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>(user.organization);

  const fetchOrganizations = async () => {
    setIsLoading(true);
    try {
      const orgService = new OrganizationService();
      const orgs = await orgService.getOrgs(user.organization);
      console.log(orgs);
      setOrganizations(orgs);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (shadowMode) {
      fetchOrganizations().then();
    }
  }, [shadowMode]);



  useEffect(() => {
    const fetchEmployees = async () => {
      setIsLoading(true);
      try {
        const service = new EmployeeService();
        const employees = await service.searchEmployees(selectedOrganization.id, searchFor);
        setEmployeeSearchResult(employees);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (searchFor !== '') {
      fetchEmployees().then();
    }
  }, [searchFor, user.organization.id]);

  const handleSearch = () => {
    setSearchFor(searchQuery);
  };

  const handleRemoveEmployee = (employeeId) => {
    if (!singleSelect) {
      setSelection(selection.filter((e) => e.id !== employeeId));
    } else {
      setSelection(null);
    }
  };

  const handleNewEmployee = () => {
    employeeStore.employeeToEdit = new Employee();
    employeeStore.mode = 'quick';
    employeeStore.open = true;
    employeeStore.editing = true;
  };

  const handlerCloseEditModal = (action, newEmployee) => {
    employeeStore.open = false;
    employeeStore.mode = 'basic';
    employeeStore.employeeToEdit = new Employee();
    employeeStore.editing = false;
    setEmployeeSearchResult([]);
    setSearchFor('');
    setSearchQuery('');
    if (action === 'save' && newEmployee) {
      if (!singleSelect) {
        setSelection((prevEmployees) => [...prevEmployees, newEmployee]);
      } else {
        setSelection(newEmployee);
      }
    }
  };

  const renderSelectedEmployees = () => {
    if (!singleSelect) {
      return selection.map((employee) => (
        <Chip
          key={employee.id}
          label={`${employee.person.first_name} ${employee.person.last_name}`}
          onDelete={() => handleRemoveEmployee(employee.id)}
          deleteIcon={<CancelIcon />}
          sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
        />
      ));
    } else if (selection) {
      return (
        <Chip
          key={selection.id}
          label={`${selection.person.first_name} ${selection.person.last_name}`}
          onDelete={() => handleRemoveEmployee(selection.id)}
          deleteIcon={<CancelIcon />}
          sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
        />
      );
    } else {
      return null;
    }
  };

  const handleListItemClick = (employee) => {
    if (!singleSelect) {
      if (selection.find((e) => e.id === employee.id)) {
        setSelection(selection.filter((e) => e.id !== employee.id));
      } else {
        setSelection([...selection, employee]);
      }
    } else {
      if (selection && selection.id === employee.id) {
        setSelection(null);
      } else {
        setSelection(employee);
      }
    }
  };

  const handleSubmit = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <AppBar sx={{ position: 'relative', backgroundColor: '#333' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <div style={{ flexGrow: 1 }}></div>
          {shadowMode &&
           <img src={appImages.iconShadow} style={{height: '45px', width: '45px'}} />
          }
          {managerPermissions && !singleSelect && (
            <Button
              color="inherit"
              variant="contained"
              onClick={handleNewEmployee}
              sx={{ color: '#444444' }}
            >
              Create Employee
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {!singleSelect &&
              <Box sx={{ border: '1px solid lightgrey', p: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Assigned Employees:
                </Typography>
                {(!singleSelect ? selection.length > 0 : selection) ? (
                  <Box>{renderSelectedEmployees()}</Box>
                ) : (
                  <Typography>No employees selected.</Typography>
                )}
              </Box>
            }
            {shadowMode && organizations.length > 0 && (
              <FormControl fullWidth margin="dense">
                <InputLabel id="organization-select-label">Organization</InputLabel>
                <Select
                  labelId="organization-select-label"
                  id="organization-select"
                  value={selectedOrganization ? String(selectedOrganization.id) : ''}
                  label="Organization"
                  onChange={(e) => {
                    const orgId = parseInt(e.target.value, 10);
                    const org = organizations.find((o) => o.id === orgId);
                    if (org) {
                      setSelectedOrganization(org);
                    }
                  }}
                >
                  {organizations.map((org) => (
                    <MenuItem key={org.id} value={String(org.id)}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  placeholder="Search employees..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') handleSearch();
                  }}
                />
                <Button variant="contained" onClick={handleSearch} style={{ marginLeft: '10px', width: '20%', height: '40px' }}>
                  Search
                </Button>
              </div>
              {employeeSearchResult.length > 0 && (
                <List>
                  {employeeSearchResult.map((employee) => (
                    <ListItem
                      key={employee.id}
                      button
                      selected={
                        !singleSelect
                          ? !!selection.find((e) => e.id === employee.id)
                          : selection && selection.id === employee.id
                      }
                      onClick={() => handleListItemClick(employee)}
                    >
                      <ListItemText
                        primary={`${employee.person.first_name} ${employee.person.last_name}`}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          </>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      {snap.open && (
        <EditEmployee
          open={snap.open}
          onActionClicked={handlerCloseEditModal}
        />
      )}
      <DialogActions>
        <Button onClick={handleSubmit} color="primary" disabled={!selection}>
          {!shadowMode ? 'Confirm' : 'Shadow Employee'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeSelect;
