import React, { useState } from "react";
import { Box, CardMedia } from "@mui/material";
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Media } from "../../../../models/media.model";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import PdfViewer from "../PdfViewer";
import { getMediaType } from "../../../../utils/table-modifier-util/tableDataUtil";

const MediaRowSquares = ({ itemMedia, captions = false, size=40, closed=false }) => {
  const [photoOpen, setPhotoOpen] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);

  const handleOpen = (url) => {
    if (getMediaType(url) === 'pdf') {
      setPdfOpen(true);
    } else {
      setPhotoOpen(true);
    }
  };

  const handleClose = () => {
    setPhotoOpen(false);
  };


  const displayPhotos = [...itemMedia, new Media(), new Media(), new Media()].slice(0, 3);

  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center">
        {displayPhotos.map((photo, index) => (
          <Box
            key={index}
            width={size}
            height={size}
            margin={0.5}
            border={1}
            borderColor={"grey.300"}
            onClick={() => photo.url && handleOpen(photo.url)}
            sx={{
              borderRadius: 2,
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            {photo.url ? (
              <CardMedia
                component="img"
                image={getMediaType(photo.url) === 'image' ? photo.url : "/white-labeling/verde/media/menu-icons/pdf-icon.png"}
                alt={`${index + 1}`}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Box width="100%" height="100%" />
            )}
          </Box>
        ))}
      </Box>
      {photoOpen && (
        <Lightbox
          open={photoOpen}
          close={() => handleClose()}
          plugins={[Captions]}
          slides={[...itemMedia].map(photo => ({ src: photo.url, description: captions ? (photo.workitem_status === 'Closed' ? 'After' : 'Before') : '' }))}
        />
      )}
      {pdfOpen && (
        <PdfViewer open={pdfOpen} onClose={() => setPdfOpen(false)} url={itemMedia[0]?.url} />
      )}
    </div>
  );
};

export default MediaRowSquares;



