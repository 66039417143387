import React, { useEffect, useState } from "react";
import useAppStore from "../../../../../appStore";
import { CasesService } from "../../../../../shared/services/cases/casesService";
import { GroupEntry } from "../../../../../shared/models/groupEntry.model";
import { Media } from "../../../../../shared/models/media.model";
import { Button, FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import RelatedEmployeesField from "./reusable-fields/RelatedEmployeesField";
import { CaseType } from "../../../../../shared/models/caseType.model";
import BaseCaseDetails from "./reusable-fields/BaseCaseDetails";
import CustomError from "../../../../../shared/components/page/popup-dialog/CustomError";
import casesStore from "../../../caseStore";
import { useSnapshot } from "valtio";
import caseStore from "../../../caseStore";
import CaseReadOnly from "../../edit-case/components/details/read-only/CaseReadOnly";
import { sageStore } from "../../../../sage-virtual-assistant/sageStore";
import DynamicFormSection from "../../dynamic-form/DynamicFormSection";
import CreatedCaseCard from "../../../../sage-virtual-assistant/created-items-assist/CreatedCaseCard";

const SageCaseCreate = ({ sections, error }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const snap = useSnapshot(casesStore);
  const { selectedLocation } = useAppStore();
  const service = new CasesService();
  const [departments, setDepartments] = useState<GroupEntry[]>([]);
  const [caseTypes, setCaseTypes] = useState<CaseType[]>([]);
  const [media, setMedia] = useState<Media[]>(casesStore.caseToEdit.media);

  useEffect(() => {
    if (snap.caseToEdit.id === null || snap.caseToEdit.id === 0) {
      casesStore.caseToEdit.created_by = user.employee;

      if (snap.caseToEdit.location.id === null || snap.caseToEdit.location.id <= 0) {
          casesStore.caseToEdit.location.id = selectedLocation.id || 0;
      }
      casesStore.caseToEdit.provider.id = user.organization?.id || 0;
    }
  }, [snap.caseToEdit.id]);

  useEffect(() => {
    casesStore.caseToEdit.media = media;
  }, [media]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const depts = await service.getDepartments(user?.organization);
        setDepartments(depts);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments().then();
  }, [user?.organization?.id]);

  useEffect(() => {
    if (snap.caseToEdit.department?.id) {
      const fetchTypes = async () => {
        try {
          const types = await service.getCaseTypes(
            user?.organization,
            snap.caseToEdit.department.id
          );
          setCaseTypes(types);
        } catch (error) {
          console.error("Error fetching case types:", error);
        }
      };

      fetchTypes().then();
    }
  }, [snap.caseToEdit.department]);

  const handleDepartmentChange = (dept: GroupEntry) => {
    casesStore.caseToEdit.department = dept;
    sageStore.isTyping = true;
    casesStore.activeSection++;
  };

  const handleCaseTypeChange = (type: CaseType) => {
    casesStore.caseToEdit.department = type.department;
    casesStore.caseToEdit.case_type = type;
    sageStore.isTyping = true;
    casesStore.activeSection++;
  };

  const handleNextStep = () => {
    sageStore.isTyping = true;
    casesStore.activeSection++;
  };

  // Determine which content to render based on currentStep
  const renderContent = () => {
    if (snap.activeSection < snap.initialSteps) {
      return renderInitialStep(snap.activeSection);
    } else if (snap.activeSection < snap.initialSteps + sections.length) {
      const sectionIndex = snap.activeSection - snap.initialSteps;
      const currentSection = sections[sectionIndex];
      return renderCustomSection(currentSection);
    } else {
      return renderEndingStep(snap.activeSection - snap.initialSteps - sections.length);
    }
  };

  const renderInitialStep = (step) => {
    switch (step) {
      case 0:
        return (
          <FormControl fullWidth style={{ marginBottom: 15 }}>
            <Box>
              {departments.map((dept) => (
                <Button
                  key={dept.id}
                  onClick={() => handleDepartmentChange(dept)}
                  fullWidth
                  style={{ marginBottom: 10, fontSize: "1.1rem" }}
                >
                  {dept.name}
                </Button>
              ))}
            </Box>
          </FormControl>
        );
      case 1:
        return (
          <FormControl fullWidth style={{ marginBottom: 15 }}>
            <Box>
              {caseTypes.map((type) => (
                <Button
                  key={type.id}
                  onClick={() => handleCaseTypeChange(type)}
                  fullWidth
                  style={{ marginBottom: 10, fontSize: "1.1rem" }}
                >
                  {type.name}
                </Button>
              ))}
            </Box>
          </FormControl>
        );
      case 2:
        return (
          <div style={{ marginTop: 15 }}>
            <RelatedEmployeesField
              itemToEdit={caseStore.caseToEdit}
              onSubmit={handleNextStep}
            />
          </div>
        );
      case 3:
        return (
          <div style={{ marginTop: 15 }}>
            <BaseCaseDetails />
          </div>
        );
      default:
        return null;
    }
  };

  const renderCustomSection = (currentSection) => {
    return (
      <DynamicFormSection section={currentSection} />
    );
  };

  const renderEndingStep = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{padding: '10px', margin: '1px', backgroundColor: '#f8f8f8', boxShadow: '1px 1px 5px rgba(0,0,0,0.2)', borderRadius: '5px'}}>
            <CaseReadOnly headers={false}/>
          </Box>
        );
      case 1:
        return (
          <>
            {error !== '' ? (
              <CustomError error={error} />
            ) : (
              <CreatedCaseCard item={snap.caseToEdit} />
            )}
          </>
        );
      default:
        return null;
    }
  };

  return <div className="flex-column-fluid">{renderContent()}</div>;
};

export default SageCaseCreate;


