import { CommandGetEmployees } from "./commands/cmdGetEmployees";
import { CommandGetPositions } from "./commands/cmdGetPositions";
import { CommandCreateEmployee } from "./commands/cmdCreateEmployee";
import { CommandUpdateEmployee } from "./commands/cmdUpdateEmployee";
import { CommandDeleteEmployee } from "./commands/cmdDeleteEmployee";
import { CommandGetEmployee } from "./commands/cmdGetEmployee";
import { Employee } from "../../models/employee.model";
import { CommandGetOrgChartEmployees } from "./commands/cmdGetOrgChartEmployees";
import { CommandSearchEmployees } from "./commands/cmdSearchEmployees";
import { CommandPostDepartment } from "./commands/cmdPostUserDepartment";
import { CommandGetEmpDepartments } from "./commands/cmdGetEmpDepartments";
import { CommandDeleteUserDepartment } from "./commands/cmdDeleteUserDepartment";

export class EmployeeService {

    async getEmployees(provider_id, location_id, offset=0, limit=Number.MAX_SAFE_INTEGER, search=null) {
        const cmd = new CommandGetEmployees();
        return await cmd.run(provider_id, location_id, offset, limit, search);
    }

    async searchEmployees(provider, search, location_id: number | undefined = undefined) {
        const cmd = new CommandSearchEmployees();
        return await cmd.run(provider, search, location_id);
    }

    async getOrgChartEmployees(provider_id, location_id, offset=0, limit=Number.MAX_SAFE_INTEGER) {
        const cmd = new CommandGetOrgChartEmployees();
        return await cmd.run(provider_id, location_id, offset, limit);
    }

    async getEmployee(id) {
        if (id !== 0) {
            const cmd = new CommandGetEmployee();
            return await cmd.run(id);
        } else {
            return new Employee();
        }
    }

    async getPositions(provider_id) {
        const cmd = new CommandGetPositions();
        return await cmd.run(provider_id);
    }

    async getUserDepartments(provider_id, employee_id) {
        const cmd = new CommandGetEmpDepartments();
        return await cmd.run(provider_id, employee_id);
    }

    async createEmployee(employee, provider_id, location_id) {
        const cmd = new CommandCreateEmployee();
        return await cmd.run(employee, provider_id, location_id);
    }

    async linkDepartment(employee_id, department_id) {
        const cmd = new CommandPostDepartment();
        return await cmd.run(employee_id, department_id);
    }

    async unlinkDepartment(department_id, employee_id) {
        const cmd = new CommandDeleteUserDepartment();
        return await cmd.run(department_id, employee_id);
    }

    async updateEmployee(employee) {
        const cmd = new CommandUpdateEmployee();
        return await cmd.run(employee);
    }

    async deleteEmployee(employeeId) {
        const cmd = new CommandDeleteEmployee();
        return await cmd.run(employeeId);
    }
}
