export class ProjectMetricModel {
  id: number;
  name: string;
  description: string;
  metric_id: number;
  metric_name: string;
  metric_score: number;
  project_id: number;
  project_plan_id: number;
  category_id: number;
  category_name: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.metric_id = 0;
    this.metric_name = '';
    this.metric_score = 0.0;
    this.project_id = 0;
    this.project_plan_id = 0;
    this.category_id = 0;
    this.category_name = '';
  }

}