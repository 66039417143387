import React, { useRef, useState } from "react";
import { WorkItem } from '../../../../../shared/models/workItem.model';
import ModalFooterActions from '../../../../../shared/components/buttons/ModalFooterActions';
import WorkItemPrintCommand from "./WorkItemPrintCommand";
import { workItemStore } from "../../../workItemStore";
import { PrintableWorkItemRow } from "./PrintableWorkItemRow";
import { useSnapshot } from "valtio";


type Props = {
  workItems: WorkItem[];
  onClose: () => void;
  handleStepChange: (step: number) => void;
};

const PrintableWorkItemsTable: React.FC<Props> = ({
                                                workItems,
                                                onClose,
                                                handleStepChange,
                                              }) => {
  const [selectedWorkItems, setSelectedWorkItems] = useState<WorkItem[]>([]);
  const printRef = useRef<{ handlePrint: () => Promise<void> }>(null);

  const handleSelectionChange = (item: WorkItem) => {
    setSelectedWorkItems((prevSelected) =>
      prevSelected.some((selected) => selected.id === item.id)
        ? prevSelected.filter((selected) => selected.id !== item.id)
        : [...prevSelected, item]
    );
  };

  const handlePrint = async () => {
    if (printRef.current) {
      await printRef.current.handlePrint();
    }
  };

  return (
    <>

      <div className="card mt-10">
        <div className="card-body py-3">
          <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
              <tr className="fw-bold text-muted">
                <th className="min-w-15px"></th>
                <th className="min-w-250px">Who</th>
                <th className="min-w-250px">Where</th>
                <th className="min-w-250px">What</th>
                <th className="min-w-225px">Urgency</th>
              </tr>
              </thead>
              <tbody>
              {workItems.map((item) => (
                <PrintableWorkItemRow
                  key={item.id}
                  item={item}
                  onSelect={handleSelectionChange}
                  isSelected={selectedWorkItems.some((selected) => selected.id === item.id)}
                  condensed={false}
                />
              ))}
              </tbody>
            </table>
          </div>
        </div>
        <ModalFooterActions onClose={onClose} onSave={handlePrint} text={"Print Selected"} />
      </div>

      <WorkItemPrintCommand ref={printRef} items={selectedWorkItems} onClose={onClose} />
    </>
  );
};


export { PrintableWorkItemsTable };
