import React from "react";

const ItemProperty = (props) => {
  const { fontIcon, name, value, colorClass } = props;

  return (
    <div className="d-flex" style={{ marginBottom: '5px' }}>
      <div className="symbol symbol-35px me-4">
                        <span className={colorClass}>
                                  <i className={fontIcon} style={{ color: "gray" }}></i>
                        </span>
      </div>
      <div className="d-flex justify-content-start flex-column">
        <span className="text-gray-400 fw-semibold d-block fs-7">{name}</span>
        <a className="text-gray-800 fw-bold mb-1 fs-6">{value}</a>
      </div>
    </div>
  );
};

export default ItemProperty;