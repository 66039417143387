import React, { useEffect } from "react";
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import {
    Alert,
    AppBar, Box, Button,
    Dialog,
    DialogActions,
    IconButton,
    Typography
} from "@mui/material";
import SpaceSelection from "./components/space-selection/SpaceSelection";
import Scoring from "./components/scoring/Scoring"
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import { useEntityValidateAndSave } from "./useEntityValidateAndSave";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import useAppStore from "../../../../../../appStore";
import { inspectedEntityStore } from "../../../../inspectedEntityStore";

const EditInspectedEntity = (props) => {

    const {
        open,
        onActionClicked,
    } = props

    const {
        scrollableContainerRef,
        validationInfo, handlePrevClicked, handleNextClicked,
        handleSaveInspectedEntity
    } = useEntityValidateAndSave();

    const {t} = useTranslation();
    const {selectedLocation} = useAppStore();
    const inspectedEntitySnap = useSnapshot(inspectedEntityStore);

    useEffect(() => {
        if (selectedLocation.id !== 0) {
            inspectedEntityStore.handleFetchBuildings(selectedLocation.id).then();
        }
    }, [selectedLocation.id]);


    useEffect(() => {
        if (selectedLocation.id !== 0 && inspectedEntitySnap.selectedFloor && inspectedEntitySnap.selectedFloor?.id !== 0) {
            inspectedEntityStore.handleFetchTypes(selectedLocation.id, inspectedEntitySnap.selectedFloor.id).then();
        }
    }, [selectedLocation.id, inspectedEntitySnap.selectedFloor?.id]);

    useEffect(() => {
        if (selectedLocation.id !== 0 && inspectedEntitySnap.selectedBuilding && inspectedEntitySnap.selectedBuilding?.id !== 0) {
            inspectedEntityStore.handleFetchFloors(selectedLocation.id, inspectedEntitySnap.selectedBuilding.id).then();
        }
    }, [selectedLocation.id, inspectedEntitySnap.selectedBuilding]);

    useEffect(() => {

        if( inspectedEntitySnap.inspectedEntity.id === null && inspectedEntitySnap.inspectedEntity.entity_type && inspectedEntitySnap.inspectedEntity.id !== 0) {
            inspectedEntityStore.handleFetchServiceItems().then();
        }else{
            inspectedEntityStore.serviceItemScores = inspectedEntityStore.inspectedEntity.service_item_scores
        }

    }, [inspectedEntitySnap.inspectedEntity.entity_type ]);


    useEffect(() => {
        inspectedEntityStore.handleGoToStep(1);
    }, [inspectedEntitySnap.inspectedEntity.id]);


    if (inspectedEntitySnap.isValidating) {
        return <CustomLoadingDots />;
    }
    if (inspectedEntitySnap.submissionError) {
        return <CustomError error={inspectedEntitySnap.submissionError} />;
    }

    const handleClose = () => {
        onActionClicked();
        inspectedEntityStore.handleGoToStep(0);
        validationInfo.passed = true
        validationInfo.messages = []
    }

    return (

      <>
          <Dialog
            open={open}
            onClose={onActionClicked}
            TransitionComponent={ModalTransition}
          >
              <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onActionClicked}
                        aria-label="close"
                      >
                          <CloseIcon />
                      </IconButton>
                      <Typography sx={{ flex: 1 }} variant="h5" component="div">
                          {t('inspect')}
                      </Typography>
                      <Button color="inherit" variant="contained" onClick={handleClose} sx={{ color: '#444444' }}>
                          {t('close')}
                      </Button>
                  </Toolbar>
              </AppBar>

              { !validationInfo.passed && (

                <Alert className="mt-5 mx-5"
                       variant="outlined"
                       severity="error"
                >
                    {validationInfo.messages.map((msg, index) => (
                      <>
                          <Box component="label" sx={{ fontSize: '16px' }}>
                              {msg}
                          </Box>
                          <br/>
                      </>

                    ))}
                </Alert>
              )}
              <div className='card mt-5'
                   style={{height: 'calc(100vh - 250px)'}}
              >
                  <div ref={scrollableContainerRef}
                       className="card-body d-flex flex-row-fluid"
                       style={{overflowY: 'scroll'}}
                  >
                      <div style={{width:'100%'}}>
                          <SwipeableViews
                            disabled={true}
                            index={inspectedEntitySnap.activeStep}
                          >
                              <SpaceSelection />
                              <Scoring />
                          </SwipeableViews>
                      </div>
                  </div>
              </div>
              <DialogActions sx={{ position: 'sticky',
                  bottom: 0,
                  backgroundColor: '#fafafa',
                  zIndex: 2
              }}>
                  <Button disabled={inspectedEntitySnap.prevDisabled}
                          onClick={handlePrevClicked}>{t('prev')}</Button>
                  <Button disabled={inspectedEntitySnap.nextDisabled}
                          onClick={handleNextClicked}>{t('next')}</Button>
                <Button variant="contained" color="primary" onClick={() => handleSaveInspectedEntity(false)} disabled={inspectedEntitySnap.submitDisabled}>
                    Save
                </Button>
              </DialogActions>
          </Dialog>
      </>

    )

}

export default EditInspectedEntity

