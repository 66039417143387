import { useRef, useState } from "react";
import useAppStore from '../../../../../../appStore';
import useTracer from '../../../../../../shared/framework/tracer/tracer';
import { InspectionService } from "../../../../../../shared/services/inspections/inspectionService";
import { inspectionsStore } from "../../../../inspectionsStore";
import { inspectedEntityStore } from "../../../../inspectedEntityStore";
import { useSnapshot } from "valtio";

export interface ValidationResult {
    passed: boolean;
    messages: string[];
  }

  export function useEntityValidateAndSave() {

    const service = new InspectionService();
    const inspectedEntitySnap = useSnapshot(inspectedEntityStore);
    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const { selectedLocation} = useAppStore();
    const scrollableContainerRef = useRef<any>();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [validationInfo, setValidationInfo] = useState<ValidationResult>({passed: true, messages: []})
    const tracer = useTracer();


    let biRef = {
      selectionPage: {
        validate: (): ValidationResult => {
          return {passed: true, messages: []}
        }
      },
      scorePage: {
        validate: (): ValidationResult => {
          return {passed: true, messages: []}
        }
      }
    }

    const validateStep = () => {
      const valInfo = biRef.scorePage.validate();
      setValidationInfo(valInfo);
      if (!valInfo.passed) return false;
      inspectedEntityStore.isValidating = true;
      return true;
    };

    const finalize = (continueInspection: boolean) => {
      inspectedEntityStore.isValidating = false;
      inspectedEntityStore.handleGoToStep(1);
      if (!continueInspection) {
        handlePrevClicked();
        handleClose();
      }
    };


    const handleNextClicked = () => {
      if (inspectedEntitySnap.activeStep === 0 ){
        let valInfo = biRef.selectionPage.validate()
        setValidationInfo(valInfo)
        if (!valInfo.passed) return
      }
      inspectedEntityStore.handleGoToStep(1)
    }

    const handlePrevClicked = () => {
      inspectedEntityStore.handleGoToStep(0);
    }

    const handleMenuClick = (event) => {
      setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setMenuAnchorEl(null);
    };

    const handleMenuItemClick = (action) => {
      handleMenuClose();
    };

    const handleClose = () => {
      inspectedEntityStore.handleGoToStep(0);
      inspectedEntityStore.creating = false;
      inspectedEntityStore.editing = false;
      validationInfo.passed = true
      validationInfo.messages = []
      setValidationInfo(validationInfo)
    };

    const handleInitializeInspection = () => {
      if (inspectionsStore.inspectionToEdit) {
        inspectionsStore.inspectionToEdit.provider.id = user.organization.id;
        inspectionsStore.inspectionToEdit.location.id = selectedLocation ? selectedLocation.id : 0;
        inspectionsStore.inspectionToEdit.inspected_by.id = user ? user.employee.id : 0;
        inspectionsStore.inspectionToEdit.inspected_entities = [...inspectionsStore.inspectionToEdit.inspected_entities, inspectedEntityStore.inspectedEntity];
      }
    };

    const handleSaveInspectedEntity = async (continueInspection: boolean) => {
      if (!validateStep()) return;

      try {
        if (inspectionsStore.inspectionToEdit) {
         handleInitializeInspection();

          if (!inspectionsStore.inspectionToEdit.id) {
            const newInspection = await service.createInspection(inspectionsStore.inspectionToEdit);
            inspectedEntityStore.handleUpdateInspectedEntity(newInspection.inspected_entities[0]);
            inspectionsStore.handleFetchInspectionDetails(newInspection.id).then();
          } else {
            const entity = inspectedEntitySnap.inspectedEntity.id ? await service.updateInspectedEntity(inspectionsStore.inspectionToEdit, inspectedEntityStore.inspectedEntity) : await service.createInspectedEntity(inspectionsStore.inspectionToEdit, inspectedEntityStore.inspectedEntity);
            inspectedEntityStore.handleUpdateInspectedEntity(entity);
            inspectionsStore.handleFetchInspectionDetails(inspectionsStore.inspectionToEdit.id).then();
            if (!continueInspection && inspectedEntityStore.editing) {
              handleClose();
            }

          }
        }
        inspectedEntityStore.handleResetInspectedEntityDetails();
      } catch (error) {
        if (error instanceof Error) inspectedEntityStore.submissionError = error.message;
      } finally {
        finalize(continueInspection);
      }
    };



    const handleSaveAndPrepWorkItem = async () => {
      if (!validateStep()) return;
      try {
        if (inspectionsStore.inspectionToEdit) {
          handleInitializeInspection();
          if (!inspectionsStore.inspectionToEdit.id) {
           const newInspection = await service.createInspection(inspectionsStore.inspectionToEdit);
            inspectedEntityStore.handleUpdateInspectedEntity(newInspection.inspected_entities[0]);
            inspectionsStore.handleFetchInspectionDetails(newInspection.id).then();
          } else {
            const entity = inspectedEntitySnap.inspectedEntity.id ? await service.updateInspectedEntity(inspectionsStore.inspectionToEdit, inspectedEntityStore.inspectedEntity) : await service.createInspectedEntity(inspectionsStore.inspectionToEdit, inspectedEntityStore.inspectedEntity);
            inspectedEntityStore.handleUpdateInspectedEntity(entity);
            inspectionsStore.handleFetchInspectionDetails(inspectionsStore.inspectionToEdit.id).then();
          }
        }
      } catch (error) {
        if (error instanceof Error) inspectedEntityStore.submissionError = error.message;
      } finally {
        inspectedEntityStore.isValidating = false;
        inspectedEntityStore.handleGoToStep(0);
      }
    };

  return {
    scrollableContainerRef,
    biRef,
    validationInfo,
    selectedLocation,
    tracer,
    menuAnchorEl,
    setValidationInfo,
    setMenuAnchorEl,
    handleNextClicked,
    handlePrevClicked,
    handleMenuClick,
    handleMenuClose,
    handleMenuItemClick,
    handleSaveInspectedEntity,
    handleSaveAndPrepWorkItem,
  };
}
