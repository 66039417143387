import React from "react";
import { useSnapshot } from "valtio/index";
import { projectStore } from "../../../../../projectsStore";
import ProgressBar
  from "../../../../../../analytics/components/site-health/components/tabs/corp-overview/components/card/components/ProgressBar";
import ActionButton from "../../../../../../../shared/components/buttons/ActionButton";

const TrackProjectsRow = (props) => {
    const {
        project,
        categories,
        onEdit,
        onDelete,
    } = props;

    const snap = useSnapshot(projectStore);

    return (
      <tr>
        {/* Project Name Column */}
        <td className="text-start">
          {project.name}
        </td>

        <td className="text-start">
          <ProgressBar progress={50} colorClass={"bg-success"} />
        </td>

        {/* Actions Column */}
        <td className="text-end">
          <div className="d-flex flex-shrink-0 justify-content-end">
            <ActionButton iconType="view" onClick={(e) => {
              e.preventDefault();
            }} />
          </div>
        </td>
      </tr>
    )
}

export default TrackProjectsRow;
