import React from "react";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import CircleIcon from '@mui/icons-material/Circle';
import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";

const WhereAndWhatRow = (props) => {

  const {
    entry,
    onDelete,
    isMobile,
    isEditing
  } = props;

  const renderServiceItems = () => {
    if (entry.service_items && entry.service_items.length > 0) {
      return entry.service_items.map((item, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="start"
          color="primary.main"
          fontStyle="italic"
          fontSize="0.875rem"
        >
          <CircleIcon sx={{ fontSize: "0.7rem", mr: 0.5, mt: 0.5 }} />
          <Box>
            <Typography variant="body1">
              {item.entity_type?.name || item.name}
            </Typography>
            {item.details && (
              <Box ml={2}>
                <Typography variant="body2" color="text.secondary">
                  {item.details}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ));
    } else {
      return (
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", fontWeight: "bold" }}
        >
          No service items available
        </Typography>
      );
    }
  };

  return (
    <TableRow>
      {/* Left Column: Location / Floor / Entity Type */}
      <TableCell
        sx={{
          minWidth: isMobile ? 120 : "auto",
          verticalAlign: "top",
          padding: "15px"
        }}
      >
        <Typography variant="body1" fontWeight="bold" color="text.primary">
          {/* Top-level (building name?) */}
          {entry.entity?.entity_path?.[0]?.name ? (
            entry.entity.entity_path[0].name
          ) : (
            <>N / A</>
          )}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Floor {entry.entity?.name || ""} /{" "}
          {entry.entity_type.name ? entry.entity_type.name : ""}
        </Typography>
      </TableCell>

      {/* Middle Column: Service Items */}
      <TableCell sx={{padding: '10px'}}>{renderServiceItems()}</TableCell>

      {/* Delete Icon (Only in edit mode) */}
      {onDelete && isEditing && (
        <TableCell sx={{ width: isMobile ? 50 : 70, verticalAlign: "top" }}>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              onDelete();
            }}
            color="error"
          >
            <i className="ki-duotone ki-trash fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
              <span className="path5"></span>
            </i>
          </IconButton>
        </TableCell>
      )}
    </TableRow>

  );
};

export default WhereAndWhatRow