// casesStore.ts
// casesStore.ts
import { proxy } from 'valtio';
import { Case } from '../../shared/models/case.model';
import { sageStore } from "../sage-virtual-assistant/sageStore";
import { InspectionService } from "../../shared/services/inspections/inspectionService";
import { CasesService } from "../../shared/services/cases/casesService";

// Define your interfaces
interface Field {
  type: string;
  name: string;
}

interface Section {
  name: string;
  sage_prompt: string;
  fields: Field[];
}

interface CasesStore {
  // State properties
  sageResponse: string;
  initialSteps: number;
  endingSteps: number;
  caseToEdit: Case;
  open: boolean;
  deleteOpen: boolean;
  isEditing: boolean;
  activeSection: number;
  sections: Section[];

  // Handler methods
  handleNewCase: () => void;
  handleViewCase: (item: Case, editing?: boolean) => void;
  handleCloseCaseModal: () => void;
  handleDeleteClicked: () => void;
  handleDeleteCase: (id: number) => Promise<void>;
  reset: () => void;
}

// Initialize the Valtio store
const casesStore = proxy<CasesStore>({
  // Initial state
  sageResponse: '',
  initialSteps: 4,
  endingSteps: 2,
  caseToEdit: new Case(),
  open: false,
  deleteOpen: false,
  isEditing: false,
  activeSection: 0,
  sections: [],

  // Handler methods defined as arrow functions
  handleNewCase: () => {
    casesStore.caseToEdit = new Case();
    casesStore.isEditing = true;
    casesStore.open = true;
  },

  handleViewCase: (item: Case, editing: boolean = false) => {
    casesStore.caseToEdit = item;
    casesStore.isEditing = editing;
    casesStore.open = true;
  },

  handleDeleteClicked: () => {
    casesStore.deleteOpen = true;
  },

  handleDeleteCase: async (id: number) => {
    try {
      const service = new CasesService();
      await service.deleteCase(id);
    } catch (error) {
      console.error(error);
    } finally {
      casesStore.caseToEdit = new Case();
      casesStore.deleteOpen = false;
      casesStore.open = false;
    }
  },

  handleCloseCaseModal: () => {
    casesStore.isEditing = false;
    casesStore.open = false;
    casesStore.deleteOpen = false;
    casesStore.caseToEdit = new Case();
  },

  reset: () => {
    casesStore.sageResponse = '';
    casesStore.caseToEdit = new Case();
    sageStore.isTyping = true;
    casesStore.activeSection = 0;
    casesStore.sections = [];
    casesStore.isEditing = false;
    casesStore.open = false;
    casesStore.deleteOpen = false;
  },
});

export default casesStore;


