import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  AppBar,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import PhotoUploaderViewer from "../../../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import { Media } from "../../../../../../shared/models/media.model";
import InfoIcon from '@mui/icons-material/Info';
import ClosingComment from "./ClosingComment";
import { Comment } from "../../../../../../shared/models/comment.model";
import useAppStore from "../../../../../../appStore";
import { CommentsService } from "../../../../../../shared/services/comments/commentsService";
import { WorkItemsService } from "../../../../../../shared/services/work-items/workItemsService";
import { useSnapshot } from "valtio";
import { workItemStore } from "../../../../../work-items/workItemStore";

const CloseWorkItemModal = ({ open, onActionClicked }) => {

  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const snap = useSnapshot(workItemStore)
  const [closingComment, setClosingComment] = useState<string>('');
  const {toggleMasterRefresh} = useAppStore();
  const [media, setMedia] = useState<Media[]>(workItemStore.workItemToEdit.media);

  useEffect(() => {
    workItemStore.workItemToEdit.media = media;
  }, [media]);

  const handleClosingComment = async (commentText) => {
    const newComment = new Comment();
    newComment.comment_text = commentText;
    newComment.created_by = user.employee.person;
    newComment.organization = user?.organization;
      try {
        const service = new CommentsService();
        await service.postComment(snap.workItemToEdit.id, newComment, 'workitems')
        setClosingComment('');
      } catch (e) {
        console.error('Error in submitting closing comment:', e);
      }
  };

  const handleCompleteClicked = () => {
    if (closingComment !== '') {
      handleClosingComment(closingComment).then()
    }
    handleCloseWorkItem();
  }

  const handleCloseWorkItem = async () => {
    try {
      const service = new WorkItemsService();
      workItemStore.workItemToEdit.status = 'Closed';
      const response = await service.updateWorkItem(workItemStore.workItemToEdit);
      onActionClicked();
    } catch (e) {
      console.error('Error in closing:', e);
      console.log(`Failed to close work item: ${e instanceof Error ? e.message : JSON.stringify(e)}`);
    } finally {
      toggleMasterRefresh();
    }
  };

  const handleClose = () => {
    setClosingComment('');
    onActionClicked();
  }


  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onActionClicked} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Completed?
          </Typography>
        </Toolbar>
      </AppBar>
      <AppBar sx={{ position: 'relative', color: '#777', bgcolor: '#e9e9e9' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit">
            <InfoIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add closing comments/photos
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {snap.workItemToEdit && snap.workItemToEdit.id !== 0 &&
          <>
            <div className="mt-5">
              <PhotoUploaderViewer
                item={snap.workItemToEdit}
                setMedia={setMedia}
                closing={true}
                module={'workitems'}
              />
            </div>
            <Box sx={{ mt: 1}}>
              <ClosingComment
                closingComment={closingComment}
                setClosingComment={setClosingComment} />
            </Box>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCompleteClicked}>Close Work Item</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseWorkItemModal;