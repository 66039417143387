import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import useAppStore from "../../../appStore";
import { formatDateTime } from "../../../shared/utils/table-modifier-util/tableDataUtil";
import traceStore from "../traceStore";

const TraceRow = ({ trace }) => {
  const { whiteLabelProps } = useAppStore();

  const getRequestColor = (method) => {
    switch (method) {
      case "GET":
        return whiteLabelProps.primary_color;
      case "POST":
        return "#0E4C92";
      case "PUT":
        return "#FF9913";
      case "DELETE":
        return "red";
      default:
        return "black";
    }
  };

  // Subtle row tint if Failed
  const rowStyle = {
    cursor: "pointer",
    backgroundColor: trace.status === "Failed" ? "rgba(255, 0, 0, 0.05)" : "transparent",
  };

  return (
    <tr onClick={() => traceStore.selectTrace(trace)} style={rowStyle}>
      <td>
        <div className="d-flex flex-row align-items-center ms-2">
          <img
            src={toAbsoluteUrl("/white-labeling/verde/media/menu-icons/inspectorProfile.png")}
            style={{ width: "50px", height: "50px", marginRight: "12px" }}
          />
          <div className="d-flex flex-column align-items-left">
            <div>
              {trace.user.employee.person.first_name} {trace.user.employee.person.last_name}
            </div>
            <div className="text-muted">{formatDateTime(trace.create_date)}</div>
          </div>
        </div>
      </td>
      <td style={{ color: getRequestColor(trace.http_method) }}>
        {trace.http_method} {trace.url}
      </td>
      <td>{trace.status}</td>
      <td>{trace.duration}ms</td>
      <td className="text-end">
        <button className="btn btn-sm btn-light me-2">Show Details</button>
      </td>
    </tr>
  );
};

export default TraceRow;

