import { proxy } from 'valtio';
import { Employee } from "../../shared/models/employee.model";
import { GroupEntry } from "../../shared/models/groupEntry.model";

interface EmployeeStore {
  // employees: Employee[];
  employeeToEdit: Employee;
  editing: boolean;
  open: boolean;
  profileOpen: boolean;
  deleteOpen: boolean;
  mode: string;
  formError: string;
}

export const employeeStore = proxy({
  employeeToEdit: new Employee(),
  editing: false,
  open: false,
  profileOpen: false,
  deleteOpen: false,
  mode: 'basic',
  formError: '',

  setFirstName(value: string) {
    this.employeeToEdit.person.first_name = value;
  },
  setLastName(value: string) {
    this.employeeToEdit.person.last_name = value;
  },
  setPersonCountryCode(value: string) {
    this.employeeToEdit.user.country_code = value;
  },
  setEmployeeCountryCode(value: string) {
    this.employeeToEdit.country_code = value;
  },
  setPersonalEmail(value: string) {
    this.employeeToEdit.person.email = value;
  },
  setWorkEmail(value: string) {
    this.employeeToEdit.email = value;
  },
});


// export const fetchEmployees = async (): Promise<Employee[]> => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(dummyData); // Return dummy data after a delay
//     }, 500); // Simulate network delay
//   });
// };
