import React, { useEffect, useState, useRef } from "react";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import EditAreaInspectionPage from "./components/edit-inspection/EditAreaInspection";
import InspectionSummaryTiles from "./components/inspections-list/components/summary/InspectionSummaryTiles";
import { InspectionService } from "../../shared/services/inspections/inspectionService";
import useAppStore from "../../appStore";
import { InspectionSummary } from "../../shared/models/inspectionSummary.model";
import CustomLoadingDots from "../../shared/components/page/popup-dialog/CustomLoadingDots";
import InspectionsCardList from "./components/inspections-list/InspectionsCardList";
import LoadMoreButton from "../../shared/components/buttons/LoadMoreButton";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { inspectionsStore } from "./inspectionsStore";
import { useSnapshot } from "valtio";

const InspectionsPage = () => {
    const snap = useSnapshot(inspectionsStore);
    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const { selectedLocation, masterRefresh, toggleMasterRefresh } = useAppStore();
    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
    const service = new InspectionService();
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);
    const showMoreButtonRef = useRef<HTMLDivElement>(null);

    const fetchData = async () => {
        const offset = page * snap.limit;
        inspectionsStore.isLoading = true;
        if (page === 0) {inspectionsStore.inspections = []}
        try {
            const summaryData = await service.getInspectionSummary(user.organization.id, selectedLocation.id);
            const inspectionsData = await service.getInspections(
              user.organization.id, selectedLocation.id, offset, snap.limit, snap.includeRegular, snap.includeJoint, snap.includeBaseline);

            inspectionsStore.summary = summaryData;
            inspectionsStore.inspections = [...inspectionsStore.inspections, ...inspectionsData];;
            inspectionsStore.isLoading = false;

        } catch (error) {
            console.error(error);
            inspectionsStore.isLoading = false;
        }
    };

    useEffect(() => {
        if (selectedLocation.id !== 0) {
            fetchData().then();
        }
    }, [user, page, masterRefresh]);

    useEffect(() => {
        inspectionsStore.summary = new InspectionSummary();
        inspectionsStore.inspections = [];
        inspectionsStore.isLoading = true;
        setPage(0);

        if (selectedLocation.id !== 0) {
            fetchData().then();
        }

    }, [selectedLocation.id, snap.includeRegular, snap.includeJoint, snap.includeBaseline]);

    const handleNewInspection = () => {
        inspectionsStore.handleHardReset();
        inspectionsStore.isEditing = true;
        inspectionsStore.open = true;
    }

    return (
      <div>
            <PageHeader
              title={t('inspections')}
              actionButtonName={t('new-inspection')}
              onActionClicked={handleNewInspection}
            />

          {selectedLocation.id === 0 || (snap.isLoading && snap.inspections.length === 0) ? (
            <div style={{ minHeight: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CustomLoadingDots />
            </div>
          ) : (
            <>
                <div style={{ marginBottom: "120px", width: "100%" }} ref={showMoreButtonRef}>
                    <InspectionSummaryTiles />
                    <div className={'d-flex flex-row justify-content-between align-items-center mt-10 mb-5'}>
                        {!isMobile &&
                          <div>
                              <h3 style={{ margin: 0, color: "#444" }}>Recent Inspections</h3>
                          </div>
                        }
                        <Box display="flex" alignItems="center" gap={2}>
                            <FormControlLabel
                              control={
                                  <Switch
                                    checked={snap.includeRegular}
                                    onChange={() => inspectionsStore.includeRegular = !snap.includeRegular}
                                    color="primary"
                                  />
                              }
                              label={t("regular")}
                            />
                            <FormControlLabel
                              control={
                                  <Switch
                                    checked={snap.includeJoint}
                                    onChange={() => inspectionsStore.includeJoint = !snap.includeJoint}
                                    color="primary"
                                  />
                              }
                              label={t("joint")}
                            />
                            <FormControlLabel
                              control={
                                  <Switch
                                    checked={snap.includeBaseline}
                                    onChange={() => inspectionsStore.includeBaseline = !snap.includeBaseline}
                                    color="primary"
                                  />
                              }
                              label={t("baseline")}
                            />
                        </Box>
                    </div>
                    <InspectionsCardList />
                    {snap.inspections.length % 10 === 0 &&
                      <LoadMoreButton onClick={() => setPage(page + 1)} isLoading={snap.isLoading} />
                    }
                </div>
                {user.employee.id &&
                  <EditAreaInspectionPage />
                }
            </>
          )}
      </div>
    );
}

export default InspectionsPage;



