import React from "react";
import { IconButton, Button, AppBar, DialogContent, useMediaQuery, useTheme } from "@mui/material";
import { useSnapshot } from "valtio";
import { suggestionsStore } from "../suggestionsStore";
import CloseIcon from "@mui/icons-material/Close";
import SuggestedWorkItemsList from "./module-based-content/SuggestedWorkItemsList";
import SuggestedCasesList from "./module-based-content/SuggestedCasesList";
import SuggestedInspectionsList from "./module-based-content/SuggestedInspectionsList";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import InfoPrompter from "./InfoPrompter";


const SuggestionActionMenu = () => {
  const suggestionSnap = useSnapshot(suggestionsStore);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  return (
    <Dialog
      open={suggestionSnap.open}
      onClose={() => suggestionsStore.open = false}
      fullWidth={!isMobile} fullScreen={isMobile}
      maxWidth={isMobile ? undefined : "sm"}
      PaperProps={{
        style: {
          overflowX: 'hidden',
          overflowY: 'auto',
        },
      }}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => suggestionsStore.open = false} aria-label="close">
            <CloseIcon />
          </IconButton>
          <div style={{ flexGrow: 1 }}></div>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div style={{ overflowY: "auto", flex: 1 }}>
            <InfoPrompter prompt={suggestionSnap.selectedSuggestion.description} />

          {suggestionSnap.selectedSuggestion.module === 'Work Items' &&
            <SuggestedWorkItemsList />
          }

          {suggestionSnap.selectedSuggestion.module === 'Cases' &&
            <SuggestedCasesList />
          }

          {suggestionSnap.selectedSuggestion.module === 'Inspections' &&
            <SuggestedInspectionsList />
          }

          {/*{suggestionSnap.selectedSuggestion.module === 'Analytics' &&*/}
          {/*  <Button variant={'contained'} sx={{fontSize: '1.3rem', width: '100%', height: '60px'}}>TAKE ME THERE</Button>*/}
          {/*}*/}


        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SuggestionActionMenu;

