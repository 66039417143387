import {FC} from 'react'
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { Employee } from "../../../models/employee.model";
import { formatPhoneNumber } from "../../../utils/data-transform/dataUtil";
import useAppStore from "../../../../appStore";
import { employeeStore } from "../../../../modules/employees/employeeStore";
import { EmployeeService } from "../../../services/employee/employeeService";

type Props = {
  color?: string
  employee: Employee
}

const EmployeeMiniCard: FC<Props> = ({
                            color = '',
                            employee,
                          }) => {

  const {appImages} = useAppStore();
  const employeeService = new EmployeeService();

  const handleViewEmployee = async () => {
    if (!employee.id) return;

    try {
      employeeStore.editing = false;
      employeeStore.employeeToEdit = await employeeService.getEmployee(employee.id);
      employeeStore.mode = 'basic';
    } catch (error) {
      console.error('Failed to fetch employee:', error);
    } finally {
      employeeStore.open = true;
    }
  };

  return (
    <div className='card' style={{width: '150px', height: '145px', cursor: 'pointer'}} onClick={handleViewEmployee}>
      <div className='card-body d-flex flex-center flex-column p-4'>
        <div className='mb-2'>
          <div className='symbol symbol-75px symbol-circle'>
            {color ? (
              <span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>
                {employee?.person?.first_name.charAt(0)}
              </span>
            ) : (
              <img alt='Pic' src={toAbsoluteUrl(appImages.iconBlankProfilePic)}
                   style={{ width: '50px', height: '50px' }} />
            )}
          </div>
        </div>

        <a
          className='text-gray-800 fw-bolder mb-0'
          style={{
            fontSize: '1rem',
            maxWidth: '140px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'center',
          }}
        >
          {employee?.person?.first_name} {employee?.person?.last_name}
        </a>

        {Array.isArray(employee?.works_at) && employee.works_at.length > 0 && (
          <div
            className='fw-bold text-primary'
            style={{
              fontSize: '0.9rem',
              maxWidth: '140px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'center',
            }}
          >
            {employee.works_at[0]?.location?.name}
          </div>
        )}
        <div
          className='fw-bold text-gray-400 mb-1'
          style={{ fontSize: '0.9rem' }}
        >
          {formatPhoneNumber(employee?.person?.phone_number)}
        </div>
      </div>
    </div>
  )
}

export default EmployeeMiniCard;
