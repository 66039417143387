import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { CaseSerializer } from "../../../models/serializers/caseSerializer";
import { ErrorSerializer } from "../../../models/serializers/errorSerializer";


export class CommandCreateCase {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/cases/`;
  }

  async run(item) {

    const url = this.getUrl()

    const tracer = useTracer.getState()
    tracer.trace('Cases', 'Api', `POST ${url}`)

    try {

      let data = CaseSerializer.serialize(item);
      const response = await httpClient.post(url, data);

      return this.deserialize(response.data);
    } catch (error) {
      const errorSerializer = new ErrorSerializer();
      const serializedError = errorSerializer.deserialize(error);
      tracer.trace("Cases", "Error", `${serializedError.message}`);
      console.error("Error:", serializedError);
      throw serializedError;
    }finally {
      tracer.trace('Cases', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new CaseSerializer()
    return serializer.deserialize(data)
  }

}