import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useAppStore from "../../../../../app/appStore";
import { getUserByToken } from "../../../../../app/modules/auth/core/_requests";
import { UserSerializer } from "../../../../../app/shared/models/serializers/userSerializer";
import { useState } from "react";
import { AuthModel } from "../../../../../app/modules/auth";
import * as authHelper from "../../../../../app/modules/auth/core/AuthHelpers";

export function ShadowUserBar() {

  const { appImages, shadowUser, setShadowUser, loggedInUser } = useAppStore();
  const [auth] = useState<AuthModel | undefined>(authHelper.getAuth());

  const handleStopShadow = async () => {
    localStorage.removeItem("shadowUser");
    setShadowUser(null);
    if (loggedInUser.id) {
      try {
        const { data } = await getUserByToken(auth?.token || "", loggedInUser.id.toString());
        if (data) {
          window.location.reload();
        }
      } catch (error) {
        console.error("Failed to return to logged in user data:", error);
      }
    }
  };

  if (!shadowUser) {
    return null;
  }

  return (
    <Box
      sx={{
        padding: '15px',
        backgroundColor: 'gray',
        color: 'white',
        width: '100%',
        flexBasis: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {/* Main Content */}
      <Typography variant="body1">
        Shadowing {shadowUser.first_name} {shadowUser.last_name}
      </Typography>

      {/* Button with Icon Shadow */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          component="img"
          src={appImages.iconShadow}
          alt="Shadow Icon"
          sx={{
            width: '35px',
            height: '35px',
            opacity: 0.4,
            filter: 'blur(0.5px)',
            marginRight: '8px',
          }}
        />
        <Button variant="text" color="inherit" size="small" onClick={handleStopShadow}>
          Stop Shadow
        </Button>
      </Box>
    </Box>
  );
}

export default ShadowUserBar;

