import React from "react";
import { Card, Col, Row, Form, ButtonGroup } from "react-bootstrap";
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import useAppStore from "../../../../../../appStore";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import ModalFooterSubmitProgress from "../../../../../../shared/components/buttons/ModalFooterSubmitProgress";
import ModalFooterActions from "../../../../../../shared/components/buttons/ModalFooterActions";

import { useSnapshot } from 'valtio';
import { formActions, formState } from "../../formStore";

const EditForm = (props) => {
  const { formToEdit, setFormToEdit, open, onClose, onSave } = props;
  const snap = useSnapshot(formState);
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const admin = user.permissions.is_super_user;
  const isMobile = useMediaQuery(useTheme().breakpoints.only("xs"));

  const addSection = () => {
    const newSection = {
      id: Date.now(),
      name: "",
      sage_prompt: "",
      fields: [],
    };
    setFormToEdit({
      ...formToEdit,
      form: {
        ...formToEdit.form,
        sections: [...formToEdit.form.sections, newSection],
      },
    });
  };

  const removeSection = (sectionId) => {
    setFormToEdit({
      ...formToEdit,
      form: {
        ...formToEdit.form,
        sections: formToEdit.form.sections.filter((section) => section.id !== sectionId),
      },
    });
  };

  const addField = (sectionId) => {
    const newField = {
      id: Date.now(),
      name: "",
      type: "Text",
      data_source: null,
    };
    setFormToEdit({
      ...formToEdit,
      form: {
        ...formToEdit.form,
        sections: formToEdit.form.sections.map((section) =>
          section.id === sectionId
            ? { ...section, fields: [...section.fields, newField] }
            : section
        ),
      },
    });
  };

  const removeField = (sectionId, fieldId) => {
    setFormToEdit({
      ...formToEdit,
      form: {
        ...formToEdit.form,
        sections: formToEdit.form.sections.map((section) =>
          section.id === sectionId
            ? { ...section, fields: section.fields.filter((field) => field.id !== fieldId) }
            : section
        ),
      },
    });
  };

  const handleChange = (e, sectionId, fieldId = null, key) => {
    const value = e.target.value;
    setFormToEdit({
      ...formToEdit,
      form: {
        ...formToEdit.form,
        sections: formToEdit.form.sections.map((section) =>
          section.id === sectionId
            ? {
              ...section,
              [fieldId
                ? "fields"
                : "name"]: fieldId
                ? section.fields.map((field) =>
                  field.id === fieldId ? { ...field, [key]: value } : field
                )
                : value,
            }
            : section
        ),
      },
    });
  };

  const handleSave = async () => {
    formActions.setIsLoading(true);
    try {
      await onSave(formToEdit);
    } catch (e) {
      console.log('error');
    } finally {
      formActions.setIsLoading(false);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={ModalTransition}
      PaperProps={{ style: { overflowX: "hidden", backgroundColor: "#f9f9f9" } }}
    >
      <AppBar sx={{ position: "fixed", top: 0, left: 0, right: 0 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, color: 'white' }}>
            {formToEdit?.id ? "Edit Form" : "Create Form"}
          </Typography>
        </Toolbar>
      </AppBar>

      <Row className="mt-20 mb-20">
        <Col xs={12}>
          <Card style={{ boxShadow: "0 1px 2px rgba(0,0,0,0.1)", margin: "5px" }}>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Form Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formToEdit?.form?.name || ""}
                    onChange={(e) => setFormToEdit({ ...formToEdit, form: { ...formToEdit.form, name: e.target.value } })}
                  />
                </Form.Group>

                <Form.Label>Sections</Form.Label>
                {formToEdit?.form?.sections.map((section) => (
                  <Card key={section.id} className="mb-3">
                    <Card.Body>
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Section Name"
                          value={section.name}
                          onChange={(e) => handleChange(e, section.id, null, "name")}
                        />
                      </Form.Group>
                      <ButtonGroup>
                        <Button size="small" startIcon={<AddIcon />} onClick={() => addField(section.id)}>
                          Add Field
                        </Button>
                        <Button size="small" color="error" startIcon={<DeleteIcon />} onClick={() => removeSection(section.id)}>
                          Delete Section
                        </Button>
                      </ButtonGroup>

                      {section.fields.map((field) => (
                        <Row key={field.id} className="mt-2 ms-5">
                          <Col xs={8}>
                            <Form.Control
                              type="text"
                              placeholder="Field Name"
                              value={field.name}
                              onChange={(e) => handleChange(e, section.id, field.id, "name")}
                            />
                          </Col>
                          <Col xs={3}>
                            <Form.Select
                              value={field.type}
                              onChange={(e) => handleChange(e, section.id, field.id, "type")}
                            >
                              <option value="Text">Text</option>
                              <option value="Select">Select</option>
                              <option value="Textarea">Textarea</option>
                              <option value="Date">Date</option>
                              <option value="Number">Number</option>
                            </Form.Select>
                          </Col>
                          <Col xs={1}>
                            <Button
                              size="small"
                              color="error"
                              onClick={() => removeField(section.id, field.id)}
                            >
                              <DeleteIcon fontSize="small" />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </Card.Body>
                  </Card>
                ))}
                <Button variant="outlined" startIcon={<AddIcon />} onClick={addSection}>
                  Add Section
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {snap.error && <CustomError error={snap.error} />}
      {snap.isLoading ? <ModalFooterSubmitProgress /> : <ModalFooterActions onSave={handleSave} onClose={onClose} />}
    </Dialog>
  );
};

export default EditForm;