import { CommandGetRelationships } from "./commands/cmdGetRelationships";
import { CommandCreateRelationship } from "./commands/cmdCreateRelationship";
import { CommandUpdateRelationship } from "./commands/cmdUpdateRelationship";
import { CommandCreateActionItem } from "./commands/cmdCreateActionItem";
import { CommandCreateSalesContact } from "./commands/cmdCreateSalesContact";
import { CommandGetSalesContacts } from "./commands/cmdGetSalesContacts";
import { CommandUpdateActionItem } from "./commands/cmdUpdateActionItem";
import { CommandUpdateSalesContact } from "./commands/cmdUpdateSalesContact";
import { CommandGetRelationshipReps } from "./commands/cmdGetRelationshipReps";
import { Employee } from "../../models/employee.model";


export class RelationshipService {

  async createRelationship(relationship) {
    const cmd = new CommandCreateRelationship()
    const result = cmd.run(relationship)
    return result
  }

  async createActionPlanItem(relationship_id, actionItem) {
    const cmd = new CommandCreateActionItem()
    const result = cmd.run(relationship_id, actionItem)
    return result
  }

  async updateActionPlanItem(relationship_id, actionItem) {
    const cmd = new CommandUpdateActionItem()
    const result = cmd.run(relationship_id, actionItem)
    return result
  }

  async createRelationshipContact(contact) {
    const cmd = new CommandCreateSalesContact()
    const result = cmd.run(contact)
    return result
  }

  async updateRelationshipContact(contact) {
    const cmd = new CommandUpdateSalesContact()
    const result = cmd.run(contact)
    return result
  }

  async getRelationshipContact(provider_id) {
    const cmd = new CommandGetSalesContacts()
    const result = cmd.run(provider_id)
    return result
  }

  async getRelationshipReps(provider_id) {
    const cmd = new CommandGetRelationshipReps()
    const result = cmd.run(provider_id)
    return result
  }

  async updateRelationship(relationship) {
    const cmd = new CommandUpdateRelationship()
    const result = cmd.run(relationship)
    return result
  }

  async getRelationships(provider_id, filter?: number | undefined) {
    const cmd = new CommandGetRelationships()
    const results = cmd.run(provider_id, filter)
    return results
  }


}