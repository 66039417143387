import React, { useState } from "react";
import { inspectionButtonContainerStyle } from "../../utils/table-modifier-util/tableDataUtil";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const ModalFooterActions = ({ onClose, onSave, text='save' }) => {

  const {t} = useTranslation();

  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    if (isSaving) return;
    setIsSaving(true);
    try {
      await onSave();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div style={inspectionButtonContainerStyle(true)}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%"
        }}
      >
        <Button
          onClick={onClose}
          style={{ fontSize: "1.2rem" }}
        >
          {t('close')}
        </Button>
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Button
            onClick={handleSave}
            style={{ fontSize: "1.2rem" }}
            disabled={isSaving}
          >
            {t(text)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalFooterActions;

