import Card from "@mui/material/Card";
import { Box, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { inspectionsStore } from "../../../../inspectionsStore";

const InspectedByCard = () => {

  const {t} = useTranslation();
  const snap = useSnapshot(inspectionsStore);

  return (
    <Card
      variant="outlined"
      sx={{
        boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
        height: 120,
        position: "relative",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
            alignItems: "center",
          }}
        >
          {/* Left Column */}
          <Box sx={{ width: "65%" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={toAbsoluteUrl(
                  "/white-labeling/verde/media/menu-icons/inspectorProfile.png"
                )}
                style={{ width: "65px", height: "65px", marginRight: "10px" }}
              />
              <Box>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#555" }}
                >
                  {snap.inspectionToEdit?.inspected_by?.first_name}{" "}
                  {snap.inspectionToEdit?.inspected_by?.last_name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('joint-inspected-by')}:{" "}
                  {(snap.inspectionToEdit && snap.inspectionToEdit.is_joint_inspection)
                    ? (snap.inspectionToEdit?.joint_inspected_by?.person.first_name + " " + snap.inspectionToEdit?.joint_inspected_by?.person.last_name)
                    : "N/A"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {t('baseline')}:{" "}
                  {(snap.inspectionToEdit && snap.inspectionToEdit.is_baseline)
                    ? `${t('yes')}`
                    : `${t('no')}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InspectedByCard;
