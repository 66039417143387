import React from 'react';
import { AppBar, Dialog, IconButton, Typography, Toolbar, Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ModalTransition from "../../../../../../../../../../shared/components/page/transitions/ModalTransition";
import { getTextForPosition } from "../../../../../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import SageScoreSummary from "./SageScoreSummary";
import ErrorIcon from '@mui/icons-material/Error';
import SageScoreSlider
  from "../../../../../../../../../dashboard/site-manager-dashboard/components/sage-analytics/SageScoreSlider";
import InfoPrompter from "../../../../../../../../../dashboard/components/suggestions/components/InfoPrompter";


const SageScoreModal = ({ open, healthData, failingCount, details, onActionClicked }) => {

  const sageScore = 100 - healthData.health_score
  const {text, message} = getTextForPosition(sageScore)
  const prompt = `Your location (${healthData?.location_name}) has a sage score of ${sageScore}/100. ` + message
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  return (
    <Dialog
      open={open} onClose={() => onActionClicked('close')}
      TransitionComponent={ModalTransition}
      fullWidth={!isMobile} fullScreen={isMobile}
      maxWidth={isMobile ? undefined : "sm"}
      PaperProps={{
        style: {
          overflowX: 'hidden',
          overflowY: 'auto',
        },
      }}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <AppBar sx={{ position: 'relative', color: '#777', bgcolor: '#e9e9e9' }}>
        <Toolbar>
          <div className="d-flex flex-column">
            <Typography variant="subtitle1">Compliance Issue Count: {healthData?.compliance_count}</Typography>
            <Typography variant="subtitle1">Quality Issue Count: {healthData?.quality_count}</Typography>
          </div>
        </Toolbar>
      </AppBar>

      <SageScoreSlider score={healthData.health_score} hideText={true} />

      <InfoPrompter prompt={prompt} />

      <div className="ps-10 pe-10" style={{marginBottom: '50px'}}>
        <SageScoreSummary failingCount={failingCount} details={details}/>
      </div>


    </Dialog>
  );
};

export default SageScoreModal;
