import { AppDate } from "../utils/date-time/AppDate";
import { User } from "./user.model";

export class Trace {
    id: number;
    module: string;
    create_date: AppDate;
    status: string;
    feature: string;
    action: string;
    http_method: string;
    url: string;
    duration: number;
    user: User;
    condition: string;
    message: string;
    query_params: any[];
    messages: string[];

    // Add these new fields:
    request: any;
    response: any;

    constructor() {
        this.id = 0;
        this.module = '';
        this.create_date = new AppDate().dateTime;
        this.status = '';
        this.feature = '';
        this.action = '';
        this.http_method = '';
        this.url = '';
        this.duration = 0;
        this.user = new User();
        this.condition = 'Trace';
        this.message = '';
        this.query_params = [];
        this.messages = [];

        // Initialize new properties to empty objects
        this.request = {};
        this.response = {};
    }
}




