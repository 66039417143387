import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme, useMediaQuery
} from "@mui/material";
import useAppStore from "../../../../../../appStore";
import SinglePhotoUploaderViewer from "../../../../../../shared/components/media/media-viewers/photos/SinglePhotoUploaderViewer";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import { Work } from "../../../../../../shared/models/work.model";
import InputMask from "react-input-mask";
import countryCodes from "./country_codes";
import UserPermissionsPanel from "./permissions/UserPermissionsPanel";
import DepartmentPanel from "./departments/DepartmentPanel";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { employeeStore } from "../../../../employeeStore";



const EmployeeForm = (props) => {
  const {
    positions,
    error,
  } = props;

  const snap = useSnapshot(employeeStore)
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { selectedLocation } = useAppStore();
  const { t } = useTranslation();
  const uploadUrl = process.env.REACT_APP_API_FACILITIES_SERVICE_URL + '/media/upload/';
  const [employeeMedia, setEmployeeMedia] = useState(employeeStore.employeeToEdit.person.photo);
  const [localPersonalPhone, setLocalPersonalPhone] = useState(snap.employeeToEdit.person.phone_number ?? '');
  const [localWorkPhone, setLocalWorkPhone] = useState(snap.employeeToEdit.phone_number ?? '');
  const [fieldsLocked, setFieldsLocked] = useState(false);
  const isMobile = useMediaQuery(useTheme().breakpoints.only("xs"));



  useEffect(() => {
    employeeStore.employeeToEdit.person.photo = employeeMedia || null;
  }, [employeeMedia]);

  useEffect(() => {
    if (snap.employeeToEdit.works_at.length === 0) {
      employeeStore.employeeToEdit.setLocation(selectedLocation.id);
    }
  }, []);

  useEffect(() => {

    if (user.permissions?.is_super_user == true) {
      setFieldsLocked(false);
      return;
    }

    if (snap.employeeToEdit.employee_id != null && employeeStore.employeeToEdit.employee_id.length > 0){
      setFieldsLocked(true);
    }

  }, []);


  const handlePersonalPhoneSetting = () => {
    employeeStore.employeeToEdit.person.phone_number = localPersonalPhone.replace(/[\s()-]/g, '');
  };

  const handleWorkPhoneSetting = () => {
    employeeStore.employeeToEdit.phone_number = localWorkPhone.replace(/[\s()-]/g, '');
  };

  const handlePositionChange = (event) => {
    if (snap.employeeToEdit.works_at.length === 0) {
      employeeStore.employeeToEdit.works_at.push(new Work());
      employeeStore.employeeToEdit.works_at[0].position.id = event.target.value;
      employeeStore.employeeToEdit.works_at[0].location.id = event.target.value;
    } else {
      employeeStore.employeeToEdit.setPosition(event.target.value);
      employeeStore.employeeToEdit.setLocation(selectedLocation.id);
    }
  };

  const getPositionValue = () => {
    return snap.employeeToEdit.works_at.length > 0 ? snap.employeeToEdit.works_at[0].position.id : 0;
  };

  return (
    <>
      <div
        className="d-flex flex-column flex-column-fluid mt-5"
        style={{ height: 'calc(100vh - 100px)' }}
      >
        {snap.formError && <CustomError error={snap.formError} />}
        {error && <CustomError error={error} />}
        <div className="d-flex flex-row">
          <div
            className="profile-picture-container"
            style={{
              position: 'relative',
              width: isMobile ? '130px' : '170px',
              height: isMobile ? '130px' : '170px',
              marginTop: '12px',
              marginRight: '10px',
            }}
          >
            <img
              src={snap.employeeToEdit.person.photo_url || `/media/avatars/blank.png`}
              alt="Profile Picture"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '15%',
                objectFit: 'cover',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.src = '/media/avatars/blank.png';
              }}
            />
            {snap.editing &&
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  backgroundColor: "white",
                  opacity: "65%"
                }}
              >
                <SinglePhotoUploaderViewer
                  setMedia={setEmployeeMedia}
                  currentUser={user}
                  uploadUrl={uploadUrl}
                  setPhotoUrl={(value) => employeeStore.employeeToEdit.person.photo_url = value}
                />
              </div>
            }
          </div>

          <div className="d-flex flex-column flex-grow-1">
            <TextField
              label={t("first-name")}
              required
              name="First Name"
              value={snap.employeeToEdit.person.first_name}
              onChange={(event) => employeeStore.setFirstName(event.target.value)}
              fullWidth
              margin="normal"
              disabled={snap.employeeToEdit.employee_id !== '' || !snap.editing}
            />
            <TextField
              label={t("last-name")}
              required
              name="Last Name"
              value={snap.employeeToEdit.person.last_name}
              onChange={(event) => employeeStore.setLastName(event.target.value)}
              fullWidth
              margin="normal"
              disabled={snap.employeeToEdit.employee_id !== '' || !snap.editing}
            />
          </div>
        </div>
        <div
          className="d-flex flex-row align-items-end"
          style={{ marginBottom: '8px' }}
        >
          <FormControl
            style={{ width: '50%', maxWidth: '170px', marginRight: '1%' }}
            margin="normal"
          >
            <Select
              value={snap.employeeToEdit.user?.country_code || '1'}
              onChange={(event) => employeeStore.setPersonCountryCode(event.target.value)}
              disabled={snap.employeeToEdit.employee_id !== '' || !snap.editing}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              {countryCodes.map((country) => (
                <MenuItem key={country.code} value={country.code} disabled={fieldsLocked}>
                  {country.flag} (+{country.code})
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <InputMask
            mask="(999) 999-9999"
            value={localPersonalPhone}
            onChange={(e) => setLocalPersonalPhone(e.target.value)}
            onBlur={handlePersonalPhoneSetting}
            maskChar=" "
            disabled={snap.employeeToEdit.employee_id !== '' || !snap.editing}
          >
            {() => (
              <TextField
                label="Personal Phone"
                required
                name="Person Phone Number"
                fullWidth
                margin="normal"
                disabled={snap.employeeToEdit.employee_id !== '' || !snap.editing}
              />
            )}
          </InputMask>
        </div>

        <div
          className="d-flex flex-row align-items-end"
          style={{ marginBottom: '8px' }}
        >
          <FormControl
            style={{ width: '50%', maxWidth: '170px', marginRight: '1%' }}
            margin="normal"
          >
            <Select
              value={snap.employeeToEdit?.country_code || '1'}
              onChange={(event) => employeeStore.setEmployeeCountryCode(event.target.value)}
              disabled={snap.employeeToEdit.employee_id !== '' || !snap.editing}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              {countryCodes.map((country) => (
                <MenuItem key={country.code} value={country.code} disabled={fieldsLocked}>
                  {country.flag} (+{country.code})
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <InputMask
            mask="(999) 999-9999"
            value={localWorkPhone}
            onChange={(e) => setLocalWorkPhone(e.target.value)}
            onBlur={handleWorkPhoneSetting}
            maskChar=" "
            disabled={snap.employeeToEdit.employee_id !== '' || !snap.editing}
          >
            {() => (
              <TextField
                label={'Work Phone'}
                required
                name="Work Phone Number"
                fullWidth
                margin="normal"
                disabled={snap.employeeToEdit.employee_id !== '' || !snap.editing}
              />
            )}
          </InputMask>
        </div>

        <TextField
          label={'Personal Email'}
          name="Personal Email"
          value={snap.employeeToEdit.person.email}
          onChange={(event) => employeeStore.setPersonalEmail(event.target.value)}
          fullWidth
          margin="normal"
          disabled={snap.employeeToEdit.employee_id !== '' || !snap.editing}
        />

        <TextField
          label={'Work Email'}
          name="Work Email"
          value={snap.employeeToEdit.email}
          onChange={(event) => employeeStore.setWorkEmail(event.target.value)}
          fullWidth
          margin="normal"
          disabled={snap.employeeToEdit.employee_id !== '' || !snap.editing}
        />

        <FormControl fullWidth margin="normal" disabled={fieldsLocked}>
          <InputLabel id="position-label">{t('position')}</InputLabel>
          <Select
            labelId="position-label"
            required
            name={snap.employeeToEdit.id !== -1 ? 'works_at.position' : 'position_id'}
            value={getPositionValue()}
            onChange={handlePositionChange}
            label={t('position')}
            disabled={snap.employeeToEdit.employee_id !== '' || !snap.editing}
          >
            {positions.map((position) => (
              <MenuItem key={position.id} value={position.id}>
                {position.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {(snap.mode === 'profile' || snap.mode === 'quick') && (
          <DepartmentPanel disabled={!snap.editing}/>
        )}

        {/* USER PERMISSIONS PANEL */}
        {snap.mode !== 'profile' && snap.mode !== 'quick' && (
          isMobile ? (
            <div style={{ paddingBottom: '200px' }}>
              <DepartmentPanel disabled={fieldsLocked || !snap.editing} />
              <UserPermissionsPanel profile={employeeStore.employeeToEdit} disabled={!snap.editing} />
            </div>
          ) : (
            <div style={{ paddingBottom: '100px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DepartmentPanel disabled={!snap.editing} />
                </Grid>
                <Grid item xs={6}>
                  <UserPermissionsPanel profile={employeeStore.employeeToEdit} disabled={!snap.editing} />
                </Grid>
              </Grid>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default EmployeeForm;

