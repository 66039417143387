import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { ProjectSerializer } from "../../../models/serializers/projectSerializer";

export class CommandUpdateProject {
  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(project_id, plan_id) {
    return `${this.SERVICE}/project_plans/${plan_id}/projects/${project_id}/`;
  }

  async run(project, plan_id) {
    const url = this.getUrl(project.id, plan_id);
    const tracer = useTracer.getState();
    tracer.trace('Projects', 'Api', `PUT ${url}`);

    try {
      const data = ProjectSerializer.serialize(project);
      const response = await httpClient.put(url, data);
      tracer.trace('Projects', 'Api', 'Success');
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Projects', 'Error', `${error}`);
      console.error('Error:', error);
      throw error;
    }
  }

  deserialize(data) {
    const serializer = new ProjectSerializer();
    const item = serializer.deserialize(data);
    return item;
  }
}