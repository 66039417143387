import React, { useState } from "react";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import CasesList from './components/case-list/CasesList';
import { Box } from "@mui/material";
import EditCase from "./components/edit-case/EditCase";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import casesStore from "./caseStore";

const DepartmentCasesPage = () => {
    const {t} = useTranslation();
    const snap = useSnapshot(casesStore)

    return (
      <div>
          {!snap.isEditing && (
            <>
                <PageHeader
                  title={t('cases')}
                  actionButtonName={t('new-case')}
                  onActionClicked={casesStore.handleNewCase}
                />
                <Box mt={3} mb="120px">
                    <CasesList />
                </Box>
            </>
          )}
          {snap.open && snap.caseToEdit && (
            <EditCase open={snap.open} />
          )}
      </div>
    );
};

export default DepartmentCasesPage;