import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import WorkItemForm from "./components/details/WorkItemForm";
import CommentsSection from "../../../../shared/components/comments/CommentsSection";
import AssignedTo from "./components/assigned-to/AssignedTo";
import { AppBar, Button, Dialog, IconButton, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import { WorkItemsService } from "../../../../shared/services/work-items/workItemsService";
import useAppStore from "../../../../appStore";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { workItemStore } from "../../workItemStore";
import WhereAndWhatTable from "./components/where-and-what/WhereAndWhatTable";
import WorkItemReadOnly from "./components/details/WorkItemReadOnly";
import { WorkItem } from "../../../../shared/models/workItem.model";
import { WorkItemPrefillModel } from "../../../../shared/models/workItemPrefill.model";
import { InspectedEntity } from "../../../../shared/models/inspectedEntity.model";
import ModalFooterActions from "../../../../shared/components/buttons/ModalFooterActions";
import Box from "@mui/material/Box";
import WorkItemPrintCommand from "../print-work-items/components/WorkItemPrintCommand";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { ConfirmationDialog } from "../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import CloseWorkItemModal from "../../../dashboard/components/user-work-items/components/components/CloseWorkItemModal";


interface EditWorkItemProps {
    open: boolean;
    onActionClicked: (action: string) => void;
}

const EditWorkItem: React.FC<EditWorkItemProps> = ({ open, onActionClicked }) => {
    const { selectedLocation, toggleMasterRefresh } = useAppStore();
    const service = new WorkItemsService();
    const {t} = useTranslation();
    const snap = useSnapshot(workItemStore)
    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const printRef = useRef<{ handlePrint: () => Promise<void> }>(null);
    const [newComments, setNewComments] = useState([]);
    const [error, setError] = useState('');
    const [isEditing, setIsEditing] = useState(snap.workItemToEdit.id === 0);
    const [generatedPrefill, setGeneratedPrefill] = useState<WorkItemPrefillModel>(new WorkItemPrefillModel());

    const initializeWorkItem = () => {
        workItemStore.workItemToEdit.due_date = DateTime.now().toUTC().plus({ days: 1 }).toISO();
        workItemStore.workItemToEdit.created_by = user.employee;
        workItemStore.workItemToEdit.location.id = selectedLocation?.id || user?.employee?.works_at?.[0]?.location?.id || 0;
        workItemStore.workItemToEdit.provider.id = user.organization?.id || 0;
    };

    useEffect(() => {
        if (snap.workItemToEdit.title === "" && snap.workItemToEdit.description === "") {
            workItemStore.workItemToEdit.title = generatedPrefill?.work_item?.title || workItemStore.workItemToEdit.title;
            workItemStore.workItemToEdit.description = generatedPrefill?.work_item?.description || workItemStore.workItemToEdit.description;
        }
    }, [generatedPrefill]);

    const handleClosingModal = (action) => {
        workItemStore.closingDialogOpen = false;
        if (action === 'save') {
            workItemStore.snackbarOpen = true;
        }
    }


    useEffect(() => {
        if (!snap.workItemToEdit.id) {
            initializeWorkItem();
        } else {
            const fetchWorkItemDetails = async () => {
                try {
                    const service = new WorkItemsService();
                    workItemStore.workItemToEdit = await service.getWorkItemDetails(snap.workItemToEdit.id);
                } catch (error) {
                    console.error('Failed to fetch work item details:', error);
                }
            };
            fetchWorkItemDetails().then();
        }
    }, [snap.workItemToEdit.id]);

    const createPrefill = async (workItem) => {
        try {
            let source = new WorkItemPrefillModel();
            source.work_item = workItem;
            const response = await new WorkItemsService().createWorkItemPrefill(source);
            setGeneratedPrefill(response)
        } catch (e) {
            console.error('Error in prefill generation:', e);
        }
    };

    const handleDeleteConfirmed = async () => {
        if (!workItemStore.workItemToEdit) return;

        try {
            await service.deleteWorkItem(workItemStore.workItemToEdit.id);
            workItemStore.deleteOpen = false;
            workItemStore.open = false;
            toggleMasterRefresh();
        } catch (error) {
            console.error(error);
        }
    };

    const handleSave = async () => {
        try {
            const service = new WorkItemsService();
            const isCreating = snap.workItemToEdit.id === 0;
            const response = isCreating
              ? await service.createWorkItem(workItemStore.workItemToEdit)
              : await service.updateWorkItem(workItemStore.workItemToEdit);

            if (isCreating) workItemStore.sortBy = "most_recent";
            const statusToStepMap = { 'Pending': '0', 'Closed': '2' };
            localStorage.setItem("activeStep", statusToStepMap[response.status] || '1');

            await postComments(service, response.id, newComments);
            setNewComments([]);
            onActionClicked('close');
            toggleMasterRefresh();
        } catch (e) {
            console.error('Error in handleSave:', e);
            setError(`Failed to update work item`);
        }
    };

    const postComments = async (service, workItemId, comments) => {
        for (const comment of comments) {
            await service.postComment(workItemId, comment);
        }
    };

    const renderCard = (Component, props = {}) => (
      <Card style={{ boxShadow: '0 1px 2px rgba(0,0,0,0.1)', margin: '5px', marginBottom: '10px', borderRadius: '0px' }}>
          <Card.Body>
              <Component {...props} />
          </Card.Body>
      </Card>
    );

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={() => onActionClicked('close')}
        TransitionComponent={ModalTransition}
        PaperProps={{
            style: {
                overflowX: 'hidden',
                backgroundColor: '#e9e9e9',
                overflowY: 'auto',
            },
        }}
      >
          <AppBar sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
              <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
                      <CloseIcon />
                  </IconButton>
                  <Box sx={{ flexGrow: 1 }} />
                  {!isEditing ? (
                    <Button color="inherit" variant="contained" onClick={() => setIsEditing(true)} sx={{ ml: 'auto', color: '#444444' }}>
                        {t('edit')}
                    </Button>
                  ) : (
                    <>
                        {snap.workItemToEdit.id !== 0 &&
                          <Button color="inherit" variant="contained" onClick={() => workItemStore.handleOpenClosingModal(workItemStore.workItemToEdit)} sx={{ ml: 'auto', color: '#444444' }}>
                              Mark Completed
                          </Button>
                        }
                    </>
                  )}
                  {snap.workItemToEdit.id !== 0 &&
                    <Button color="inherit" variant="contained" onClick={workItemStore.handleDeleteOpenClose} sx={{ ml: '5px', color: '#444444' }}>
                        {t('delete')}
                    </Button>
                  }
              </Toolbar>
          </AppBar>

          <div id={"work-item-content"} style={{marginBottom: '100px'}}>

              <Row className="edit-work-item-page mt-20">
                  <div className="ps-10 pe-10">
                      {error && <CustomError error={error} />}
                  </div>
                  <Col xs={12} md={12}>
                      {renderCard(WhereAndWhatTable, { fromInspection: null, isEditing, createPrefill })}
                      {isEditing ? (
                        <>
                            {renderCard(WorkItemForm, { })}
                            {renderCard(AssignedTo, { isEditing })}
                            {snap.workItemToEdit.id !== 0 && renderCard(CommentsSection, { item: workItemStore.workItemToEdit, newComments, setNewComments, type: 'workitems' })}
                        </>
                      ) : (
                        <>
                            {renderCard(WorkItemReadOnly, { workItemToEdit: workItemStore.workItemToEdit })}
                            {renderCard(AssignedTo, { isEditing })}
                            {snap.workItemToEdit.id !== 0 && renderCard(CommentsSection, { item: workItemStore.workItemToEdit, newComments, setNewComments, type: 'workitems' })}
                        </>
                      )}
                  </Col>
              </Row>
          </div>

          {snap.deleteOpen && (
            <ConfirmationDialog
              isOpen={snap.deleteOpen}
              setIsOpen={workItemStore.handleDeleteOpenClose}
              message="Delete Work Item?"
              description="Are you sure you want to delete this work item? This action cannot be undone."
              onConfirmClicked={handleDeleteConfirmed}
            />
          )}
          {isEditing && (
            <ModalFooterActions onClose={() => onActionClicked('close')} onSave={handleSave} text={snap.workItemToEdit.id === 0 ? 'submit' : 'save'} />
          )}
          {snap.closingDialogOpen && snap.workItemToEdit &&
            <CloseWorkItemModal
              open={snap.closingDialogOpen}
              onActionClicked={handleClosingModal}
            />
          }
          <WorkItemPrintCommand ref={printRef} items={[workItemStore.workItemToEdit]} onClose={() => console.log('Printed!')} />

      </Dialog>
    );
};

export default EditWorkItem;

