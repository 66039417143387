import React, { useEffect, useState } from 'react';
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { inspectionsStore } from "../../../../inspectionsStore";
import { useSnapshot } from "valtio";

const InspectionSummary = () => {
  const { t } = useTranslation();
  const inspectionSnap = useSnapshot(inspectionsStore);

  const [spaceCount, setSpaceCount] = useState(0);
  const [score, setScore] = useState(0.0);

  const calculate = () => {
    const entities = inspectionSnap.inspectionToEdit?.inspected_entities || [];
    setSpaceCount(entities.length);

    const totalScore = entities.reduce((acc, entity) => {
      return acc + parseFloat(entity.score?.toString() || '0');
    }, 0);

    const avgScore = entities.length > 0 ? totalScore / entities.length : 0;
    setScore(avgScore);
  }

  useEffect(() => {
    calculate();
  }, [inspectionSnap.inspectionToEdit?.inspected_entities]);

  return (
    <div className="card-header d-flex justify-content-between align-items-center px-7" style={{ marginBottom: '20px' }}>
      <div className="d-flex align-items-center">
        <Typography variant="h6" className="fw-bold text-gray-700" style={{ marginRight: '20px' }}>
          {t('spaces')}:
          <span className="badge py-3 px-4 fs-5 justify-content-center">
            {spaceCount}
          </span>
        </Typography>

        <Typography variant="h6" className="fw-bold text-gray-700">
          {t('score')}:
          <span className="badge ms-1 py-3 px-4 fs-5 badge-light-success justify-content-center">
            {score.toFixed(2)}
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default InspectionSummary;

