import React from "react";
import useAppStore from "../../../../../../../appStore";

const WorkItemCreationConfirmation = (props) => {
  const { appImages } = useAppStore();
  const { workItemStatus } = props;
  const success = workItemStatus === 1;

  return (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: "100px", padding: "65px" }}
        >
          <img
            src={!success ? appImages.iconItemCancelled : appImages.iconWorkDone}
            style={{ width: 120, height: !success ? 150 : 120 }}
          />
          <h3 style={{ textAlign: "center", width: "100%" }}>
            {success
              ? "Work Item Created. Your inspection has been saved."
              : "Work Item Cancelled. Your inspection has been saved."}
          </h3>
        </div>
  );
};

export default WorkItemCreationConfirmation;