import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Slider } from "@mui/material";
import { projectStore } from "../../../../projectsStore";
import { useSnapshot } from "valtio";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchableTreeView
  from "../../../../../../shared/components/forms/widgets/searchable-tree-view/SearchableTreeView";

const ProjectForm = ({parent, setParent}) => {
  const snap = useSnapshot(projectStore);

  useEffect(() => {
    setParent(projectStore.selectedProject?.parent || undefined);
  }, [snap.selectedProject]);

  const handleParentChange = (project) => {
    setParent(project);
  };

  const handleNameChange = (event) => {
    if (projectStore.selectedProject) {
      projectStore.selectedProject.name = event.target.value;
    }
  };

  const handleDescChange = (event) => {
    if (projectStore.selectedProject) {
      projectStore.selectedProject.description = event.target.value;
    }
  };

  const handleMetricChange = (metricId, newValue) => {
    if (projectStore.selectedProject && projectStore.selectedProject.metrics) {
      const metricIndex = projectStore.selectedProject.metrics.findIndex(m => m.metric_id === metricId);
      if (metricIndex !== -1) {
        projectStore.selectedProject.metrics[metricIndex].metric_score = newValue;
      }
    }
  };

  const impactMetrics = snap.selectedProject?.metrics?.filter(m => m.category_name === "Impact") || [];
  const difficultyMetrics = snap.selectedProject?.metrics?.filter(m => m.category_name === "Difficulty") || [];

  return (
    <Box sx={{ padding: 2 }}>


      <span className="text-gray-700">Project Name</span>
      <TextField
        autoFocus
        fullWidth
        placeholder="Add a name..."
        variant="outlined"
        sx={{ marginBottom: 1 }}
        value={snap.selectedProject?.name || ""}
        onChange={handleNameChange}
      />
      <span className="text-gray-700">Description</span>
      <TextField
        placeholder="Add a description..."
        value={snap.selectedProject?.description || ""}
        onChange={handleDescChange}
        fullWidth
        multiline
        rows={6}
        sx={{ marginBottom: 2 }}
      />
      <span className="text-gray-700">Parent</span>
      <SearchableTreeView
        key={parent?.id || 'no-parent'}
        data={projectStore.projects}
        onItemSelected={handleParentChange}
        itemToEdit={parent}
        text={""}
        showNone={false}
      />

      {/* Metrics Section */}
      <Box sx={{ display: "flex", gap: 4, mt: 2 }}>

        <div style={{ flex: 1 }}>
          <span className="text-gray-700">Impact</span>
          {/* Impact Column */}
          <Box
            sx={{
              flex: 1,
              border: "1px solid #ccc",
              borderRadius: 1,
              p: 3
            }}
          >
            {impactMetrics.length === 0 ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <InfoOutlinedIcon color="action" />
                <Typography variant="body2" color="textSecondary">
                  No metrics yet. Metrics can be edited after project creation.
                </Typography>
              </Box>
            ) : (
              impactMetrics.map((metric) => (
                <Box key={metric.metric_id} sx={{ marginBottom: 2 }}>
                  <Typography variant="subtitle1" sx={{ marginBottom: "40px" }}>{metric.metric_name}</Typography>
                  <StyledSlider
                    value={metric.metric_score}
                    onChange={(event, newValue) => handleMetricChange(metric.metric_id, newValue)}
                    step={1}
                    min={0}
                    max={5}
                    valueLabelDisplay="on"
                  />
                </Box>
              ))
            )}
          </Box>
        </div>


        {/* Difficulty Column */}
        <div style={{ flex: 1 }}>
          <span className="text-gray-700">Difficulty</span>
          <Box
            sx={{
              flex: 1,
              border: "1px solid #ccc",
              borderRadius: 1,
              p: 3
            }}
          >
            {difficultyMetrics.length === 0 ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <InfoOutlinedIcon color="action" />
                <Typography variant="body2" color="textSecondary">
                  No metrics yet. Metrics can be edited after project creation.
                </Typography>
              </Box>
            ) : (
              difficultyMetrics.map((metric) => (
                <Box key={metric.metric_id} sx={{ marginBottom: 2 }}>
                  <Typography variant="subtitle1" sx={{ marginBottom: "40px" }}>{metric.metric_name}</Typography>
                  <StyledSlider
                    value={metric.metric_score}
                    onChange={(event, newValue) => handleMetricChange(metric.metric_id, newValue)}
                    step={1}
                    min={0}
                    max={10}
                    valueLabelDisplay="on"
                  />
                </Box>
              ))
            )}
          </Box>
        </div>

      </Box>
    </Box>
  );
};

const StyledSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-thumb": {
    width: '28px',
    height: '28px',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
  },
}));


export default ProjectForm;


