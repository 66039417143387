import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { ProjectPlanSerializer } from "../../../models/serializers/projectPlanSerializer";


export class CommandGetPlans {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {

    return  `${this.SERVICE}/project_plans/`;
  }

  async run(organization, location) {

    const url = this.getUrl()

    const params = {
      params: {
        provider: organization.id,
        location: location.id,
      }
    }
    try {
      const response = await httpClient.get(url, params);
      return this.deserializePosts(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserializePosts(data) {

    const serializer = new ProjectPlanSerializer()
    const plans = serializer.deserializeList(data)
    return plans
  }

}