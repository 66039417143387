import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

const ReusableTable = ({
                         items,
                         headers,
                         RowComponent,
                         CardComponent,
                         tableClassName = '',
                         tableView = true,
                       }) => {

  const cardView = useMediaQuery(useTheme().breakpoints.only('xs')) || !tableView;

  const renderItems = (items) => (
    cardView ? (
      <div style={{ width: '100%'}}>
        {items.map((item, index) => (
          <CardComponent key={index} item={item} />
        ))}
      </div>
    ) : (
      <>
        {items.map((item, index) => (
          <RowComponent key={index} item={item} />
        ))}
      </>
    )
  );

  return (
    <>
      {!cardView ? (
        <div className={`card m-0 pt-4 ${tableClassName}`} style={{ border: '2px solid #e8e8e8' }}>
          <div className="table-responsive">
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
              <thead>
              <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className={`p-0 pt-2 pb-3 ${header.className || ''}`}
                    style={header.style || {}}
                  >
                    {header.label}
                  </th>
                ))}
              </tr>
              </thead>
              <tbody>
              {renderItems(items)}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>
          {renderItems(items)}
        </>
      )}
    </>
  );
};

export default ReusableTable;
