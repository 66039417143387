import { proxy } from "valtio";
import { Entity } from "../../shared/models/entity.model";
import { EntityService } from "../../shared/services/entities/entityService";
import { EntityTypeService } from "../../shared/services/entity-types/entityTypeService";
import { ServicePlanService } from "../../shared/services/service-plans/servicePlanService";
import { InspectionServiceItemScore } from "../../shared/models/inspectionServiceItemScore.model";
import { InspectedEntity } from "../../shared/models/inspectedEntity.model";
import { inspectionsStore } from "./inspectionsStore";
import { workItemStore } from "../work-items/workItemStore";

interface InspectedEntityStore {
  inspectedEntity: InspectedEntity;
  selectedBuilding: Entity | null;
  selectedFloor: Entity | null;
  editing: boolean;
  creating: boolean;
  buildings: Entity[];
  floors: Entity[];
  types: Entity[];
  serviceItemScores: InspectionServiceItemScore[];
  buildingsLoading: boolean;
  floorsLoading: boolean;
  typesLoading: boolean;
  serviceItemsLoading: boolean;
  activeStep: number;
  prevDisabled: boolean;
  nextDisabled: boolean;
  submitDisabled: boolean;
  isValidating: boolean;
  fetchError: string;
  submissionError: string;


  handleFetchBuildings: (location_id) => Promise<void>;
  handleFetchFloors: (location_id, building_id) => Promise<void>;
  handleFetchTypes: (location_id, floor_id) => Promise<void>;
  handleFetchServiceItems: () => Promise<void>;
  handleResetModal: () => void;
  handleResetInspectedEntityDetails: () => void;
  handleInspectClicked: () => void;
  handleEditInspectedEntity: (entity: InspectedEntity) => void;
  handleUpdateInspectedEntity: (entity: InspectedEntity) => void;
  handleGoToStep: (step: number) => void;
  handleDisableButtons: (step: number) => void;


}

export const inspectedEntityStore = proxy<InspectedEntityStore>({
  inspectedEntity: new InspectedEntity(),
  selectedBuilding: null,
  selectedFloor: null,
  editing: false,
  creating: false,
  buildings: [],
  floors: [],
  types: [],
  serviceItemScores: [],
  buildingsLoading: false,
  floorsLoading: false,
  typesLoading: false,
  serviceItemsLoading: false,
  activeStep: 0,
  prevDisabled: true,
  nextDisabled: false,
  submitDisabled: true,
  isValidating: false,
  fetchError: '',
  submissionError: '',

  handleFetchBuildings: async (location_id) => {
    try {
      inspectedEntityStore.buildingsLoading = true;
      const service = new EntityService();
      inspectedEntityStore.buildings = await service.getEntities(location_id);
    } catch (error) {
      if (error instanceof Error) {
        inspectedEntityStore.fetchError = error.message;
      }
    } finally {
      inspectedEntityStore.buildingsLoading = false;
    }
  },

  handleFetchFloors: async (location_id, building_id) => {
    try {
      inspectedEntityStore.floorsLoading = true;
      const service = new EntityService();
      inspectedEntityStore.floors = await service.getEntities(location_id, building_id);
    } catch (error) {
      if (error instanceof Error) {
        inspectedEntityStore.fetchError = error.message;
      }
    } finally {
      inspectedEntityStore.floorsLoading = false;
    }
  },

  handleFetchTypes: async (location_id, floor_id) => {
    try {
      inspectedEntityStore.typesLoading = true;
      const service = new EntityTypeService();
      inspectedEntityStore.types = await service.getEntityTypes(location_id, 'Place', floor_id);
    } catch (error) {
      if (error instanceof Error) {
        inspectedEntityStore.fetchError = error.message;
      }
    } finally {
      inspectedEntityStore.typesLoading = false;
    }
  },

  handleFetchServiceItems: async () => {
    try {
      inspectedEntityStore.serviceItemsLoading = true;
      const service = new ServicePlanService();
      const items = await service.getServiceItems(inspectedEntityStore.inspectedEntity.entity_type.id);
      inspectedEntityStore.inspectedEntity.service_item_scores = []
      let fake_id = -1;
      items.map((item) => {
        let score = new InspectionServiceItemScore()
        score.id = fake_id--;
        score.service_item = item
        score.inspection_points = item.inspection_points
        inspectedEntityStore.inspectedEntity.service_item_scores.push(score)
      })
      inspectedEntityStore.serviceItemScores = inspectedEntityStore.inspectedEntity.service_item_scores;
    } catch (error) {
      if (error instanceof Error) {
        inspectedEntityStore.fetchError = error.message;
      }
    }finally {
      inspectedEntityStore.serviceItemsLoading = false
    }
  },

  handleResetModal: () => {
    inspectedEntityStore.inspectedEntity = new InspectedEntity();
    inspectedEntityStore.buildings = [];
    inspectedEntityStore.floors = [];
    inspectedEntityStore.types = [];
    inspectedEntityStore.serviceItemScores = [];
    inspectedEntityStore.isValidating = false;
    inspectedEntityStore.fetchError = '';
    inspectedEntityStore.submissionError = '';
  },

  handleResetInspectedEntityDetails: () => {
    inspectedEntityStore.inspectedEntity = new InspectedEntity();
    inspectedEntityStore.selectedFloor = null;
    inspectedEntityStore.selectedBuilding = new Entity();
    inspectedEntityStore.selectedBuilding = inspectionsStore.inspectionBuilding || null;
    inspectedEntityStore.inspectedEntity.entity = inspectionsStore.inspectionBuilding || new Entity();
    inspectedEntityStore.isValidating = false;
    inspectedEntityStore.fetchError = '';
    inspectedEntityStore.submissionError = '';
  },

  handleInspectClicked: () => {
    inspectedEntityStore.handleResetModal();
    inspectedEntityStore.handleResetInspectedEntityDetails();
    inspectedEntityStore.selectedBuilding = null;
    inspectedEntityStore.selectedFloor = null;
    inspectedEntityStore.creating = true;
  },

  handleEditInspectedEntity: (entity) => {
    inspectedEntityStore.inspectedEntity = entity;
    inspectedEntityStore.selectedBuilding = entity.entity_path[0];
    inspectedEntityStore.selectedFloor = entity.entity_path[1];
    inspectedEntityStore.editing = true;
  },

  handleUpdateInspectedEntity: (inspectedEntity) => {
    if (inspectionsStore.inspectionToEdit === null ) return;

    const entity_index = inspectionsStore.inspectionToEdit.inspected_entities.findIndex(item => item.id === inspectedEntity.id);

    if (entity_index !== -1) {
      inspectionsStore.inspectionToEdit.inspected_entities[entity_index] = inspectedEntity
    } else {
      inspectionsStore.inspectionToEdit.inspected_entities.push(inspectedEntity)
    }

    if (inspectedEntity.entity_path.length > 0) inspectedEntityStore.selectedBuilding = inspectedEntity.entity_path[0];

    inspectedEntityStore.inspectedEntity = inspectedEntity;
  },

  handleDisableButtons: (step: number) => {
    if( step === 0 ){
      inspectedEntityStore.submitDisabled = true;
      inspectedEntityStore.prevDisabled = true;
      inspectedEntityStore.nextDisabled = false;
    }

    if( step === 1){
      inspectedEntityStore.submitDisabled = false;
      inspectedEntityStore.prevDisabled = false;
      inspectedEntityStore.nextDisabled = true;
    }
  },

  handleGoToStep: (step: number) => {
    inspectedEntityStore.activeStep = step;
    inspectedEntityStore.handleDisableButtons(step);
  },



});