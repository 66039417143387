import React, { useState } from "react";
import { Box, Divider } from "@mui/material";
import { AttachFile } from "@mui/icons-material";
import MediaRowSquares from "../../../../../../shared/components/media/media-viewers/photos/MediaRowSquares";
import { useTranslation } from "react-i18next";
import { formatSlashedDate } from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import ItemProperty from "../../../../../../shared/components/forms/read-only/ItemProperty";
import { Media } from "../../../../../../shared/models/media.model";

const WorkItemReadOnly = ({ workItemToEdit, headers = true }) => {
  const { t } = useTranslation();

  const {
    created_by,
    status,
    is_good_catch,
    is_viewed,
    description,
    urgency,
    media,
    due_date
  } = workItemToEdit || {};

  const { first_name = "", last_name = "" } = created_by?.person || {};
  const beforeMedia = media.filter(m => m.workitem_status !== 'Closed');
  const afterMedia = media.filter(m => m.workitem_status === 'Closed');

  // Compute a display string for urgency
  const urgencyText =
    urgency < 30
      ? `Low — Due: ${formatSlashedDate(due_date).slice(0, -5)}`
      : urgency > 70
        ? `High — Due: ${formatSlashedDate(due_date).slice(0, -5)}`
        : `Medium — Due: ${formatSlashedDate(due_date).slice(0, -5)}`;

  // Convert detailItems to the shape expected by ItemProperty
  const detailItems = [
    {
      labelKey: "created-by",
      colorClass: "symbol-label bg-light-gray",
      fontIcon: "fa-solid fa-user",
      value: `${first_name} ${last_name}`,
    },
    {
      labelKey: "status",
      colorClass: "symbol-label bg-light-primary",
      fontIcon: "fa-solid fa-bars-progress",
      value: status,
    },
    {
      labelKey: "urgency",
      colorClass: "symbol-label bg-light-danger",
      fontIcon: "fa-solid fa-clock",
      value: urgencyText,
    },
    {
      labelKey: "marked-good-catch",
      colorClass: "symbol-label bg-light-warning",
      fontIcon: "fa-solid fa-bookmark",
      value: is_good_catch ? "Yes" : "No",
    },
    {
      labelKey: "seen-by-employee",
      colorClass: "symbol-label bg-light-success",
      fontIcon: "fa-solid fa-eye",
      value: is_viewed ? "Yes" : "No",
    },
  ];

  return (
    <div className="flex-column-fluid">
      {headers && (
        <div style={{ marginBottom: "15px" }}>
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-700">
              {t("details")}
            </span>
          </h3>
        </div>
      )}

      <div className="mb-3">
        <Box className="d-flex flex-column mt-8 w-100">
          {detailItems.map((item) => (
            <ItemProperty
              key={item.labelKey}
              colorClass={item.colorClass}
              fontIcon={item.fontIcon}
              name={t(item.labelKey)}
              value={item.value}
            />
          ))}
        </Box>
      </div>

      <Divider sx={{ marginBottom: "15px", width: "100%" }} />

      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">
          {t("additional-notes")}:
        </span>
        <span className="fs-7">
          {description || "No additional information"}
        </span>
      </div>

      <span className="text-gray-400 fw-semibold d-block fs-7">
          Before:
        </span>
      <div className="d-flex mt-1">
        <div className="d-flex justify-content-start flex-column">
          <MediaRowSquares
            itemMedia={beforeMedia}
            captions={status === "Closed"}
          />
        </div>
      </div>

      <span className="text-gray-400 fw-semibold d-block fs-7">
          After:
        </span>
      <div className="d-flex mt-1">
        <div className="d-flex justify-content-start flex-column">
          <MediaRowSquares
            itemMedia={afterMedia}
            captions={status === "Closed"}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkItemReadOnly;


