import { ProjectPlan } from "../projetPlan.model";
import { BaseSerializer } from "./baseSerializer";


export class ProjectPlanSerializer extends BaseSerializer {

  public deserialize( data ): any {

    let plan = new ProjectPlan();

    this._copyAttributes(plan, data);

    return plan;

  }

}