
export class SuggestionModel {
  name: string;
  description: string;
  module: string;
  count: number;
  type: string;

  constructor() {
    this.name = '';
    this.description = '';
    this.module = '';
    this.count = 0;
    this.type = '';
  }

}