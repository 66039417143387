import React from "react";
import { Button, Divider } from "@mui/material";
import ProjectHierarchy from "../ProjectHierarchy";
import EditProject from "./EditProject";
import { projectStore } from "../../../../projectsStore";
import { Project } from "../../../../../../shared/models/project.model";

const ProjectHierDualViewer = () => {

  const handleAddProject = () => {
    projectStore.selectedProject = new Project();
  }

  return (
    <>
      <div className="card">
        <div className="card-body d-flex flex-row" style={{ alignItems: 'flex-start' }}>
          <div className="d-flex flex-column flex-row-auto" style={{ minHeight: '600px', overflow: 'auto', width: '50%' }}>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3>Projects</h3>
              <Button variant="contained" color="primary" onClick={handleAddProject} style={{ height: '35px' }}>
                + Project
              </Button>
            </div>

            <ProjectHierarchy />
          </div>

          <Divider orientation="vertical" flexItem
                   style={{ backgroundColor: 'gray', marginRight: '10px', marginLeft: '10px' }} />


          <EditProject />

        </div>
      </div>
    </>
  );
};


export default ProjectHierDualViewer;