import { BaseSerializer } from "./baseSerializer";
import { Project } from "../project.model";

export class ProjectSerializer extends BaseSerializer {

  public deserialize(data: any): Project {
    let project = new Project();
    this._copyAttributes(project, data);

    // Calculate average Impact score
    const impactMetrics = project.metrics.filter(m => m.category_name === 'Impact');
    project.impact = impactMetrics.length > 0
      ? impactMetrics.reduce((sum, m) => sum + m.metric_score, 0) / impactMetrics.length
      : 0;

    // Calculate average Difficulty score
    const difficultyMetrics = project.metrics.filter(m => m.category_name === 'Difficulty');
    project.difficulty = difficultyMetrics.length > 0
      ? difficultyMetrics.reduce((sum, m) => sum + m.metric_score, 0) / difficultyMetrics.length
      : 0;

    return project;
  }

}
