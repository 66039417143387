import React, { useEffect, useRef, useState } from "react";
import SagePrompter from "./SagePrompter";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { CasesService } from "../../../../shared/services/cases/casesService";
import {
  tellSageContainerStyles,
  vaButtonContainerStyles
} from "../../../../shared/utils/table-modifier-util/tableDataUtil";
import SageCaseCreate from "./case-creation-assist/SageCaseCreate";
import ChatComponent from "./chat-with-sage/ChatComponent";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import casesStore from "../../caseStore";
import { SageError } from "../../../../shared/models/sageError.model";
import { sageStore } from "../../../sage-virtual-assistant/sageStore";

const TellSageModal = ({ onClose, fullScreen = false, incident = false }) => {
  const service = new CasesService();
  const isMobile = useMediaQuery(useTheme().breakpoints.only("xs"));
  const modalRef = useRef<any>(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const sageSnap = useSnapshot(sageStore);
  const snap = useSnapshot(casesStore);
  const [sections, setSections] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const fetchType = async () => {
      try {
        const service = new CasesService();
        const safetyCaseType = await service.lookupCaseType(1, "safety_incident");
        casesStore.caseToEdit.case_type = safetyCaseType[0];
        casesStore.caseToEdit.department = safetyCaseType[0]?.department
      } catch (error) {
        console.error('Failed to fetch case type:', error);
      }
    }

    if (incident) {
      casesStore.activeSection = 2;
      fetchType().then();
    }

  }, [incident]);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const dynamicForm = await service.getDynamicForm(snap.caseToEdit.case_type);
        const fetchedSections = dynamicForm.form.sections;
        setSections(fetchedSections);
      } catch (error) {
        console.error("Error fetching dynamic form:", error);
      }
    };

    if (snap.caseToEdit.case_type.id !== 0) {
      fetchSections().then();
    }
  }, [snap.caseToEdit.case_type]);

  useEffect(() => {
    const getResponseMessage = () => {
      const currentStepInfo = getCurrentStepInfo();
      return currentStepInfo?.sage_prompt || "";
    };

    casesStore.sageResponse = getResponseMessage();
    }, [snap.activeSection, sections]);

  const handleNextStep = () => {
    casesStore.activeSection++;
  }

  const handlePrevStep = () => {
    setError('');
    casesStore.activeSection--;
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      setError('');
      casesStore.caseToEdit = await service.createCase(casesStore.caseToEdit);
      handleNextStep();
    } catch (error) {
      const errorMessage = error instanceof SageError ? error.message : JSON.stringify(error);
      setError(`Failed to save case: ${errorMessage}`);
    } finally {
      setIsLoading(false);
    }
  };


  const handleRestart = () => {
    casesStore.reset();
    casesStore.activeSection = 0;
    setError('');
  };

  const sectionsCount = snap.initialSteps + sections.length + snap.endingSteps;

  const getCurrentStepInfo = () => {

    if (snap.activeSection < snap.initialSteps) {
      return getInitialStepInfo(snap.activeSection);
    } else if (snap.activeSection < snap.initialSteps + sections.length) {
      const sectionIndex = snap.activeSection - snap.initialSteps;
      return sections[sectionIndex];
    } else {
      return getEndingStepInfo(
        snap.activeSection - snap.initialSteps - sections.length
      );
    }
  };

  const getInitialStepInfo = (step) => {
    const initialPrompts = [
      {
        name: "Department Selection",
        sage_prompt: `${t('sage-prompt-1')}`,
      },
      {
        name: "Case Type Selection",
        sage_prompt: `${t('sage-prompt-2')}`,
      },
      {
        name: "Involved Employees",
        sage_prompt: `${t('sage-prompt-3')}`,
      },
      {
        name: "Details",
        sage_prompt: `${t('sage-prompt-4')}`,
      },
    ];

    return initialPrompts[step];
  };

  const getEndingStepInfo = (step) => {
    const endingPrompts = [
      {
        name: "Summary",
        sage_prompt: `${t('sage-prompt-5')}`,
      },
      {
        name: "Action",
        sage_prompt: `${t('sage-prompt-6')}`,
      },
    ];

    return endingPrompts[step];
  };

  const isSummaryStep = () => {
    return snap.activeSection === snap.initialSteps + sections.length;
  };

  const isActionStep = () => {
    return (
      snap.activeSection === snap.initialSteps + sections.length + snap.endingSteps - 1
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div style={tellSageContainerStyles(isMobile, fullScreen)} ref={modalRef}>
      {/* Tab Navigation */}
      {/*<Box sx={{ borderBottom: 1, borderColor: "divider" }}>*/}
      {/*  <Tabs*/}
      {/*    value={tabValue}*/}
      {/*    onChange={handleTabChange}*/}
      {/*    aria-label="Assist and Chat Tabs"*/}
      {/*  >*/}
      {/*    <Tab label="REPORT" />*/}
      {/*    <Tab label="CHAT" />*/}
      {/*  </Tabs>*/}
      {/*</Box>*/}
      {/* ASSIST Tab Content */}
      {tabValue === 0 && (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ overflowY: "auto", flex: 1 }}>
            <SagePrompter
              sageResponse={error === '' ? snap.sageResponse : t('sage-error')}
              error={error}
            />
            {!sageSnap.isTyping && (
              <Box
                sx={{
                  justifyContent: "center",
                  marginTop: "10px",
                  marginBottom: "200px",
                }}
              >
                <SageCaseCreate
                  sections={sections}
                  error={error}
                />
              </Box>
            )}
          </div>
          <div style={vaButtonContainerStyles(snap.activeSection)}>
            {/* Navigation Buttons */}
            {snap.activeSection > 0 && !isSummaryStep() && !isActionStep() && (
              <Button
                onClick={handlePrevStep}
                style={{
                  marginBottom: "20px",
                  marginRight: "20px",
                  fontSize: "1.2rem",
                }}
              >
                Prev
              </Button>
            )}
            {snap.activeSection > 1 && snap.activeSection < sectionsCount - 1 && !isSummaryStep() && (
              <Button
                onClick={handleNextStep}
                style={{ marginBottom: "20px", fontSize: "1.2rem" }}
                disabled={snap.activeSection === 2 && snap.caseToEdit.related_to_employees.length === 0}
              >
                Next
              </Button>
            )}
            {!sageSnap.isTyping && isSummaryStep() && (
              isLoading ? (
                <Button
                  onClick={undefined}
                  style={{ marginBottom: "20px", fontSize: "1.25rem", color: 'darkgreen' }}
                >
                  Submitting...
                </Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  style={{ marginBottom: "20px", fontSize: "1.2rem" }}
                >
                  Submit
                </Button>
              )
            )}

            {!sageSnap.isTyping && isActionStep() && (
              <>

                <Button
                  onClick={onClose}
                  style={{ marginBottom: "20px", fontSize: "1.2rem" }}
                >
                  Close
                </Button>
              </>
            )}
          </div>
        </div>
      )}
      {/* CHAT Tab Content */}
      {tabValue === 1 && (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <ChatComponent />
        </div>
      )}
    </div>
  );
};

export default TellSageModal;



