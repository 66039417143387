import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { TreeView } from '@mui/x-tree-view/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSnapshot } from "valtio";
import { projectStore } from "../../../projectsStore";
import { ProjectsService } from "../../../../../shared/services/projects/projectsService";
import ProgressBar
  from "../../../../analytics/components/site-health/components/tabs/corp-overview/components/card/components/ProgressBar";

const MAX_RECTANGLES = 5;
const getImpactRectangles = (impact) => {
  const filledCount = Math.ceil(impact);
  return Array.from({ length: MAX_RECTANGLES }, (_, i) => i < filledCount && impact > 0);
};

const renderImpact = (impact) => {
  const rectangles = getImpactRectangles(impact);
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '2px' }}>
      {rectangles.map((filled, i) => (
        <Box
          key={i}
          sx={{
            width: '10px',
            height: '20px',
            border: '1px solid #c7c7c7',
            backgroundColor: filled ? '#c7c7c7' : 'transparent'
          }}
        />
      ))}
    </Box>
  );
};

const renderDifficulty = (difficulty) => {
  const progress = difficulty * 10;
  let colorClass = 'bg-success'

  if (progress > 25 && progress <= 65) {
    colorClass = 'bg-warning';
  } else if (progress > 65) {
    colorClass = 'bg-danger';
  }

  return (
    <ProgressBar progress={progress} colorClass={colorClass} />
  );
};

const ProjectHierarchy = () => {
  const snap = useSnapshot(projectStore);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const service = new ProjectsService();

  useEffect(() => {
    const fetchProjects = async () => {
      if (snap.selectedPlan) {
        setIsLoading(true);
        try {
          const service = new ProjectsService();
          const data = await service.getProjects(snap.selectedPlan?.id);
          projectStore.projects = data;
        } catch (error) {
          console.error('Error fetching projects:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchProjects();
  }, [snap.selectedPlan, snap.refreshProjects]);


  const handleProjectSelected = async (event, nodeId) => {
    if (event.target.closest('.MuiTreeItem-iconContainer')) {
      return;
    }
        try {
          const proj = await service.getProject(snap.selectedPlan?.id, nodeId);
          projectStore.selectedProject = proj;
        } catch (error) {
          console.error('Failed to fetch employee:', error);
        }
        return;
  };


  const handleToggle = (event, nodeIds) => setExpandedNodes(nodeIds);

  const renderLabel = (node) => (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center',
        gap: '1rem',
        width: '100%'
      }}
    >
      <Typography variant="body2" sx={{ fontSize: '1rem' }}>{node.name}</Typography>
      <Box sx={{ textAlign: 'center' }}>{renderImpact(node.impact)}</Box>
      <Box sx={{ textAlign: 'center' }}>{renderDifficulty(node.difficulty)}</Box>
    </Box>
  );

  const renderTree = (node, type) => {
    if (node.matched !== undefined && !node.matched) {
      return null;
    }

    return (
      <TreeItem
        key={node.id}
        nodeId={`${node.id.toString()}`}
        label={renderLabel(node)}
        classes={treeItemClasses}
        sx={{
          '& .MuiTreeItem-content': {
            paddingTop: '8px',
            paddingBottom: '8px',
          },
          '& .MuiTreeItem-label': {
            fontSize: '1.2rem',
          },
          '& .MuiTreeItem-iconContainer svg': {
            fontSize: '1.5rem',
          },
        }}
      >
        {Array.isArray(node.children)
          ? node.children.map((childNode) => renderTree(childNode, type))
          : null}
      </TreeItem>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Table Header */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '1rem',
          fontWeight: 'bold',
          mb: 1
        }}
      >
        <Typography variant="body1">Name</Typography>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>Impact</Typography>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>Difficulty</Typography>
      </Box>

      {isLoading ? (
        <Typography variant="body1">Loading projects...</Typography>
      ) : snap.projects.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 15 }}>
          No projects found. You can add one by pressing the " + Project " button.
        </Typography>
      ) : (
        <TreeView
          aria-label="project plan hierarchy"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={handleProjectSelected}
          onNodeToggle={handleToggle}
          expanded={expandedNodes}
          sx={{ width: '100%', height: '100%' }}
        >
          {snap.projects.map((item) => renderTree(item, item.name))}
        </TreeView>
      )}
    </Box>
  );
};

export default ProjectHierarchy;
