import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { Button, Card } from "@mui/material";
import { suggestionsStore } from "./suggestionsStore";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import SuggestionRow from "./components/SuggestionRow";
import SuggestionProgress from "./components/SuggestionProgress";
import SuggestionActionMenu from "./components/SuggestionActionMenu";
import { AnalyticsService } from "../../../../shared/services/analytics/analyticsService";
import useAppStore from "../../../../appStore";


const Suggestions = () => {
  const snap = useSnapshot(suggestionsStore);
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const {selectedLocation} = useAppStore();
  const [showAll, setShowAll] = useState(false);
  const service = new AnalyticsService();


  useEffect(() => {
    const fetchData = async () => {
      try {
        suggestionsStore.isLoading = true;
        suggestionsStore.suggestions = await service.getSuggestions(user.organization.id, selectedLocation.id);
      } catch (error) {
        console.error(error);
      } finally {
        suggestionsStore.isLoading = false;
        console.log(suggestionsStore.suggestions);
      }
    };

    suggestionsStore.suggestions = [];
    if (selectedLocation?.id !== 0) {
      fetchData().then();
    }

  }, [user, selectedLocation.id]);


  if (snap.isLoading) {
    return <CustomLoadingDots />;
  }
  if (snap.error) {
    return <CustomError error={snap.error} />;
  }

  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: '5px',
        padding: '15px',
        boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
        width: '100%'
      }}
    >
      <div style={{ marginBottom: '20px' }}>
        <SuggestionProgress />
      </div>

      {suggestionsStore.suggestions.map((suggestion, index) => (
        <div style={{ marginBottom: '10px' }} key={index}>
          <SuggestionRow suggestion={suggestion} />
        </div>
      ))}

      {snap.suggestions.length > 3 && (
        <Button
          variant="text"
          onClick={() => setShowAll((prev) => !prev)}
        >
          {showAll ? 'Show Less' : 'Show More...'}
        </Button>
      )}

      {snap.open && <SuggestionActionMenu />}
    </Card>
  );
};

export default Suggestions;
