import React, { useEffect } from "react";
import Scoring from "../../edit-inspected-entity/components/scoring/Scoring";
import SpaceSelection from "../../edit-inspected-entity/components/space-selection/SpaceSelection";
import { inspectionsStore } from "../../../../../inspectionsStore";
import WorkItemCreationConfirmation from "./WorkItemCreationConfirmation";
import { inspectedEntityStore } from "../../../../../inspectedEntityStore";
import useAppStore from "../../../../../../../appStore";
import { useSnapshot } from "valtio";
import InspectionWorkItem from "../../edit-inspected-entity/components/inspection-work-item/InspectionWorkItem";

const InspectionSteps = (props) => {
  const { step, workItemStatus, handleWorkItemAction } = props;
  const {selectedLocation} = useAppStore();
  const inspectionSnap = useSnapshot(inspectionsStore);
  const inspectedEntitySnap = useSnapshot(inspectedEntityStore);

  useEffect(() => {
    if (selectedLocation.id !== 0) {
      inspectedEntityStore.handleFetchBuildings(selectedLocation.id).then();
    }
  }, [selectedLocation.id]);


  useEffect(() => {
    if (selectedLocation.id !== 0 && inspectedEntitySnap.selectedFloor && inspectedEntitySnap.selectedFloor?.id !== 0) {
      inspectedEntityStore.handleFetchTypes(selectedLocation.id, inspectedEntitySnap.selectedFloor.id).then();
    }
  }, [selectedLocation.id, inspectedEntitySnap.selectedFloor?.id]);

  useEffect(() => {
    if (selectedLocation.id !== 0 && inspectedEntitySnap.selectedBuilding && inspectedEntitySnap.selectedBuilding?.id !== 0) {
      inspectedEntityStore.handleFetchFloors(selectedLocation.id, inspectedEntitySnap.selectedBuilding.id).then();
    }
  }, [selectedLocation.id, inspectedEntitySnap.selectedBuilding]);

  useEffect(() => {

    if( inspectedEntitySnap.inspectedEntity.id === null && inspectedEntitySnap.inspectedEntity.entity_type && inspectedEntitySnap.inspectedEntity.id !== 0) {
      inspectedEntityStore.handleFetchServiceItems().then();
    }else{
      inspectedEntityStore.serviceItemScores = inspectedEntityStore.inspectedEntity.service_item_scores
    }

  }, [inspectedEntitySnap.inspectedEntity.entity_type ]);

  return (
    <div className="flex-column-fluid">
      {step === 0 && (
          <SpaceSelection />
      )}

      {step === 1 && (
          <Scoring />
      )}

      {step === 2 && <div></div>}

      {step === 3 && (
        <InspectionWorkItem
          onActionClicked={handleWorkItemAction}
          inspectedEntity={inspectedEntityStore.inspectedEntity}
        />
      )}

      {step === 4 && (
        <WorkItemCreationConfirmation workItemStatus={workItemStatus} />
      )}
    </div>
  );
};

export default InspectionSteps;

