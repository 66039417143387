import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import CaseForm from "./components/details/CaseForm";
import { AppBar, Button, Dialog, IconButton, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import useAppStore from "../../../../appStore";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import CaseReadOnly from "./components/details/read-only/CaseReadOnly";
import { CasesService } from "../../../../shared/services/cases/casesService";
import CommentsSection from "../../../../shared/components/comments/CommentsSection";
import ModalFooterActions from "../../../../shared/components/buttons/ModalFooterActions";
import ModalFooterSubmitProgress from "../../../../shared/components/buttons/ModalFooterSubmitProgress";
import { useSnapshot } from "valtio";
import casesStore from "../../caseStore";
import { employeeStore } from "../../../employees/employeeStore";
import { Employee } from "../../../../shared/models/employee.model";
import EditEmployee from "../../../employees/components/edit-employee/EditEmployee";
import { ConfirmationDialog } from "../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import CaseActionModal from "./components/case-action/CaseActionModal";


const EditCase = (props) => {

    const {
        open,
        incident=false
    } = props

    const snap = useSnapshot(casesStore);
    const employeeSnap = useSnapshot(employeeStore);
    const {toggleMasterRefresh} = useAppStore();
    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const admin = user.permissions.is_super_user || user?.permissions.hasAccess("Cases", 8);
    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
    const [error, setError] = useState('');
    const [formErrors, setFormErrors] = useState({
        department: false,
        caseType: false
    });
    const [isEditing, setIsEditing] = useState(snap.isEditing);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newComments, setNewComments] = useState<any>([]);
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const type = 'cases';

    useEffect(() => {
        const fetchDetailCase = async () => {
            try {
                const service = new CasesService();
                casesStore.caseToEdit = await service.getDetailCase(casesStore.caseToEdit.id);
            } catch (error) {
                console.error('Failed to fetch case:', error);
            }
        }

        if (snap.caseToEdit.id === null || snap.caseToEdit.id === 0) {
            casesStore.caseToEdit.created_by = user.employee;
            casesStore.caseToEdit.provider.id = user.organization?.id || 0;
        } else {
            fetchDetailCase().then();
        }

    }, [snap.caseToEdit.id]);


    useEffect(() => {
        const fetchType = async () => {
            try {
                const service = new CasesService();
                const safetyCaseType = await service.lookupCaseType(1, "safety_incident");
                casesStore.caseToEdit.case_type = safetyCaseType[0];
                casesStore.caseToEdit.department = safetyCaseType[0]?.department;
            } catch (error) {
                console.error('Failed to fetch case type:', error);
            } finally {

            }
        }

        if (incident) {
            fetchType().then();
        }

    }, [incident]);


    const handleAction = async (action) => {
        casesStore.caseToEdit.action.name = action;
        try {
            setIsSubmitting(true)
            const service = new CasesService();
            const isCreating = snap.caseToEdit.id === 0;
            if (isCreating) casesStore.caseToEdit.status = 'Working';
            casesStore.caseToEdit.department = casesStore.caseToEdit.case_type.department;
            casesStore.caseToEdit = isCreating
              ? await service.createCase(casesStore.caseToEdit)
              : await service.updateCase(casesStore.caseToEdit);
            if (isCreating) {
                setActionModalOpen(true);
            } else {
                toggleMasterRefresh();
                setNewComments([]);
                casesStore.handleCloseCaseModal();
            }
        } catch (e) {
            console.error('Error in handleSave:', e);
            setError(`Failed to update case: ${e instanceof Error ? e.message : JSON.stringify(e)}`);
            casesStore.handleCloseCaseModal();
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleFinishSubmission = () => {
        setActionModalOpen(false);
        toggleMasterRefresh();
        setNewComments([]);
        casesStore.handleCloseCaseModal();
    };

    const validateForm = () => {
        let valid = true;
        if (!snap.caseToEdit.case_type || snap.caseToEdit.case_type.name === '') {
            setFormErrors(prevErrors => ({ ...prevErrors, caseType: true }));
            valid = false;
        }
        return valid;
    };

    const handleSave = () => {
        if (validateForm()) {
            handleAction("submit").then();
        }
    };

    const handleCloseEmployee = (action) => {
        employeeStore.open = false;
        employeeStore.employeeToEdit = new Employee();
        employeeStore.editing = false;
        employeeStore.mode = 'basic';
    };

    const handleDelete = () => {
        casesStore.handleDeleteCase(snap.caseToEdit.id).then(toggleMasterRefresh);
    }


    const renderCard = (Component, props = {}) => (
      <Card style={{boxShadow: '0 1px 2px rgba(0,0,0,0.1)', margin: '5px', marginBottom: '10px', borderRadius: '0px'}}>
          <Card.Body>
              <Component {...props} />
          </Card.Body>
      </Card>
    );


    if (error) {
        return <CustomError error={error} />;
    }

    return (
      <Dialog fullScreen open={open} onClose={casesStore.handleCloseCaseModal} TransitionComponent={ModalTransition} PaperProps={{ style: { overflowX: 'hidden', backgroundColor: '#e9e9e9' } }}>
          <AppBar sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
              <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={casesStore.handleCloseCaseModal} aria-label="close">
                      <CloseIcon />
                  </IconButton>
                  {!isEditing ? (
                    <Button
                      color="inherit"
                      variant="contained"
                      onClick={() => setIsEditing(true)}
                      sx={{ ml: 'auto', color: '#444444' }}
                    >
                        {"Edit"}
                    </Button>
                    ) : (
                      <div style={{marginLeft: 'auto'}}>
                          {snap.caseToEdit.id !== 0 && snap.caseToEdit.status !== 'Closed' && (
                            <>
                                <Button
                                  color={'inherit'}
                                  variant="contained"
                                  sx={{ color: '#444444' }}
                                  onClick={() => handleAction('complete')}
                                >
                                    {'Mark Completed'}
                                </Button>
                            </>
                          )}
                      </div>
                  )}
                  {admin && snap.caseToEdit.id !== 0 && (
                      <Button
                        color={'inherit'}
                        variant="contained"
                        sx={{ color: '#444444', ml: 1 }}
                        onClick={casesStore.handleDeleteClicked}
                      >
                          {'Delete'}
                      </Button>
                    )}
              </Toolbar>
          </AppBar>

          {/* Main Content */}
          <Row className="edit-case-page mt-20 mb-20">
              {isMobile ? (
                <Col xs={12}>
                    {isEditing ? (
                        renderCard(CaseForm, { formErrors, incident })
                        ) : (
                        renderCard(CaseReadOnly, {  })
                        )}
                    {snap.caseToEdit.id !== 0 &&
                      renderCard(CommentsSection, { item: casesStore.caseToEdit, newComments, setNewComments, type})
                    }
                </Col>
              ) : (
                <>
                    <Col xs={12}>
                        {isEditing ? (
                          renderCard(CaseForm, { formErrors, incident })
                        ) : (
                          renderCard(CaseReadOnly, {  })
                        )}
                    </Col>
                    {snap.caseToEdit.id !== 0 &&
                      renderCard(CommentsSection, { item: casesStore.caseToEdit, newComments, setNewComments, type})
                    }
                </>
              )}
          </Row>

          {error && <CustomError error={error} />}
          {isSubmitting &&
            <ModalFooterSubmitProgress />
          }
          {isEditing && !isSubmitting &&
            <ModalFooterActions onSave={handleSave} onClose={casesStore.handleCloseCaseModal} text={snap.caseToEdit.id === 0 ? "Submit" : "Save"} />
          }
          <EditEmployee
            open={employeeSnap.open}
            onActionClicked={handleCloseEmployee}
          />
          {snap.deleteOpen && (
            <ConfirmationDialog
              isOpen={snap.deleteOpen}
              setIsOpen={casesStore.handleCloseCaseModal}
              message="Delete Case?"
              description="Are you sure you want to delete this case? This action cannot be undone."
              onConfirmClicked={handleDelete}
            />
          )}
          {actionModalOpen && (
            <CaseActionModal open={actionModalOpen} onClose={handleFinishSubmission} />
          )}
      </Dialog>
    );

}

export default EditCase
