import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import { getEmployee } from "../../../shared/utils/table-modifier-util/tableDataUtil";
import useAppStore from "../../../appStore";
import ActionButton from "../../../shared/components/buttons/ActionButton";
import { ActionPlanItem } from "../../../shared/models/actionPlanItem.model";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";


const RelationshipCard = (props) => {
  const {
    relationship,
    setSelected,
    setOpen,
  } = props;

  const { whiteLabelProps } = useAppStore();
  const {t} = useTranslation();
  const [actionPlan, setActionPlan] = React.useState<ActionPlanItem[]>(() => {
    return relationship.action_entries.filter(entry => entry.date_resolved === null).sort((a, b) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime());
  });
  const formattedDate = actionPlan.length > 0 && actionPlan[0].due_date
    ? format(new Date(actionPlan[0].due_date), 'MMM d, yyyy')
    : "--";

  useEffect(() => {
    setActionPlan(relationship.action_entries.filter(entry => entry.date_resolved === null).sort((a, b) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime()));
  }, [relationship.action_entries]);


  return (
    <>
      <Card variant="outlined" sx={{ marginTop: '15px', marginBottom: '15px', boxShadow: '0 1px 4px rgba(0,0,0,0.1)', position: 'relative', paddingBottom: 0 }}>

        <Box sx={{
          position: "absolute",
          top: -5,
          left: 0,
          backgroundColor: "#f6f6f6",
          width: '100%',
          color: "black",
          padding: "8px 8px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          zIndex: 50,
        }}>
          <Box sx={{
            width: '36px',
            height: '36px',
            backgroundColor: whiteLabelProps.primary_color,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            borderRadius: '4px',
            marginRight: '5px',
            padding: '1px',
          }}>
            {`${relationship?.sales_contact?.person.first_name[0]}${relationship?.sales_contact?.person.last_name[0]}`}
          </Box>
          <div className="d-flex flex-column p-1">
            <Typography variant="h4" sx={{ fontWeight: "bold", color: "#555", fontSize: "1.15rem", marginRight: "3px" }}>
              {relationship?.sales_contact?.person.first_name} {relationship?.sales_contact?.person.last_name}
            </Typography>
            <Typography variant="h4" color="textSecondary" sx={{ fontSize: '1.1rem', marginTop: '2px' }}>
              {relationship?.sales_contact?.company_name || ' --'}
            </Typography>
          </div>
        </Box>


        <Box sx={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: "8px 8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          zIndex: 50
        }}>
          <div className="d-flex flex-row align-items-center">
            <ActionButton
              iconType={"view"}
              onClick={(e) => {
                e.preventDefault();
                setSelected(relationship);
                setOpen(true);
              }}
            />
          </div>
        </Box>

        <CardContent sx={{ padding: 0, '&:last-child': { paddingBottom: 0 } }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #c6c6c6', width: '100%', paddingTop: '50px' }}>
            {/* Left Column */}
            <Box sx={{ width: "70%", paddingLeft: '20px'}}>
              <div className="d-flex flex-column">
                <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      left: "5px",
                      top: 0,
                      bottom: 0,
                      width: "1.5px",
                      backgroundColor: "transparent",
                      borderLeft: "1.5px dashed #c9c9c9",
                    }}
                  />

                  <Box
                    sx={{
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      backgroundColor: 'white',
                      border: "2px solid #c3c3c3",
                      position: "absolute",
                      left: "0px",
                      top: "50%",
                      transform: "translateY(-50%)"
                    }}
                  />

                  <div className="d-flex flex-column ps-10 pb-5 pt-5 pe-10 ">
                    <Typography variant="h4" sx={{ fontWeight: "bold", color: "#555", fontSize: "1.0rem" }}>
                      {t('next-step')}
                    </Typography>
                    {actionPlan.length === 0 ? (
                      <>
                        <Typography variant="body1" sx={{ color: "#555" }}>None</Typography>
                      </>
                      ) : (
                      <>
                        <Typography variant="body1" sx={{ color: "#555" }}>{actionPlan[0].action_to_take}</Typography>
                        <Typography variant="body2" color="textSecondary">{t('due')}: {formattedDate}</Typography>
                      </>
                    )}

                  </div>
                </Box>
              </div>
            </Box>

            {/* Right Column */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "30%", textAlign: "right", padding: "16px" }}>
              {(actionPlan.length) > 0 &&
                <Typography variant="body2" sx={{ color: whiteLabelProps.primary_color, marginTop: '5px' }}>
                  {actionPlan.length + ` ${t('pending-action-items')}`}
                </Typography>
              }
            </Box>
          </Box>
          <Box style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "15px"
          }}>
            {/* Left Column */}
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginTop: "10px",
              padding: "16px",
              paddingBottom: 0,
              paddingTop: 0
            }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color:
                    relationship.sales_contact.relationship_level === 3
                      ? "#DB5856"
                      : relationship.sales_contact.relationship_level === 2
                        ? "goldenrod"
                        : "#555",
                  fontSize: "1.0rem",
                }}
              >
                {relationship.sales_contact.relationship_level === 3
                  ? "High Priority"
                  : relationship.sales_contact.relationship_level === 2
                    ? "Medium Priority"
                    : "Low Priority"}
              </Typography>

              <Typography variant="h4" color="textSecondary" sx={{ fontSize: "0.9rem", marginTop: "2px" }}>
                {t('relationship-level')}
              </Typography>
            </Box>

            {/* Right Column */}
            <Box sx={{ textAlign: "right", marginTop: "10px", padding: "16px", paddingBottom: 0, paddingTop: 0 }}>
              <div className="d-flex flex-column"
                   style={{
                     maxWidth: "350px",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                     whiteSpace: "nowrap"
                   }}>
                <Typography variant="h4" sx={{ fontWeight: "bold", color: "#555", fontSize: "1.0rem" }}>
                  {getEmployee(relationship)}
                </Typography>
                <Typography variant="h4" color="textSecondary" sx={{ fontSize: "0.9rem", marginTop: "2px" }}>
                  {t('relationship-manager')}
                </Typography>
              </div>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default RelationshipCard;

