import { BaseSerializer } from "./baseSerializer";
import { SuggestionModel } from "../suggestionModel";

export class SuggestionSerializer extends BaseSerializer {


  public deserialize( data ): any {

    const suggestion = new SuggestionModel();

    this._copyAttributes(suggestion, data);

    return suggestion;

  }

}