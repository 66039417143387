import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { TraceSerializer } from "../../../models/serializers/traceSerializer";


export class CommandGetTraces {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE}/traces/`;
  }

  async run(search, offset, selectedEmployee, selectedStatus='All', selectedHttpMethod='All') {

    const url = this.getUrl()

    const params = {
      params: {
        search: search,
        limit: 20,
        offset: offset,
        employee: selectedEmployee && selectedEmployee.id ? selectedEmployee.id : undefined,
        status: selectedStatus,
        http_method: selectedHttpMethod
      }
    }

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserialize(data) {
    const serializer = new TraceSerializer()
    const traces = serializer.deserializeList(data)
    return traces
  }

}