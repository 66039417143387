import React from "react";
import EmployeeRow from "./EmployeeRow";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    getCardClass
} from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import { useTranslation } from "react-i18next";


const EmployeeTable = (props) => {

    const{
        employees,
        onEdit,
        onDelete
    } = props

    const {t} = useTranslation();
    const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

    return (
      <>
          <div className={getCardClass(isMobile)}>
              <div className="card-body">
                  <div className="table-responsive">
                      <table className="table table-row-dashed align-middle gs-0 gy-3">
                          <thead>
                          <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                              {isMobile ? (
                                <>
                                    <th className="p-0 pb-3 min-w-150px text-start">{t('employee').toUpperCase()}</th>
                                    <th className="p-0 pb-3 min-w-25px text-start">{t('user').toUpperCase()}</th>
                                    <th className="p-0 pb-3 min-w-50px text-end px-3">{t('actions').toUpperCase()}</th>
                                </>
                              ) : (
                                <>
                                    <th className="p-0 pb-3 min-w-215px text-start">{t('employee').toUpperCase()}</th>
                                    <th className="p-0 pb-3 min-w-60px text-start px-3">{t('phone').toUpperCase()}</th>
                                    <th className="p-0 pb-3 min-w-160px text-start px-3">EMAIL</th>
                                    <th className="p-0 pb-3 min-w-50px text-start px-3">ID</th>
                                    <th className="p-0 pb-3 min-w-25px text-end">{t('actions').toUpperCase()}</th>
                                </>
                              )}
                          </tr>
                          </thead>
                          <tbody>
                          {employees.map((employee) => (
                            <EmployeeRow key={employee.id}
                                         employee={employee}
                                         onEdit={onEdit}
                                         onDelete={onDelete}
                            />
                          ))}
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
      </>
    );

}

export default EmployeeTable