// src/components/DummyGanttChart.tsx
import React, { useState } from 'react';
import { Card, CardContent, Typography, Tooltip, Box } from '@mui/material';

interface Task {
  name: string;
  start: string; // "YYYY-MM-DD"
  end: string;   // "YYYY-MM-DD"
}

// Dummy tasks and date range
const tasks: Task[] = [
  { name: "Project A", start: "2024-12-01", end: "2024-12-05" },
  { name: "Project B", start: "2024-12-03", end: "2024-12-10" },
  { name: "Project C", start: "2024-12-07", end: "2024-12-12" },
  { name: "Project D", start: "2024-12-03", end: "2024-12-12" },
  { name: "Project E", start: "2024-12-01", end: "2024-12-03" },
  { name: "Project F", start: "2024-12-07", end: "2024-12-12" },
];

const startDate = new Date("2024-12-01");
const endDate = new Date("2024-12-15");

function generateDateRange(start: Date, end: Date): Date[] {
  const dates: Date[] = [];
  let current = new Date(start);
  while (current <= end) {
    dates.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }
  return dates;
}

function parseDate(str: string): Date {
  const [year, month, day] = str.split("-").map(Number);
  return new Date(year, month - 1, day);
}

function isWithinRange(date: Date, start: Date, end: Date): boolean {
  return date >= start && date <= end;
}

const dateRange: Date[] = generateDateRange(startDate, endDate);

const DummyGanttChart: React.FC = () => {
  const [hoveredCell, setHoveredCell] = useState<{ taskName: string; date: Date } | null>(null);

  const handleCellClick = (taskName: string, date: Date) => {
    console.log(`Clicked on ${taskName} for date ${date.toDateString()}`);
    alert(`You clicked on ${taskName} for date ${date.toDateString()}`);
  };

  return (
    <div className="card card-flush h-md-100 mt-5">
      <div className="card-header pt-7 pb-3">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-800">Plan</span>
          <span className="text-gray-400 mt-1 fw-semibold fs-6">Plan projects</span>
        </h3>
      </div>

        <Box sx={{ overflowX: 'auto' }}>
          <table style={{ borderCollapse: "collapse", width: "100%", fontFamily: "Arial, sans-serif" }}>
            <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #15aa78",
                  color: "#15aa78",
                  whiteSpace: "nowrap",
                  position: "sticky",
                  left: 0,
                  background: "#fff",
                  zIndex: 2
                }}
              >
                Task
              </th>
              {dateRange.map((date) => (
                <th
                  key={date.toISOString()}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                    fontSize: "12px",
                    borderBottom: "1px solid #ccc",
                    whiteSpace: "nowrap"
                  }}
                >
                  {date.getDate()}
                </th>
              ))}
            </tr>
            </thead>
            <tbody>
            {tasks.map((task) => {
              const taskStart = parseDate(task.start);
              const taskEnd = parseDate(task.end);

              return (
                <tr key={task.name}>
                  <td
                    style={{
                      textAlign: "left",
                      padding: "8px",
                      borderBottom: "1px solid #ddd",
                      whiteSpace: "nowrap",
                      position: "sticky",
                      left: 0,
                      background: "#fff",
                      zIndex: 1
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {task.name}
                    </Typography>
                  </td>
                  {dateRange.map((date) => {
                    const isTaskDay = isWithinRange(date, taskStart, taskEnd);
                    const backgroundColor = isTaskDay ? "#15aa78" : "transparent";
                    const textColor = isTaskDay ? "#fff" : "#000";

                    return (
                      <Tooltip
                        key={date.toISOString()}
                        title={
                          isTaskDay
                            ? `${task.name}: ${date.toDateString()}`
                            : ''
                        }
                      >
                        <td
                          style={{
                            borderBottom: "1px solid #ddd",
                            padding: "5px",
                            textAlign: "center",
                            backgroundColor,
                            color: textColor,
                            cursor: isTaskDay ? "pointer" : "default",
                            transition: "background-color 0.3s",
                          }}
                          onMouseEnter={() => {
                            if (isTaskDay) {
                              setHoveredCell({ taskName: task.name, date });
                            }
                          }}
                          onMouseLeave={() => {
                            if (isTaskDay) {
                              setHoveredCell(null);
                            }
                          }}
                          onClick={() => {
                            if (isTaskDay) handleCellClick(task.name, date);
                          }}
                        >
                          {/* Display a small bar or just leave it empty */}
                          {isTaskDay && (
                            <Box
                              sx={{
                                height: '10px',
                                backgroundColor: "#fff",
                                borderRadius: '2px'
                              }}
                            />
                          )}
                        </td>
                      </Tooltip>
                    );
                  })}
                </tr>
              );
            })}
            </tbody>
          </table>
        </Box>

        {hoveredCell && (
          <Box sx={{ mt: 2, border: '1px solid #ccc', p: 1, borderRadius: 1 }}>
            <Typography variant="body2" sx={{ color: '#333' }}>
              Hovering over: <strong>{hoveredCell.taskName}</strong> on {hoveredCell.date.toDateString()}
            </Typography>
          </Box>
        )}
    </div>
  );
};

export default DummyGanttChart;



