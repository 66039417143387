import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { ProjectSerializer } from "../../../models/serializers/projectSerializer";

export class CommandGetProjects {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(plan_id) {
    return  `${this.SERVICE}/project_plans/${plan_id}/projects/`;
  }

  async run(plan_id) {

    const url = this.getUrl(plan_id)
    try {
      const response = await httpClient.get(url);
      return this.deserializePosts(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  }

  deserializePosts(data) {
    const serializer = new ProjectSerializer()
    const projects = serializer.deserializeList(data)
    return projects
  }

}