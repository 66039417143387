import React, { useCallback, useEffect, useState } from "react";
import { AppBar, Dialog, IconButton, Typography, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ModalTransition from "../../../../../../../../../shared/components/page/transitions/ModalTransition";
import InspectionsCardList
  from "../../../../../../../../inspections/components/inspections-list/InspectionsCardList";
import useAppStore from "../../../../../../../../../appStore";
import { InspectionService } from "../../../../../../../../../shared/services/inspections/inspectionService";
import EditAreaInspectionPage from "../../../../../../../../inspections/components/edit-inspection/EditAreaInspection";
import CustomLoadingDots from "../../../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { inspectionsStore } from "../../../../../../../../inspections/inspectionsStore";
import { useSnapshot } from "valtio";

interface InspectionListDrillDownProps {
  open: boolean;
  title: string;
  onActionClicked: (action: string) => void;
  timeframe?: number;
  location_id?: number;
  include_regular?: boolean;
  include_joint?: boolean;
  include_baseline?: boolean;
  from_date?: string;
  to_date?: string;
  space_id?: number;
  space_type_id?: number;
  headers?: boolean;
}

const InspectionListDrillDown: React.FC<InspectionListDrillDownProps> = ({
                                                                           open,
                                                                           title,
                                                                           onActionClicked,
                                                                           timeframe,
                                                                           location_id,
                                                                           include_regular = true,
                                                                           include_joint = true,
                                                                           include_baseline = true,
                                                                           from_date = undefined,
                                                                           to_date = undefined,
                                                                           space_id = undefined,
                                                                           space_type_id = undefined,
                                                                           headers = true
                                                                         }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const service = new InspectionService();
  const snap = useSnapshot(inspectionsStore);

  useEffect(() => {
    const fetchData = async () => {
      const empId = title === 'Employee' ? location_id : undefined;
      const locationId = title !== 'Employee' ? location_id : undefined;
      try {
        inspectionsStore.isLoading = true;
        const inspectionsData = await service.getInspections(
          user.organization.id,
          locationId,
          0,
          undefined,
          include_regular,
          include_joint,
          include_baseline,
          empId,
          timeframe,
          from_date,
          to_date,
          space_id,
          space_type_id
        );
        inspectionsStore.inspections = inspectionsData;
      } catch (error) {
        console.error(error);
      } finally {
        inspectionsStore.isLoading = false;
      }
    };

    fetchData().then();
  }, [timeframe, location_id, title, user.organization.id, from_date, to_date, include_regular, include_joint, include_baseline, space_id, space_type_id]);

  // Gather all filters
  const filters = {
    timeframe,
    location_id,
    include_regular,
    include_joint,
    include_baseline,
    from_date,
    to_date,
    space_id,
    space_type_id
  };

  // Filter out undefined parameters
  const appliedFilters = Object.entries(filters).filter(([key, value]) => value !== undefined);

  return (
    <Dialog fullScreen open={open} onClose={() => onActionClicked('close')} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div style={{ padding: '2rem', paddingTop: '15px' }}>
        {/* Show filters if any are set */}
        {appliedFilters.length > 0 && (
          <div style={{
            marginBottom: '1rem',
            background: '#f9f9f9',
            padding: '1rem',
            borderRadius: '8px',
            border: '1px solid #ddd'
          }}>
            <Typography variant="subtitle1" gutterBottom>
              Current Filters:
            </Typography>
            {appliedFilters.map(([key, value]) => (
              <Typography variant="body2" key={key} style={{ marginBottom: '0.5rem' }}>
                <strong>{key.replace('_', ' ')}:</strong> {String(value)}
              </Typography>
            ))}
          </div>
        )}

        {snap.isLoading && (
          <CustomLoadingDots />
        )}
        <InspectionsCardList />
      </div>
      <EditAreaInspectionPage />
    </Dialog>
  );
};

export default InspectionListDrillDown;
