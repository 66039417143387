import { CSSProperties } from "react";
import { DateTime } from 'luxon';


export const getTitle = (workItem: any): string => {
  return workItem.title || "";
};

export const getServiceName = (workItem: any): string => {
  const service = workItem.where_and_what?.[0]?.service_items?.[0]?.entity_type?.name;
  return service || "Service";
};


export const getEntityTypeName = (workItem: any): string => {
  const entityName = workItem.where_and_what?.[0]?.entity_type?.name;
  if (entityName) {
    return `${entityName}`;
  } else {
    return "NA";
  }
};

export const getBuildingName = (workItem: any): string => {
  const building = workItem.where_and_what?.[0]?.entity.entity_path[0]?.name;
  return building || "No Space Specified";
};


export const getFloor = (workItem: any): string => {
  const floor = workItem.where_and_what?.[0]?.entity.name || '0';
  return ("Floor " + floor) || "";
};

export const getEmployee = (item: any): string => {
  const firstName = item.assigned_to_employees?.[0]?.person?.first_name || '';
  const lastName = item.assigned_to_employees?.[0]?.person?.last_name || 'N/A';
  return `${firstName} ${lastName}`;
};

export const getCreatedBy = (item: any): string => {
  const firstName = item.created_by?.person?.first_name || '';
  const lastName = item.created_by?.person?.last_name || 'N/A';
  return `${firstName} ${lastName}`;
};

export const getCaseAssignee = (item: any): string => {
  const index = item.assigned_to_employees?.length - 1;
  const firstName = item.assigned_to_employees?.[index]?.person?.first_name || '--';
  const lastName = item.assigned_to_employees?.[index]?.person?.last_name || '';
  return `${firstName} ${lastName}`;
};

export const getCaseRelatedEmployee = (item: any): string => {
  const index = item.related_to_employees?.length - 1;
  const firstName = item.related_to_employees?.[index]?.person?.first_name || '--';
  const lastName = item.related_to_employees?.[index]?.person?.last_name || '';
  return `${firstName} ${lastName}`;
};

export const getCardClass = (isMobile: boolean): string => {
  return isMobile ? "mt-5" : "card card-flush h-md-100 mt-5 mb-10";
}

export const getTitleClass = (isMobile: boolean): string => {
  return isMobile ? "d-none" : "card-title align-items-start flex-column";
}
export const getButtonClass = (isMobile: boolean): string => {
  return isMobile ? "btn btn-sm btn-light w-100" : "btn btn-sm btn-light me-2";
}

export const getHeaderClass = (isMobile: boolean): string => {
  return isMobile ? "" : "card-header pt-7";
}

export const calculateProgress = (value, urgency = true) => {
  let colorClass = 'bg-success';
  let label = urgency ? 'Low' : 'Pretty Good';

  if (value > 25 && value <= 75) {
    colorClass = 'bg-warning';
    label = urgency ? 'Medium' : 'Some Issues';
  } else if (value > 75) {
    colorClass = 'bg-danger';
    label = urgency ? 'High' : 'Significant Issues';
  }

  return { value, colorClass, label };
};

export const getTextForPosition = (position) => {
  const thresholds = [
    { upTo: 25, text: 'Needs Serious Attention', message: 'A lot can be improved, you will need to put in some more effort to raise your score.' },
    { upTo: 75, text: 'Needs Improvement', message: 'Not terrible, but not where you want to be, take a look at my suggestions to raise your score.' },
    { upTo: 90, text: 'Pretty Good', message: 'Impressive, but there is room for improvement. Take a look at my suggestions to raise your score' },
    { upTo: 100, text: 'Verde Vibed', message: 'Everything looks great! I have nothing to suggest!' },
  ];

  const threshold = thresholds.find(thresh => position <= thresh.upTo);

  return threshold ? { text: threshold.text, message: threshold.message } : { text: 'Pretty Good', message: 'Position is fairly good, but specific suggestions are not available.' };
};


export const sortData = (data, sortConfig) => {
  return data.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

export const getTimeDifferenceString = (dateString, text='ago') => {
  if (!dateString) return 'N/A';

  const inspectionDate = new Date(dateString);
  const currentDate = new Date();
  const differenceInTime = currentDate.getTime() - inspectionDate.getTime();
  const differenceInHours = differenceInTime / (1000 * 3600);
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  if (differenceInDays === 1) {
    if (differenceInHours < 24) {
      return differenceInHours < 1
        ? 'Just now'
        : `${Math.floor(differenceInHours)} hour${Math.floor(differenceInHours) === 1 ? '' : 's'} ago`;
    } else {
      return 'Today';
    }
  } else {
    return `${differenceInDays} days ${text}`;
  }
};

export const getDaysAgo = (dateString) => {
  if (!dateString) return 0;

  const inspectionDate = new Date(dateString);
  const currentDate = new Date();
  const differenceInTime = currentDate.getTime() - inspectionDate.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
};


export const formatSlashedDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

export const getBadge = (score) => {
  if (score >= 0 && score <= 2.99) return "badge py-3 fs-7 badge-light-danger";
  if (score >= 3 && score <= 3.99) return "badge py-3 fs-7 badge-light-warning";
  if (score >= 4 && score <= 5) return "badge py-3 fs-7 badge-light-success";
  return "badge";
};

export const getMediaType = (url) => {
  const extension = url.split('?')[0].split('.').pop();
  if (extension === 'pdf') {
    return 'pdf';
  } else if (['png', 'jpg', 'jpeg', 'gif', 'webp'].includes(extension)) {
    return 'image';
  }
  return 'unknown';
};

export const isNumeric = (str) => {
  if (typeof str != "string") return false;
  return !isNaN(parseFloat(str));
}

export function capitalizeFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export const tellSageContainerStyles = (isMobile: boolean, fullScreen: boolean): CSSProperties => {
  const centeredStyles: CSSProperties = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    height: '700px',
    border: '1px solid #d3d3d3',
    boxShadow: '0px 7px 11px rgba(0, 0, 0, 0.33)',
    backgroundColor: 'white',
  };

  const mobileStyles: CSSProperties = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: 998,
    gap: '10px',
    padding: '10px',
    background: '#fafafa',
    borderTop: '1px solid #e0e0e0',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 'auto',
    height: 'auto',
    border: 'none',
    boxShadow: 'none',
  };

  const mobileStylesAlt: CSSProperties = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    top: 35,
    zIndex: 998,
    gap: '10px',
    padding: '10px',
    background: '#fafafa',
    borderTop: '1px solid #e0e0e0',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: 'auto',
    height: 'auto',
    border: 'none',
    boxShadow: 'none',
  };

  const defaultStyles: CSSProperties = {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 998,
    gap: '10px',
    padding: '10px',
    background: '#fafafa',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '400px',
    height: '650px',
    border: '1px solid #d3d3d3',
    boxShadow: '0px 7px 11px rgba(0, 0, 0, 0.33)',
  };

  if (isMobile && fullScreen) {
    return mobileStylesAlt;
  } else if (isMobile) {
    return mobileStyles;
  } else if (fullScreen) {
    return centeredStyles;
  } else {
    return defaultStyles;
  }
};

export const sageSuggestionsModalStyle = (isMobile: boolean, open: boolean): CSSProperties => {
  if (isMobile) {
    // Mobile – full screen (no slide from the side unless you add your own transform)
    return {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 998,
      padding: "10px",
      background: "#fafafa",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      boxShadow: "none",
      transition: "none" // or remove if you want a fade/slide for mobile
    };
  }

  // Desktop – right-side drawer with slide
  return {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 998,
    padding: "10px",
    background: "#fafafa",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    width: "500px",
    border: "1px solid #d3d3d3",
    boxShadow: "0px 7px 11px rgba(0, 0, 0, 0.33)",

    // Key to animating from the right:
    transform: open ? "translateX(0)" : "translateX(100%)",
    transition: "transform 0.3s ease-in-out",
  };
};


export const vaButtonContainerStyles = (step: number): CSSProperties => ({
  position: 'fixed',
  bottom: -10,
  width: '100%',
  background: '#fafafa',
  borderTop: '2px solid #e8e8e8',
  zIndex: 999,
  padding: '10px',
});

export const inspectionButtonContainerStyle = (isMobile: boolean): CSSProperties => ({
  position: 'fixed',
  bottom: 0,
  background: '#fafafa',
  borderTop: '2px solid #e8e8e8',
  width: isMobile ? '100%' : '400px',
  zIndex: 999,
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});




export function getPacificTime(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/Los_Angeles',
  };

  const pacificTime = new Intl.DateTimeFormat('en-US', options).format(date);

  return pacificTime;
}

export function formatDateTime(isoString: string): string {
  const dateTime = DateTime.fromISO(isoString).setZone('America/Los_Angeles'); // Set to Pacific Time
  return dateTime.toLocaleString(DateTime.DATETIME_FULL);
}

export function formatWrittenDate(isoString: string): string {
  const dateTime = DateTime.fromISO(isoString).setZone("America/Los_Angeles");
  return dateTime.toLocaleString({ month: "long", day: "numeric", year: "numeric" });
}

export const printStyles = `
        @media print {
            @page {
                size: A4;
                margin: 1mm;
            }
            body {
                -webkit-print-color-adjust: exact !important;
            }
            .MuiDialog-root {
                overflow: visible !important;
            }
            #modal-content {
                height: auto !important;
                overflow: visible !important;
            }
        }
    `;










