/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import useAppStore from '../../../../../app/appStore'
import {SidebarMenuItem} from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { useTranslation } from "react-i18next";

const SidebarMenuMain = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const {whiteLabelProps} = useAppStore()
  const { t } = useTranslation();

  const hasDepartmentCasesAccess = user.permissions.hasAccess("Department Cases");
  const hasLocationCasesAccess = user.permissions.hasAccess("Location Cases");
  const hasMultipleAccess = hasDepartmentCasesAccess && hasLocationCasesAccess;

  const casesLink = () => {
    if (hasDepartmentCasesAccess && !hasLocationCasesAccess) {
      return "/facilities/department-cases";
    }
    if (hasLocationCasesAccess && !hasDepartmentCasesAccess) {
      return "/facilities/location-cases";
    }
    return "/facilities/cases";
  };


    /* ICONS
      Go here to see icons that can be used
      https://preview.keenthemes.com/html/metronic/docs/icons/keenicons
     */
  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={t('dashboard')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={true}
      />
      <SidebarMenuItem
        to="/location-summary"
        icon="tablet-text-up"
        title={t('location-summary')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Location Summary")}
      />

      <SidebarMenuItemWithSub
        to="/analytics"
        icon="chart-pie-3"
        title={t('analytics')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Analytics")}
      >
        <SidebarMenuItem
          to="/site-health"
          icon="chart-line-up"
          title={t('overview')}
          fontIcon="bi-app-indicator"
          hasBullet={false}
          isVisible={user.permissions.hasAccess("Analytics")}
        />
        <SidebarMenuItem
          to="/inspection-analytics"
          icon="chart-simple"
          title={t('inspection-charts')}
          fontIcon="bi-app-indicator"
          hasBullet={false}
          isVisible={user.permissions.hasAccess("Analytics")}
        />
        <SidebarMenuItem
          to="/customer-survey-analytics"
          icon="notepad"
          title={t('customer-surveys')}
          fontIcon="bi-app-indicator"
          hasBullet={false}
          isVisible={user.permissions.hasAccess("Analytics")}
        />
        <SidebarMenuItem
          to="/employee-survey-analytics"
          icon="notepad"
          title={t('employee-surveys')}
          fontIcon="bi-app-indicator"
          hasBullet={false}
          isVisible={user.permissions.hasAccess("Analytics")}
        />

      </SidebarMenuItemWithSub>

      {/* SOCIAL */}
      <div className="menu-item visually-hidden">
        <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-uppercase fs-8 ls-1"
                      style={{ color: whiteLabelProps.contrast_color }}>Social</span>
        </div>
      </div>
      {/*<SidebarMenuItem*/}
      {/*  to="/social/feed"*/}
      {/*  icon="abstract-21"*/}
      {/*  title="Feed"*/}
      {/*  fontIcon="bi-app-indicator"*/}
      {/*  hasBullet={false}*/}
      {/*  isVisible={false}*/}
      {/*/>*/}
      <SidebarMenuItem
        to="/crafted/pages/profile/connections"
        icon="people"
        title="Contributors"
        hasBullet={false}
        isVisible={false}
      />


      {/* FACILITIES */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-uppercase fs-8 ls-1"
                      style={{ color: whiteLabelProps.contrast_color }}>{t('facilities')}</span>
        </div>
      </div>
      <SidebarMenuItem
        to="/facilities/inspections"
        icon="note-2"
        title={t('inspections')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Inspections")}
      />
      <SidebarMenuItem
        to="/facilities/work-items"
        icon="check-square"
        title={t('work-items')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Work Items")}
      />
      {hasMultipleAccess ? (
        <>
      <SidebarMenuItemWithSub
        to={"/facilities/cases"}
        icon="clipboard"
        title={t('cases')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Cases")}
      >
            <SidebarMenuItem
              to="/facilities/department-cases"
              icon="clipboard"
              title={t('department-cases')}
              fontIcon="bi-app-indicator"
              hasBullet={false}
              isVisible={hasDepartmentCasesAccess}
            />
            <SidebarMenuItem
              to="/facilities/location-cases"
              icon="clipboard"
              title={t('location-cases')}
              fontIcon="bi-app-indicator"
              hasBullet={false}
              isVisible={hasLocationCasesAccess}
            />
      </SidebarMenuItemWithSub>
        </>
      ) : (
        <SidebarMenuItem
          to={casesLink()}
          icon="clipboard"
          title={t('cases')}
          fontIcon="bi-app-indicator"
          hasBullet={false}
          isVisible={hasDepartmentCasesAccess || hasLocationCasesAccess}
        />
      )}
      <SidebarMenuItem
        to="/facilities/surveys"
        icon="like-2"
        title={t('surveys')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Surveys")}
      />

      {/* TEAM */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-uppercase fs-8 ls-1"
                      style={{ color: whiteLabelProps.contrast_color }}>{t('team')}</span>
        </div>
      </div>
      {/*<SidebarMenuItem*/}
      {/*  to="/social/feed"*/}
      {/*  icon="messages"*/}
      {/*  title="Feed"*/}
      {/*  fontIcon="bi-app-indicator"*/}
      {/*  hasBullet={false}*/}
      {/*  isVisible={loggedInUser.permissions.hasAccess("Feed")}*/}
      {/*/>*/}
      <SidebarMenuItem
        to="/team/employees"
        icon="people"
        title={t('employees')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Employees")}
      />
      <SidebarMenuItem
        to="/team/training"
        icon="teacher"
        title={t('training')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Employee Training")}
      />
      <SidebarMenuItem
        to="/team/org-chart"
        icon="ranking"
        title={t('org-chart')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Org Chart")}
      />
      <SidebarMenuItem
        to="/ehs/home"
        icon="shield-tick"
        title={t('safety')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={true}
      />

      {/* SETTINGS */}
      <div className="menu-item visually-hidden">
        <div className="menu-content pt-8 pb-2">
                <span className="menu-section text-uppercase fs-8 ls-1"
                      style={{ color: whiteLabelProps.contrast_color }}>Settings</span>
        </div>
      </div>
      <SidebarMenuItem
        to="/apps/user-management/users"
        icon="clipboard"
        title={t('users')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={false}
      />
      <SidebarMenuItem
        to="/apps/user-management/users"
        icon="element-7"
        title="Web Page"
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={false}
      />

      {user.permissions.hasAccess("Relationship Management") && (
        <div className="menu-item">
          <div className="menu-content pt-8 pb-2">
                  <span className="menu-section text-uppercase fs-8 ls-1"
                        style={{ color: whiteLabelProps.contrast_color }}>CRM</span>
          </div>
        </div>
      )}
      <SidebarMenuItem
        to="/crm/relationships"
        icon="diamonds"
        title={t('relationships')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Relationship Management")}
      />


      {/* ADMIN */}
      {user.permissions.hasAccess("Admin") && (
        <div className="menu-item">
          <div className="menu-content pt-8 pb-2">
                  <span className="menu-section text-uppercase fs-8 ls-1"
                        style={{ color: whiteLabelProps.contrast_color }}>{t('admin')}</span>
          </div>
        </div>
      )}
      <SidebarMenuItem
        to="/admin/location-profile"
        icon="menu"
        title={t('location-profile')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Location Space Types Admin") ||
                   user.permissions.hasAccess("Location Buildings Admin") ||
                   user.permissions.hasAccess("Location Contracts Admin") ||
                   user.permissions.hasAccess("Location POC Admin") ||
                   user.permissions.hasAccess("Location Settings Admin")
      }
      />
      <SidebarMenuItem
        to="/admin/space-types"
        icon="abstract-26"
        title={t('global-space-types')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Admin")}
      />
      <SidebarMenuItem
        to="/admin/form-builder"
        icon="element-plus"
        title={t('form-builder')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Form Builder")}
      />
      <SidebarMenuItem
        to="/admin/service-catalog"
        icon="book-open"
        title={t('service-catalog')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Admin")}
      />
      <SidebarMenuItem
        to='/admin/training-catalog'
        icon='book-open'
        title={t('training-catalog')}
        fontIcon='bi-app-indicator'
        hasBullet={false}
        isVisible={user.permissions.hasAccess('Training Catalog')}
      />
      <SidebarMenuItem
        to="/admin/supplies"
        icon="paintbucket"
        title={t('supplies')}
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Admin")}
      />
      {/*<SidebarMenuItem*/}
      {/*  to="/admin/positions"*/}
      {/*  icon="clipboard"*/}
      {/*  title="Positions"*/}
      {/*  fontIcon="bi-app-indicator"*/}
      {/*  hasBullet={false}*/}
      {/*  isVisible={loggedInUser.permissions.hasAccess("Positions")}*/}
      {/*/>*/}
      {/*<SidebarMenuItem*/}
      {/*  to="/crafted/pages/profile/connections"*/}
      {/*  icon="lock-2"*/}
      {/*  title="Permissions"*/}
      {/*  fontIcon="bi-app-indicator"*/}
      {/*  hasBullet={false}*/}
      {/*  isVisible={loggedInUser.permissions.hasAccess("Permissions")}*/}
      {/*/>*/}
      {/*<SidebarMenuItem*/}
      {/*  to='/facilities/floor-plans'*/}
      {/*  icon='user-tick'*/}
      {/*  title='Shifts'*/}
      {/*  fontIcon='bi-app-indicator'*/}
      {/*  hasBullet={false}*/}
      {/*  isVisible={loggedInUser.permissions.hasAccess('Shifts')}*/}
      {/*/>*/}
      {/*<SidebarMenuItem*/}
      {/*  to='/facilities/floor-plans'*/}
      {/*  icon='people'*/}
      {/*  title='Teams'*/}
      {/*  fontIcon='bi-app-indicator'*/}
      {/*  hasBullet={false}*/}
      {/*  isVisible={loggedInUser.permissions.hasAccess('Teams')}*/}
      {/*/>*/}
      {/*<SidebarMenuItem*/}
      {/*  to="/facilities/floor-plans"*/}
      {/*  icon="element-10"*/}
      {/*  title="Floor Plans"*/}
      {/*  fontIcon="bi-app-indicator"*/}
      {/*  hasBullet={false}*/}
      {/*  isVisible={loggedInUser.permissions.hasAccess("Floor Plans")}*/}
      {/*/>*/}


      {user.permissions.hasAccess("Development") && (
        <div className="menu-item">
          <div className="menu-content pt-8 pb-2">
                  <span className="menu-section text-uppercase fs-8 ls-1"
                        style={{ color: whiteLabelProps.contrast_color }}>Developer</span>
          </div>
        </div>
      )}
        <SidebarMenuItem
          to="/developer/traces"
          icon="icon"
          title={t('traces')}
          fontIcon="bi-app-indicator"
          hasBullet={false}
          isVisible={user.permissions.hasAccess("Development")}
        />
      <SidebarMenuItemWithSub
        to="/projects"
        icon="chart-pie-3"
        title='Projects'
        fontIcon="bi-app-indicator"
        hasBullet={false}
        isVisible={user.permissions.hasAccess("Development")}
      >
        <SidebarMenuItem
          to="/projects/dashboard"
          icon="tablet-text-down"
          title="Dashboard"
          fontIcon="bi-app-indicator"
          hasBullet={false}
          isVisible={user.permissions.hasAccess("Development")}
        />
        <SidebarMenuItem
          to="/projects/manage"
          icon="tablet-text-down"
          title="Manage"
          fontIcon="bi-app-indicator"
          hasBullet={false}
          isVisible={user.permissions.hasAccess("Development")}
        />
      </SidebarMenuItemWithSub>
        <SidebarMenuItem
          to='/developer/icons'
          icon='abstract-29'
          title='KPI Icon Help'
          fontIcon='bi-app-indicator'
          hasBullet={false}
          isVisible={user.permissions.hasAccess('Development')}
        />

    </>
  )
}

export { SidebarMenuMain };


/*
SUB MENU EXAMPLE

<SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>




 */