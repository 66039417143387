import React, { useEffect, useState } from "react";
import {
  Grid, Typography, Card, CardContent, useTheme, useMediaQuery
} from "@mui/material";
import { SiteHealthService } from "../../../../../../../../../shared/services/site-health/siteHealthService";
import SageScoreSummary from "./components/SageScoreSummary";
import IconButton from "@mui/material/IconButton";
import SageScoreModal from "./components/SageScoreModal";
import CustomLoadingDots from "../../../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import SageScoreSlider
  from "../../../../../../../../dashboard/site-manager-dashboard/components/sage-analytics/SageScoreSlider";
import useAppStore from "../../../../../../../../../appStore";
import { SiteHealthModel } from "../../../../../../../../../shared/models/siteHealth.model";

const SiteHealthCard = ({ location }) => {
  const failCount = location.quality_count + location.compliance_count;
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const service = new SiteHealthService();
  const [details, setDetails] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [healthData, setHealthData] = useState<SiteHealthModel>(new SiteHealthModel());


  useEffect(() => {
     fetchDetails().then()
  }, []);

  useEffect(() => {
    setIsLoading(true);
    service.getHealthData(user.organization?.id, location.id)
      .then(data => {
        setHealthData(data[0]);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [location.id, user.organization?.id]);


  const fetchDetails = async () => {
    try {
      setIsLoading(true)
      const service = new SiteHealthService();
      const items = await service.getHealthDetails(location.id);
      setDetails(items);
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      }
    } finally {
      setIsLoading(false)
    }
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = (action) => {
    setOpen(false);
  }

  if (isLoading) {
    return <CustomLoadingDots />;
  }

  return (
    <Card variant="outlined" sx={{marginBottom: '10px', boxShadow: '0 1px 2px rgba(0,0,0,0.1)', width: '100%'}}>
      <CardContent sx={{margin: '5px'}}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Typography variant="h6" component="div" sx={{marginBottom: '15px', marginTop: '5px', fontWeight: 'bold'}}>
                {location.name}
              </Typography>
              <IconButton onClick={handleOpenModal} sx={{ alignSelf: 'flex-start' }}>
                <i className="fas fa-eye fs-1 me-1"></i>
              </IconButton>
            </Grid>
            <SageScoreSlider score={healthData.health_score} />
          </Grid>
        </Grid>
        <SageScoreSummary failingCount={failCount} details={details}/>
      </CardContent>
      {open && (
        <SageScoreModal
          open={open}
          healthData={healthData}
          failingCount={failCount}
          details={details}
          onActionClicked={handleCloseModal}
        />
      )}
    </Card>
  );
}

export default SiteHealthCard;
