// serializers/errorSerializer.ts
import { BaseSerializer } from "./baseSerializer";
import { SageError } from "../sageError.model";

export class ErrorSerializer extends BaseSerializer {
  public deserialize(error: any): SageError {
    let status = 500;
    let message = "An unknown error occurred";

    if (error.response) {
      status = error.response.data.status;
      message = error.response.data.message;
    }

    return new SageError(status, message);
  }
}

