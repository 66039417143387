import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import useAppStore from "../../../../../../appStore";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import { WorkItemsService } from "../../../../../../shared/services/work-items/workItemsService";
import SubHeader from "../../../../../../shared/components/page/headers/SubHeader";
import EditWorkItem from "../../../../../work-items/components/edit-work-item/EditWorkItem";
import { WorkItem } from "../../../../../../shared/models/workItem.model";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { workItemStore } from "../../../../../work-items/workItemStore";
import WorkItemCard
  from "../../../../../work-items/components/work-item-list/views/card-view/WorkItemCard";
import CloseWorkItemModal from "../../../user-work-items/components/components/CloseWorkItemModal";
import EmployeeQuickSelect from "../../../../../../shared/components/forms/widgets/selection-forms/EmployeeQuickSelect";
import { AnalyticsService } from "../../../../../../shared/services/analytics/analyticsService";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { suggestionsStore } from "../../suggestionsStore";

const SuggestedWorkItemsList = () => {

  const {selectedLocation} = useAppStore();
  const workItemSnap = useSnapshot(workItemStore);
  const suggestionSnap = useSnapshot(suggestionsStore);
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { masterRefresh } = useAppStore();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const workItemsService = new WorkItemsService();
  const suggestionService = new AnalyticsService();

  useEffect(() => {
    const fetchItems = async () => {
      workItemStore.workItems = [];
      try {
        workItemStore.isLoading = true;
        const [items] = await Promise.all([suggestionService.getSuggestionItems(user.organization?.id, selectedLocation.id, suggestionSnap.selectedSuggestion.type)]);
        workItemStore.workItems = [...workItemStore.workItems, ...items];
      } catch (error) {
        workItemStore.error = error instanceof Error ? error.message : String(error)
      } finally {
        workItemStore.isLoading = false;
      }
    };

    if (selectedLocation.id !== 0) {
      fetchItems().then();
    }

  }, [workItemSnap.activeStep, masterRefresh, selectedLocation]);


  const handleCloseDialog = () => {
    workItemStore.assignModalOpen = false;
    workItemStore.workItemToEdit = new WorkItem();
  };

  const handleSaveEmployees = async (workItem, employees) => {
    workItem.assigned_to_employees = employees;
    try {
      await workItemsService.updateWorkItem(workItem);
      handleCloseDialog();
    } catch (e) {
      console.error('Error in handleSaveEmployees:', e);
    }
  };

  const handleCloseModals = (action) => {
    workItemStore.isEditing = false;
    workItemStore.open = false;
    workItemStore.closingDialogOpen = false;
    workItemStore.workItemToEdit = new WorkItem();
    if (action === 'save') {
      workItemStore.snackbarOpen = true;
    }
  }

  return (
    <div>

      {error && <CustomError error={error} />}

      {!error && (
        <>
          <div>
            <div>
              <div style={{ marginTop: "25px" }}>
                <SubHeader title={t("work-items")} color="#777" margin={false} showAction={false} />
                <Divider sx={{ bgcolor: "black", marginBottom: "10px" }} />
                {workItemSnap.isLoading && (
                  <div style={{ minHeight: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomLoadingDots />
                  </div>
                )}

                {workItemStore.workItems.map((workItem, index) => (
                  <WorkItemCard
                    key={index}
                    item={workItem}
                    useSmallVersion={true}
                  />
                ))}

              </div>

            </div>
          </div>
        </>
      )}

      {workItemSnap.open && workItemSnap.workItemToEdit &&
        <EditWorkItem
          open={workItemSnap.open}
          onActionClicked={handleCloseModals}
        />
      }
      {workItemSnap.closingDialogOpen && workItemSnap.workItemToEdit &&
        <CloseWorkItemModal
          open={workItemSnap.closingDialogOpen}
          onActionClicked={handleCloseModals}
        />
      }
      {workItemSnap.assignModalOpen && workItemSnap.workItemToEdit && (
        <EmployeeQuickSelect open={workItemSnap.assignModalOpen} onClose={handleCloseDialog}
                             handleSave={handleSaveEmployees} item={workItemStore.workItemToEdit}
                             showSelectionBox={false} />
      )}
    </div>
  );
};

export default SuggestedWorkItemsList;