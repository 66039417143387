import React, { useState } from "react";
import { Button, ButtonGroup, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import useAppStore from "../../../../../../appStore";

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  borderRadius: 4, // Adjust as needed
}));

const SaveButton = styled(Button)(({ theme }) => ({
  height: "40px",
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
}));

const DropdownButton = styled(IconButton)(({ theme }) => ({
  height: "40px",
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: 'none',
  padding: '0 8px',
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '10px',
  paddingRight: '10px',
  marginBottom: '20px', // Adjust as needed
}));

const ProjectButtons: React.FC<{ handleCancel: () => void; handleSave: () => void; handleDeleteProject: () => void; }> = ({ handleCancel, handleSave, handleDeleteProject }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {whiteLabelProps} = useAppStore();

  const handleDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Prevent triggering parent click events
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    handleDeleteProject();
    handleMenuClose();
  };

  return (
    <ButtonContainer>
      <Button
        variant="contained"
        onClick={handleCancel}
        sx={{
          height: "40px",
          backgroundColor: "#e8e8e8",
          color: "black",
          textTransform: 'none',
        }}
      >
        CANCEL
      </Button>
      <StyledButtonGroup variant="contained">
        <SaveButton
          onClick={handleSave}
          sx={{
            backgroundColor: whiteLabelProps.primary_color,
            color: "#fff",
            textTransform: 'none',
            '&:hover': {
              backgroundColor: whiteLabelProps.primary_color_light,
            },
          }}
        >
          SAVE PROJECT
        </SaveButton>
        <Tooltip title="More Options">
          <DropdownButton
            onClick={handleDropdownClick}
            sx={{
              backgroundColor: whiteLabelProps.primary_color,
              color: "#fff",
              textTransform: 'none',
              '&:hover': {
                backgroundColor: whiteLabelProps.primary_color_light,
              },
            }}
            aria-controls={anchorEl ? 'save-button-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? 'true' : undefined}
          >
            <ArrowDropDownIcon />
          </DropdownButton>
        </Tooltip>
      </StyledButtonGroup>
      <Menu
        id="save-button-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'split-button',
        }}
      >
        <MenuItem onClick={handleDeleteClick}>Delete Project</MenuItem>
      </Menu>
    </ButtonContainer>
  );
};

export default ProjectButtons;
