import React from 'react';
import { Box, Typography } from '@mui/material';
import { suggestionsStore } from "../suggestionsStore";
import ActionButton from "../../../../../shared/components/buttons/ActionButton";

const SuggestionRow = ({ suggestion }) => {

  const handleViewClicked = () => {
    suggestionsStore.selectedSuggestion = suggestion;
    suggestionsStore.open = true;
  }


  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        maxHeight: '40px',
      }}
    >
      <ActionButton
        iconType="view"
        onClick={(e) => {
          e.preventDefault();
          handleViewClicked();
        }}
      />
      <Typography
        variant="h6"
        component="div"
        noWrap
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '1.1rem',
          textDecoration: 'none',
          color: 'inherit',
          ml: 1,
        }}
      >
        {suggestion.name}: <strong>{suggestion.count} remaining</strong>
      </Typography>
    </Box>
  );
};

export default SuggestionRow;


