import { proxy } from 'valtio';
import { ProjectPlan } from "../../shared/models/projetPlan.model";
import { Project } from "../../shared/models/project.model";


interface ProjectStore {
  projects: Project[];
  selectedProject: Project | null;
  selectedPlan: ProjectPlan | null;
  open: boolean;
  planOpen: boolean;
  isEditing: boolean;
  mode: string;
  refreshProjects: boolean;
  deleteOpen: boolean;
}

export const projectStore = proxy<ProjectStore>({
  projects: [],
  selectedProject: null,
  selectedPlan: null,
  open: false,
  planOpen: false,
  isEditing: false,
  mode: 'Rank',
  refreshProjects: false,
  deleteOpen: false,
});
