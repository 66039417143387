import React from 'react';
import CaseRow from './CaseRow';
import CaseCard from '../mobile/CaseCard';
import ReusableTable from "../../../../../../shared/components/page/tables/ReusableTable";

const CaseTable = ({ cases }) => {

  const headers = [
    { label: 'CASE', className: 'ps-5 min-w-225px text-start' },
    { label: 'SUBMITTED', className: 'min-w-75px text-start px-3' },
    { label: 'INVOLVING', className: 'min-w-150px text-start px-3' },
    { label: 'ASSIGNED TO', className: 'min-w-150px text-start px-3' },
    { label: '', className: 'min-w-20px text-center' },
  ];

  return (
    <ReusableTable
      items={cases}
      headers={headers}
      RowComponent={(props) => <CaseRow {...props} />}
      CardComponent={(props) => <CaseCard {...props} />}
    />
  );
};

export default CaseTable;




