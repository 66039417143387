import React from 'react';
import { Typography, Button } from '@mui/material';
import { workItemStore } from "../../../../../../work-items/workItemStore";
import { WorkItem } from "../../../../../../../shared/models/workItem.model";
import CaseWorkItem from "./CaseWorkItem";
import casesStore from "../../../../../caseStore";
import BuildIcon from "@mui/icons-material/Build";
import { PermissionLevels } from "../../../../../../../shared/models/userPermissions.model";
import useAppStore from "../../../../../../../appStore";

function CaseWorkItemCard({ caseData, editing }) {

  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const canEdit = user?.permissions.hasAccess("Cases", PermissionLevels.UPDATE);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewWorkItem = () => {
    if (caseData.work_item_id !== 0) {
      let workItem = new WorkItem();
      workItem.id = caseData.work_item_id;
      workItemStore.workItemToEdit = workItem;
      setOpen(true);
    }
  }

  const handleCreateWorkItem = () => {
    workItemStore.workItemToEdit = new WorkItem();
    setOpen(true);
  }

  if (!canEdit) return null;

  return (
    <div className="mt-3 mb-5 flex-column" style={{ width: '320px' }}>
      {editing ? (
        <span className="text-gray-700">Work Item</span>
      ) : (
        <Typography
          variant="subtitle1"
          className="text-gray-400 fw-semibold d-block fs-7"
        >
          Work Item:
        </Typography>
      )}


      <div className="card" style={{ width: '100%' }}>
        <div className="card-body d-flex flex-center flex-column p-4">
          {caseData.has_work_items ? (
            <>
              <div className="d-flex align-items-center mb-3">
                <BuildIcon sx={{ color: "gray", fontSize: '20px', cursor: 'pointer' }} />
                <Typography variant="body1" className="ms-2">
                  Status: {caseData.work_item_status}
                </Typography>
              </div>
              <Button
                onClick={handleViewWorkItem}
              >
                View Work Item
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={handleCreateWorkItem}
              >
                Create Work Item
              </Button>
            </>
          )}
        </div>
      </div>
      {open &&
        <CaseWorkItem open={open} onActionClicked={handleClose} selectedCase={casesStore.caseToEdit} />
      }
    </div>
  );
}

export default CaseWorkItemCard;
