import React from 'react';
import { IconButton, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from "@mui/material";
import { Print, ViewList } from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { useSnapshot } from "valtio/index";
import { workItemStore } from "../../../modules/work-items/workItemStore";
import { WorkItem } from "../../models/workItem.model";

const WorkItemListToolbar = () => {
  const snap = useSnapshot(workItemStore);
  const isMobile = useMediaQuery(useTheme().breakpoints.only("xs"));

  const handleSwitchView = (event, newView) => {
    if (newView === false) {
      workItemStore.tableView = false
    } else {
      workItemStore.tableView = true;
    }
  };

  const handleOpenHistory = () => {
    workItemStore.historyOpen = true;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: snap.historyOpen ? 'end' : '' }}>
      {!snap.historyOpen &&
        <>
          <IconButton onClick={handleOpenHistory}>
            <HistoryIcon />
          </IconButton>
          {!isMobile &&
            <IconButton onClick={() => workItemStore.printModalOpen = true}>
              <Print />
            </IconButton>
          }
        </>
      }
      {!isMobile &&
        <ToggleButtonGroup
          value={snap.tableView}
          exclusive
          onChange={handleSwitchView}
          aria-label="view switcher"
        >
          <ToggleButton value={true} aria-label="list view">
            <ViewList />
          </ToggleButton>
          <ToggleButton value={false} aria-label="card view">
            <ViewModuleIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      }
    </div>
  );
};

export default WorkItemListToolbar;

