import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import FundRaisersPage from "../modules/fund-raising/FundRaisersPage";
import WorkItemsPage from "../modules/work-items/WorkItemsPage";
import DepartmentCasesPage from '../modules/cases/DepartmentCasesPage'
import InspectionsPage from '../modules/inspections/InspectionsPage'
import SurveysPage from '../modules/surveys/SurveysPage'
import IconReference from '../modules/development/IconReference'
import ServiceCatalogPage from '../modules/service-catalog/ServiceCatalogPage'
import TrainingCatalogPage from '../modules/training/training-catalog/TrainingCatalogPage'
import EmployeesPage from '../modules/employees/EmployeesPage'
import DashboardPage from "../modules/dashboard/DashboardPage";
import MobileLauncherPage from "../pages/mobile-launcher/MobileLauncherPage";
import SiteHealthPage from "../modules/analytics/components/site-health/SiteHealthPage";
import CustomerSurveyPage from "../modules/analytics/components/surveys/customer/CustomerSurveyPage";
import EmployeeSurveyPage from "../modules/analytics/components/surveys/employee/EmployeeSurveyPage";
import EquipmentPage from "../modules/equipment/EquipmentPage";
import SuppliesPage from "../modules/supplies/SuppliesPage";
import PositionsPage from "../modules/positions/PositionsPage";
import InspectionAnalytics from "../modules/analytics/components/inspections/InspectionsAnalytics";
import PermissionsPage from "../modules/permissions/PermissionsPage";
import OrgChartPage from "../modules/org-chart/OrgChartPage";
import SafetyPage from '../modules/safety/SafetyPage'
import SpaceTypesPage from "../modules/space-types/SpaceTypesPage";
import LocationProfilePage from "../modules/location-profile/LocationProfilePage";
import SafetyDataSheets from "../modules/safety/components/SafetyDataSheets";
import LocationBuildings from "../modules/location-profile/components/spaces/LocationBuildings";
import LocationSummaryPage from "../modules/location-summary/LocationSummaryPage";
import RelationshipPage from "../modules/relationships/RelationshipPage";
import LocationCasesPage from "../modules/cases/LocationCasesPage";
import TrainingReadOnlyPage from "../modules/training/training-read-only/TrainingReadOnlyPage";
import TraceThreadLog from "../modules/trace-thread-log/TraceThreadLog";
import ProjectsDashboardPage from "../modules/projects/dashboard/ProjectsDashboardPage";
import ProjectsManagePage from "../modules/projects/manage/ProjectsManagePage";
import FormBuilder from "../modules/location-profile/components/form-builder/FormBuilder";

const PrivateRoutes = () => {
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const FeedPage = lazy( () => import('../modules/feed/FeedPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to this route after success login or registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardPage />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='launcher' element={<MobileLauncherPage />} />


        {/* Lazy Modules */}
        <Route
          path='inspection-analytics/*'
          element={
            <SuspensedView>
              <InspectionAnalytics />
            </SuspensedView>
          }
        />
        <Route
          path='site-health/*'
          element={
            <SuspensedView>
              <SiteHealthPage />
            </SuspensedView>
          }
        />
        <Route
          path='customer-survey-analytics/*'
          element={
            <SuspensedView>
              <CustomerSurveyPage />
            </SuspensedView>
          }
        />
        <Route
          path='employee-survey-analytics/*'
          element={
            <SuspensedView>
              <EmployeeSurveyPage />
            </SuspensedView>
          }
        />
        <Route
          path='social/feed/*'
          element={
              <SuspensedView>
                  <FeedPage />
              </SuspensedView>
          }
        />
        <Route
          path='fund-raising/fundraisers/*'
          element={
              <SuspensedView>
                  <FundRaisersPage />
              </SuspensedView>
          }
        />
        <Route
          path='facilities/cases/*'
          element={
              <SuspensedView>
                  <DepartmentCasesPage />
              </SuspensedView>
          }
        />
        <Route
          path='facilities/department-cases/*'
          element={
            <SuspensedView>
              <DepartmentCasesPage />
            </SuspensedView>
          }
        />
        <Route
          path='facilities/location-cases/*'
          element={
            <SuspensedView>
              <LocationCasesPage />
            </SuspensedView>
          }
        />
        <Route
          path='facilities/work-items/*'
          element={
              <SuspensedView>
                  <WorkItemsPage />
              </SuspensedView>
          }
        />
        <Route
          path='projects/manage/*'
          element={
              <SuspensedView>
                  <ProjectsManagePage />
              </SuspensedView>
          }
        />
        <Route
          path='projects/dashboard/*'
          element={
            <SuspensedView>
              <ProjectsDashboardPage />
            </SuspensedView>
          }
        />
        <Route
          path='facilities/inspections/*'
          element={
              <SuspensedView>
                  <InspectionsPage />
              </SuspensedView>
          }
        />
        <Route
          path='facilities/surveys/*'
          element={
              <SuspensedView>
                  <SurveysPage />
              </SuspensedView>
          }
        />
        <Route
          path='team/employees/*'
          element={
              <SuspensedView>
                  <EmployeesPage />
              </SuspensedView>
          }
        />
        <Route
          path='team/training/*'
          element={
            <SuspensedView>
              <TrainingReadOnlyPage />
            </SuspensedView>
          }
        />
        <Route
          path='team/org-chart/*'
          element={
            <SuspensedView>
              <OrgChartPage />
            </SuspensedView>
          }
        />
        <Route
          path='ehs/home/*'
          element={
            <SuspensedView>
              <SafetyPage />
            </SuspensedView>
          }
        />
        <Route
          path='ehs/sds/*'
          element={
            <SuspensedView>
              <SafetyDataSheets />
            </SuspensedView>
          }
        />
        <Route
          path='developer/icons/*'
          element={
              <SuspensedView>
                  <IconReference />
              </SuspensedView>
          }
        />
        <Route
          path='crm/relationships/*'
          element={
            <SuspensedView>
              <RelationshipPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/service-catalog/*'
          element={
              <SuspensedView>
                  <ServiceCatalogPage />
              </SuspensedView>
          }
        />
        <Route
          path='admin/location-profile/*'
          element={
            <SuspensedView>
              <LocationProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='location-summary/*'
          element={
            <SuspensedView>
              <LocationSummaryPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/training-catalog/*'
          element={
              <SuspensedView>
                  <TrainingCatalogPage />
              </SuspensedView>
          }
        />
        <Route
          path='admin/positions/*'
          element={
            <SuspensedView>
              <PositionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/space-types/*'
          element={
            <SuspensedView>
              <SpaceTypesPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/form-builder/*'
          element={
            <SuspensedView>
              <FormBuilder />
            </SuspensedView>
          }
        />
        <Route
          path='admin/spaces/*'
          element={
            <SuspensedView>
              <LocationBuildings />
            </SuspensedView>
          }
        />
        <Route
          path='admin/equipment/*'
          element={
            <SuspensedView>
              <EquipmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/supplies/*'
          element={
            <SuspensedView>
              <SuppliesPage />
            </SuspensedView>
          }
        />
        <Route
          path='developer/traces/*'
          element={
            <SuspensedView>
              <TraceThreadLog />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <PermissionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const PublicRoutes = () => (
  <Routes>
    <Route path="ehs/home/*" element={<SafetyPage />} />
  </Routes>
);

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes, PublicRoutes };