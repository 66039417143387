import React, {useEffect, useState} from 'react'
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import InspectionPointRow from "./InspectionPointRow";
import CustomLoadingDots from "../../../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { useTranslation } from "react-i18next";
import { inspectionsStore } from "../../../../../../../inspectionsStore";
import { useSnapshot } from "valtio";
import { inspectedEntityStore } from "../../../../../../../inspectedEntityStore";


const InspectionPoints = () => {
  const inspectedEntitySnap = useSnapshot(inspectedEntityStore);
  const {t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false);


  const handleScoreChange = (id, newScore) => {
    const updated = inspectedEntityStore.serviceItemScores.map(item => {
      if (item.id === id) {
        return { ...item, score: newScore, is_wow: false };
      }
      return item;
    });

    inspectedEntityStore.serviceItemScores = updated
    inspectedEntityStore.inspectedEntity.service_item_scores = updated;
  };

  const handleWowChange = (id, isWow) => {
    const updated = inspectedEntityStore.serviceItemScores.map(item => {
      if (item.id === id) {
        return { ...item, is_wow: isWow };
      }
      return item;
    });

    inspectedEntityStore.serviceItemScores = updated;
    inspectedEntityStore.inspectedEntity.service_item_scores = updated;

  };

  const handleReasonChange = (id, reasons) => {
    const updated = inspectedEntityStore.serviceItemScores.map(item => {
      if (item.id === id) {
        return { ...item, inspection_points: reasons };
      }
      return item;
    });

    inspectedEntityStore.serviceItemScores = updated;
    inspectedEntityStore.inspectedEntity.service_item_scores = updated;
  };


  if (isLoading) {
    return <CustomLoadingDots />
  }

  return (
    <div className="d-flex flex-column">
      {inspectedEntitySnap.inspectedEntity.service_item_scores.length == 0 && (
        <div>
          No details found for this space.
        </div>
      )}
      {inspectedEntitySnap.inspectedEntity.service_item_scores.length > 0 && (
        <>
          <div className="d-flex flex-row" style={{ width: "100%" }}>
            <div style={{ width: "35%", alignItems: "center", padding: "2px", textAlign: "center" }} />
            <div style={{ width: "20%", alignItems: "center", padding: "2px", textAlign: "center" }}>
              <label><b>{t('miss')}</b></label>
            </div>
            <div style={{ width: "20%", alignItems: "center", padding: "2px", textAlign: "center" }}>
              <label><b>{t('meets')}</b></label>
            </div>
            <div className="d-flex justify-content-end pe-6" style={{ width: "25%", alignItems: "center", padding: "2px", textAlign: "center" }}>
              <label><b>{t('wow')}</b></label>
            </div>
          </div>
        </>
      )}
      {inspectedEntityStore.serviceItemScores.map((serviceItem) => (

        <InspectionPointRow key={serviceItem.id}
                            serviceItem={serviceItem}
                            handleScoreChange={handleScoreChange}
                            handleWowChange={handleWowChange}
                            handleReasonChange={handleReasonChange}
            />
          ))}

        </div>
  )
};

export default InspectionPoints;