import React from "react";
import useAppStore from "../../appStore";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import TraceList from "./components/TraceList";
import TraceDetails from "./components/TraceDetails";
import traceStore from "./traceStore";
import PageHeader from "../../shared/components/page/headers/PageHeader";
import { Tab, Tabs } from "@mui/material";
import TraceAnalytics from "./components/TraceAnalytics";

const TraceThreadLog = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { t } = useTranslation();
  const snap = useSnapshot(traceStore);
  const [tab, setTab] = React.useState<number>(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <PageHeader title='Traces'
                  showAction={false} />

      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label={'Requests'} value={0} />
        <Tab label={'Analytics'} value={1} />
      </Tabs>

      {tab === 0 ? (
          <TraceList />
        ) : (
          <TraceAnalytics />
      )}


      {snap.open && (
        <TraceDetails
          open={snap.open}
          onClose={traceStore.closeDialog}
        />
      )}
    </>
  );
};

export default TraceThreadLog;

