import { RelationshipContact } from "../relationshipContact.model";
import { BaseSerializer } from "./baseSerializer";
import { PersonSerializer } from "./personSerializer";

export class RelationshipContactSerializer extends BaseSerializer {

  public deserialize(data): RelationshipContact {
    const contact = new RelationshipContact();
    this._copyAttributes(contact, data);

    if (data && data.person) {
      let personSerializer = new PersonSerializer();
      contact.person = personSerializer.deserialize(data.person);
    }

    return contact;
  }

}