import useAppStore from "../../../../../../appStore";
import React, { useState } from "react";
import { Box } from "@mui/material";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";

const FormRow = (props) => {
  const { form, onEdit, onDelete } = props;

  const { whiteLabelProps, toggleMasterRefresh } = useAppStore();
  const [deleting, setDeleting] = useState(false);

  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <Box
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: whiteLabelProps?.primary_color || "gray",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "1.2rem",
                borderRadius: "4px",
                marginRight: "10px",
                padding: "1px",
              }}
            >
              {form?.name?.charAt(0)} {/* Display the first letter of the form name */}
            </Box>
            <div className="d-flex justify-content-start flex-column">
              <a className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                {form?.name}
              </a>
              <span className="text-gray-400 fw-semibold d-block fs-7">
                {form?.description}
              </span>
            </div>
          </div>
        </td>

        <td>
          <span className="text-gray-800 fw-bold d-block">
            {form?.caseType}
          </span>
        </td>

        <td>
          <span className="text-gray-800 fw-bold d-block">
            {form?.fieldCount}
          </span>
        </td>

        <td>
          <div className="d-flex justify-content-end">
            <ActionButton
              iconType="pencil"
              onClick={(e) => {
                e.preventDefault();
                onEdit(form);
              }}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

export default FormRow;
