import React from "react";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import SelectableTilesGrid from "./components/selectable-tiles/SelectableTilesGrid";
import { useEntityValidateAndSave, ValidationResult } from "../../useEntityValidateAndSave";
import CustomLoadingDots from "../../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../../../shared/components/page/popup-dialog/CustomError";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { inspectionsStore } from "../../../../../../inspectionsStore";
import { inspectedEntityStore } from "../../../../../../inspectedEntityStore";

const SpaceSelection = () => {

    const inspectedEntitySnap = useSnapshot(inspectedEntityStore);
    const {t} = useTranslation();
    const {biRef } = useEntityValidateAndSave();

    const handleBuildingChanged = (event) => {
        const selectedBuildingName = event.target.value;
        const building = inspectedEntityStore.buildings.find(bldg => bldg.name === selectedBuildingName) || null;
        inspectedEntityStore.selectedFloor = null;
        inspectedEntityStore.selectedBuilding = building;
        inspectionsStore.inspectionBuilding = building;
    };

    const handleEntityTypeChanged = (event, spaceType) => {
        inspectedEntityStore.inspectedEntity.entity_type = spaceType;
    };

    const handleSpaceNumberChanged = (event) => {
        inspectedEntityStore.inspectedEntity.space_number = event.target.value;
    };


    biRef.selectionPage.validate = () => {

        let valInfo: ValidationResult = { passed: true, messages: [] }

        if (inspectedEntityStore.selectedBuilding === null) {
            valInfo.passed = false
            valInfo.messages.push('Building is required.')
        }

        if (inspectedEntityStore.inspectedEntity.entity === null || inspectedEntityStore.inspectedEntity.entity.id === 0) {
            valInfo.passed = false
            valInfo.messages.push('Floor is required.')
        }

        return valInfo
    };

    if (inspectedEntitySnap.buildingsLoading) {
        return <CustomLoadingDots />;
    }
    if (inspectedEntitySnap.fetchError) {
        return <CustomError error={inspectedEntitySnap.fetchError} />;
    }

    return (

      <div className="d-flex flex-column flex-row-fluid" style={{paddingBottom:"150px"}}>
          <h1>{t('select-space')}</h1>
              <>
              <div className='mt-10'>
                  <h3>{t('what-building')}</h3>
                  <FormControl style={{ width: '100%' }} margin="normal">
                      <Select
                        value={inspectedEntitySnap.selectedBuilding ? inspectedEntitySnap.selectedBuilding.name : ''}
                        onChange={handleBuildingChanged}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    width: '80%',
                                    height: '70%',
                                },
                            },
                        }}
                      >
                          {inspectedEntityStore.buildings.map((bldg) => (
                            <MenuItem key={bldg.id} value={bldg.name}>
                                {bldg.name}
                            </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </div>
                  {inspectedEntitySnap.floors.length > 0 && (
                    <div className="mt-10">
                        <h3>{t('what-floor')}</h3>
                        {inspectedEntitySnap.floorsLoading ? (
                          <div style={{ marginTop: "15px" }}>
                              <CustomLoadingDots />
                          </div>
                        ) : (
                          <>
                              {inspectedEntitySnap.selectedBuilding !== null ? (
                                <SelectableTilesGrid disabled={inspectedEntitySnap.editing}
                                                     options={inspectedEntityStore.floors}
                                                     value={inspectedEntitySnap.selectedFloor}
                                                     onChange={(event, selectedEntity) => {
                                                         if (selectedEntity) {
                                                             inspectedEntityStore.selectedFloor = selectedEntity;
                                                             inspectedEntityStore.inspectedEntity.entity = selectedEntity;
                                                         }
                                                     }}
                                                     sageModal={true}
                                />
                              ) : (
                                <p>{t("choose-building")}</p>
                              )}
                          </>

                        )}
                    </div>
                  )}

                    <>
                        <div className="mt-10" style={{ width: "100%" }}>
                            <h3>{t('select-space-type')}</h3>
                            {inspectedEntitySnap.typesLoading ? (
                              <div style={{ marginTop: "15px" }}>
                                  <CustomLoadingDots />
                              </div>
                            ) : (
                              <>
                                  {inspectedEntitySnap.selectedFloor !== null ? (
                                    <SelectableTilesGrid disabled={inspectedEntitySnap.editing}
                                                         options={inspectedEntityStore.types}
                                                         value={inspectedEntitySnap.inspectedEntity.entity_type}
                                                         onChange={handleEntityTypeChanged}
                                                         sageModal={true}
                                    />
                                  ) : (
                                    <p>{t("choose-floor")}</p>
                                  )}
                              </>

                            )}
                        </div>
                        {inspectedEntitySnap.selectedFloor !== null && (
                          <div className="mt-10">
                              <h3>{t('room-number')}</h3>
                              <TextField placeholder=''
                                         variant="outlined"
                                         onChange={handleSpaceNumberChanged}
                                         value={inspectedEntitySnap.inspectedEntity.space_number}
                              />
                          </div>
                        )}
                    </>
              </>
      </div>
    )};

export default SpaceSelection;