import React, { useRef, useState, useEffect } from "react";
import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  inspectionButtonContainerStyle,
  tellSageContainerStyles
} from "../../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import Box from "@mui/material/Box";
import InspectionSteps from "./InspectionSteps";
import { useEntityValidateAndSave } from "../../edit-inspected-entity/useEntityValidateAndSave";
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { inspectionsStore } from "../../../../../inspectionsStore";
import { inspectedEntityStore } from "../../../../../inspectedEntityStore";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { workItemStore } from "../../../../../../work-items/workItemStore";

const InspectTool = ({ onClose, step, setStep }) => {
  const inspectedEntitySnap = useSnapshot(inspectedEntityStore)
  const { handleSaveInspectedEntity, handleSaveAndPrepWorkItem } = useEntityValidateAndSave();
  const {t} = useTranslation();
  const [workItemStatus, setWorkItemStatus] = useState(0);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const modalRef = useRef<HTMLDivElement>(null);
  const spaceValid = (inspectedEntitySnap.selectedBuilding !== null && inspectedEntitySnap.selectedFloor !== null && inspectedEntitySnap.inspectedEntity.entity !== null && inspectedEntitySnap.inspectedEntity.entity.id !== 0 && inspectedEntitySnap.selectedFloor?.id !== 0 && inspectedEntitySnap.selectedBuilding?.id !== 0);
  const [error, setError] = useState<string>("");
  const modalContentRef = useRef<HTMLDivElement>(null);


  const handleSubmit = (restart: boolean) => {
    handleSaveInspectedEntity(restart).then();
    setStep(0);
    if (!restart) {
      onClose();
    }
  }

  const handleRestart = () => {
    inspectedEntityStore.handleResetInspectedEntityDetails();
    setStep(0);
  }

  const handleNext = () => {
    let validationError = '';

    if (step === 0) {
      if (!spaceValid) {
        validationError = `${t('building-and-floor-required')}`;
      }
    } else if (step === 1) {
      if (inspectedEntitySnap.inspectedEntity.score <= 0) {
        validationError = `${t('score-required')}`;
      }
    }

    if (validationError) {
      setError(validationError);
      return;
    } else {
      setError('');
    }

    setStep(step + 1);

  };

  const handleWorkItemAction = (action) => {
    if (action === "cancel") {
      setStep(4);
      setWorkItemStatus(0);
    } else if (action === "save") {
      setStep(4);
      setWorkItemStatus(1);
    }
  };

  const handleCreateWorkItem = () => {
    setStep(3);
    handleSaveAndPrepWorkItem().then()
  };

  useEffect(() => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [step]);


  return (
    <div style={tellSageContainerStyles(isMobile, false)} ref={modalRef}>
      <div  style={{ overflowY: 'auto', flex: 1 }}>
        <Box ref={modalContentRef} sx={{ justifyContent: 'center', marginTop: '10px', marginBottom: '100px', padding: '10px' }}>
          <InspectionSteps step={step} workItemStatus={workItemStatus}
                           handleWorkItemAction={handleWorkItemAction} />
        </Box>
      </div>
      {step < 2 &&
        <div style={inspectionButtonContainerStyle(isMobile)}>
          {error && (
            <div style={{ display: "flex", color: "red", marginBottom: "10px", alignItems: "center" }}><ErrorIcon
              sx={{ color: "red", marginRight: "2px" }} /> {error}</div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Button
              onClick={onClose}
              style={{ fontSize: "1.2rem" }}
            >
              {t('cancel')}
            </Button>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center"
              }}
            >
              {step === 1 && (
                <Button
                  onClick={() => setStep(step - 1)}
                  style={{ marginRight: "20px", fontSize: "1.2rem" }}
                >
                  {t('prev')}
                </Button>
              )}
              <Button
                onClick={handleNext}
                style={{ fontSize: "1.2rem" }}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        </div>
      }


      {(step === 2) && (
        <div className="d-flex flex-column justify-content-start align-items-start">
          {/* Updated button with icon and combined text */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              p: 2,
              mb: 2,
              borderRadius: 2,
              borderBottom: '1px solid #e8e8e8'
            }}
          >
            <CheckCircleOutlineIcon
              sx={{ mr: 2 }}
              color="success"  // Gives the icon a green color
            />
            <Typography sx={{fontSize: '1.1rem'}}>
              <strong>{t('space-saved')}</strong> — {t('select-next-action')}
            </Typography>
          </Box>

          <Button
            onClick={handleCreateWorkItem}
            style={{ marginBottom: "20px", fontSize: "1.2rem", width: '100%', height: '55px' }}
            variant="contained"
          >
            {t('create-a-work-item')}
          </Button>
          <Button
            onClick={() => handleSubmit(true)}
            style={{ marginBottom: "20px", fontSize: "1.2rem", width: '100%', height: '55px' }}
            variant="contained"
          >
            {t('continue-inspection')}
          </Button>
          <Button
            onClick={() => handleSubmit(false)}
            style={{ marginBottom: "20px", fontSize: "1.2rem", width: '100%', height: '55px' }}
            variant="contained"
          >
            {t('finished')}
          </Button>
        </div>
      )}
      {(step === 4) &&
        <div style={inspectionButtonContainerStyle(isMobile)}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%"
            }}
          >
            <Button
              onClick={onClose}
              style={{ fontSize: "1.2rem" }}
            >
              Close
            </Button>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Button
                onClick={handleRestart}
                style={{ fontSize: '1.2rem' }}
              >
                Continue Inspection
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  )
    ;
};

export default InspectTool;
