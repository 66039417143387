import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AverageScoreCard from "./average-score/AverageScoreCard";
import LastInspectionCard from "./last-inspection/LastInspectionCard";
import InspectionSuggestions from "./suggestions/InspectionSuggestions";
import { useSnapshot } from "valtio";
import { inspectionsStore } from "../../../../inspectionsStore";

const InspectionSummaryTiles = (props) => {

  const snap = useSnapshot(inspectionsStore);

  return (
    <Box sx={{ '& > :not(style)': { mb: 2, mt: 1 } }}>
      {snap.summary && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <AverageScoreCard
                value={snap.summary.avg_score?.toFixed(1) ?? 'N/A'}
                count={snap.summary.total_inspected_spaces}
                module={"Inspections"}
                height={150}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <LastInspectionCard height={150} />
            </Grid>
            <Grid item xs={12} md={4}>
             <InspectionSuggestions />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default InspectionSummaryTiles;
