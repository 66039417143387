import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { sageStore } from "../../../sage-virtual-assistant/sageStore";


const bubbleStyle = {
  margin: 2,
  padding: 3,
  marginTop: 4,
  maxWidth: '650px',
  paddingTop: 6,
  borderRadius: '15px',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '100%',
    left: '50%',
  }
};

const avatarStyle = {
  position: 'absolute',
  top: -35,
  left: 5,
  width: 95,
  height: 80,
};

const SagePrompter = ({ sageResponse, color='black', error }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    setDisplayedText("");
    sageStore.isTyping = true;
    let index = 0;
    const interval = setInterval(() => {
      setDisplayedText(sageResponse.slice(0, index));
      index++;
      if (index > sageResponse.length) {
        clearInterval(interval);
        sageStore.isTyping = false;
      }
    }, 16);

    return () => {
      clearInterval(interval);
      sageStore.isTyping = false;
    };

  }, [sageResponse]);

  return (
    <Box sx={{ padding: 1, paddingTop: 3 }}>
      <Paper elevation={3} sx={bubbleStyle}>
        <Box sx={{
          ...avatarStyle,
          borderRadius: '50%',
          overflow: 'hidden'
        }}>
          <img
            src={"/white-labeling/verde/media/menu-icons/virtual-assistant.png"}
            alt="Sage"
            style={{
              marginLeft: '3px',
              height: '100%',
              width: '85%',
              scale: '85%',
              objectPosition: 'center'
            }}
          />
        </Box>
        <Box>
          <Typography sx={{color: color}} variant="h6" gutterBottom>
            {displayedText}
          </Typography>
        </Box>
        {error && <CustomError error={error} />}
      </Paper>
    </Box>
  );
};

export default SagePrompter;