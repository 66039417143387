import { proxy } from "valtio";
import { Inspection } from "../../shared/models/inspection.model";
import { InspectionSummary } from "../../shared/models/inspectionSummary.model";
import { InspectionService } from "../../shared/services/inspections/inspectionService";
import { InspectedEntity } from "../../shared/models/inspectedEntity.model";
import { Entity } from "../../shared/models/entity.model";
import { inspectedEntityStore } from "./inspectedEntityStore";


interface InspectionsStore {
  inspections: Inspection[];
  inspectionToEdit: Inspection | null;
  inspectionBuilding: Entity | null;
  open: boolean;
  isLoading: boolean;
  isEditing: boolean;
  detailsLoading: boolean;
  limit: number;
  includeJoint: boolean;
  includeRegular: boolean;
  includeBaseline: boolean;
  summary: InspectionSummary;
  tabIndex: number;
  forceRefresh: boolean;

  handleHardReset: () => void;
  handleViewInspection: (item: Inspection) => void;
  handleDeleteInspection: (id: number) => Promise<void>;
  handleFetchInspectionDetails: (id: number) => Promise<void>;
}

export const inspectionsStore = proxy<InspectionsStore>({
  inspections: [],
  inspectionToEdit: null,
  inspectionBuilding: null,

  open: false,
  isLoading: false,
  detailsLoading: false,
  limit: 10,
  includeJoint: true,
  includeRegular: true,
  includeBaseline: true,
  isEditing: false,
  summary: new InspectionSummary(),
  tabIndex: 0,
  forceRefresh: false,

  handleViewInspection: (item: Inspection) => {
    inspectionsStore.inspectionBuilding = null;
    inspectionsStore.handleFetchInspectionDetails(item.id || 0).then(() => inspectionsStore.open = true);
    inspectionsStore.isEditing = false;
  },


  handleFetchInspectionDetails: async (id: number) => {
    inspectionsStore.detailsLoading = true;
    try {
      const service = new InspectionService();
      if (id && id !== 0) {
        inspectionsStore.inspectionToEdit = await service.getInspectionDetails(id);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      }
    } finally {
      inspectionsStore.detailsLoading = false;
    }
  },

  handleDeleteInspection: async (id: number) => {
      try {
        const service = new InspectionService();
        await service.deleteInspection(id);
      } catch (error) {
        console.error(error);
      }
    },

  handleHardReset: () => {
    inspectionsStore.inspectionToEdit = new Inspection();
    inspectionsStore.inspectionBuilding = null;
    inspectedEntityStore.inspectedEntity = new InspectedEntity();
    inspectedEntityStore.selectedBuilding = null;
    inspectedEntityStore.selectedFloor = null;
    inspectionsStore.tabIndex = 0;
  },


});



