import React, { useEffect, useState } from "react";
import PageHeader from "../../../shared/components/page/headers/PageHeader";
import { Tabs, Tab, Box, Select, MenuItem, FormControl, Button } from "@mui/material";
import { projectStore } from "../projectsStore";
import useAppStore from "../../../appStore";
import { ProjectsService } from "../../../shared/services/projects/projectsService";
import { ProjectPlan } from "../../../shared/models/projetPlan.model";
import { useSnapshot } from "valtio";
import CreateProjectPlan from "./components/create-plan/CreateProjectPlan";
import { SageError } from "../../../shared/models/sageError.model";
import ProjectsRankTab from "./components/tabs/rank/ProjectsRankTab";
import ProjectsPlanTab from "./components/tabs/plan/ProjectsPlanTab";
import ProjectsLaunchTab from "./components/tabs/launch/ProjectsLaunchTab";
import ProjectsTrackTab from "./components/tabs/track/ProjectsTrackTab";
import ProjectsCloseTab from "./components/tabs/close/ProjectsCloseTab";
import { Project } from "../../../shared/models/project.model";


const ProjectsManagePage = () => {
  const {selectedLocation, toggleMasterRefresh, masterRefresh} = useAppStore();
  const snap = useSnapshot(projectStore);
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const service = new ProjectsService();
  const [tabValue, setTabValue] = useState(0);
  const [projectPlans, setProjectPlans] = useState<ProjectPlan[]>([]);
  const [error, setError] = useState<string>('');
  const [creatingPlan, setCreatingPlan] = useState<boolean>(false);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plans = await service.getPlans(user?.organization, selectedLocation);
        setProjectPlans(plans);
        if (!projectStore.selectedPlan) {
          projectStore.selectedPlan = plans[0]
        }
      } catch (error) {
        console.error('Error fetching project plans:', error);
      }
    };

    if (user && selectedLocation) {
      fetchPlans().then();
    }

    fetchPlans().then();
  }, [user, selectedLocation, masterRefresh]);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    const modes = ['Rank', 'Plan', 'Launch', 'Track', 'Close'];
    projectStore.mode = modes[newValue];
    projectStore.open = false;
    projectStore.isEditing = false
  };

  const handlePlanSelected = (plan: ProjectPlan) => {
    projectStore.selectedPlan = plan;
    projectStore.selectedProject = null;
  };

  const handleNewPlan = () => {
    projectStore.selectedPlan = new ProjectPlan();
    projectStore.selectedProject = new Project();
    setCreatingPlan(true);
  }

  const handleSavePlan = async () => {
    try {
      if (!snap.selectedPlan) {
        throw new Error("No plan data to save");
      }

      let response;

      if (snap.selectedPlan.id !== 0) {
        // response = await service.updatePlan(projectStore.selectedPlan);
      } else {
        if (user?.organization && selectedLocation && projectStore.selectedPlan) {
          projectStore.selectedPlan.provider = user.organization
          projectStore.selectedPlan.location = selectedLocation;
          response = await service.createPlan(projectStore.selectedPlan);
          toggleMasterRefresh();
          projectStore.selectedPlan = response;
        } else {
          throw new Error("Organization data is missing for the new employee");
        }
      }

    } catch (e) {
      const errorMessage = e instanceof SageError ? e.message : JSON.stringify(e);
      setError(`Failed to update employee: ${errorMessage}`);
    }
  };

  const handlePlanEditAction = (action: string) => {
    if (action === 'save') {
      handleSavePlan().then();
      setCreatingPlan(false);
    } else {
      setCreatingPlan(false);
    }
  }

  return (
    <>
      <PageHeader
        title='Projects'
        showAction={false}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 2,
          marginBottom: 2
        }}
      >
        {/* The FormControl + Select will occupy 50% of the width */}
        <FormControl sx={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
          <Select
            value={snap.selectedPlan?.name || "Select Plan"}
            sx={{backgroundColor: 'white'}}
            className='flex-grow-1'
          >
            {projectPlans.map(plan => (
              <MenuItem
                key={plan.id}
                value={plan.name}
                onClick={() => handlePlanSelected(plan)}
              >
                {plan.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant={'contained'} sx={{ marginLeft: 'auto' }} onClick={handleNewPlan}>
          Create Plan
        </Button>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label="RANK" />
          <Tab label="PLAN" />
          <Tab label="LAUNCH" />
          <Tab label="TRACK" />
          <Tab label="CLOSE" />
        </Tabs>
      </Box>

      <Box sx={{ p: 2 }}>
        {tabValue === 0 && <ProjectsRankTab />}
        {tabValue === 1 && <ProjectsPlanTab />}
        {tabValue === 2 && <ProjectsLaunchTab />}
        {tabValue === 3 && <ProjectsTrackTab />}
        {tabValue === 4 && <ProjectsCloseTab />}
      </Box>

      {creatingPlan && (
        <CreateProjectPlan
          open={creatingPlan}
          onActionClicked={handlePlanEditAction}
        />
      )}
    </>
  );
};

export default ProjectsManagePage;

