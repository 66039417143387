import { Case } from "../../../../../../../shared/models/case.model";
import { useSnapshot } from "valtio";
import { workItemStore } from "../../../../../../work-items/workItemStore";
import useAppStore from "../../../../../../../appStore";
import React, { useEffect, useState } from "react";
import { WorkItem } from "../../../../../../../shared/models/workItem.model";
import { DateTime } from "luxon";
import { WorkItemsService } from "../../../../../../../shared/services/work-items/workItemsService";
import { Card } from "react-bootstrap";
import CustomLoadingDots from "../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../../shared/components/page/popup-dialog/CustomError";
import WhereAndWhatTable
  from "../../../../../../work-items/components/edit-work-item/components/where-and-what/WhereAndWhatTable";
import WorkItemForm from "../../../../../../work-items/components/edit-work-item/components/details/WorkItemForm";
import AssignedTo from "../../../../../../work-items/components/edit-work-item/components/assigned-to/AssignedTo";
import {
  AppBar,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme
} from "@mui/material";
import ModalTransition from "../../../../../../../shared/components/page/transitions/ModalTransition";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import casesStore from "../../../../../caseStore";
import { CasesService } from "../../../../../../../shared/services/cases/casesService";


interface CaseWorkItemProps {
  open: boolean;
  selectedCase?: Case;
  onActionClicked: (action: string) => void;
}

const CaseWorkItem: React.FC<CaseWorkItemProps> = ({ open, selectedCase, onActionClicked }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const snap = useSnapshot(workItemStore);
  const [prefill, setPrefill] = useState<WorkItem>(new WorkItem());
  const { selectedLocation, toggleMasterRefresh } = useAppStore();
  const [error, setError] = useState<string>("");
  const [isEditing, setIsEditing] = useState(snap.workItemToEdit.id === 0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initializeWorkItem = () => {
    workItemStore.workItemToEdit.due_date = DateTime.now().toUTC().plus({ days: 1 }).toISO();
    workItemStore.workItemToEdit.created_by = user.employee;
    workItemStore.workItemToEdit.location.id = selectedLocation?.id || user?.employee?.works_at?.[0]?.location?.id || 0;
    workItemStore.workItemToEdit.provider.id = user.organization?.id || 0;
  };


  useEffect(() => {
    if (selectedCase) {
      workItemStore.workItemToEdit.source = { type: 'Case', case: selectedCase, inspected_entity: undefined }
    }
  }, [selectedCase]);

  useEffect(() => {
    if (!snap.workItemToEdit.id) {
      initializeWorkItem();
    } else {
      const fetchWorkItemDetails = async () => {
        try {
          const service = new WorkItemsService();
          workItemStore.workItemToEdit = await service.getWorkItemDetails(snap.workItemToEdit.id);
        } catch (error) {
          console.error("Failed to fetch work item details:", error);
        }
      };
      fetchWorkItemDetails().then();
    }
  }, [snap.workItemToEdit.id]);

  const handleSaveCase = async () => {
    try {
      const service = new CasesService();
      await service.updateCase(casesStore.caseToEdit);
    } catch (e) {
      setError(`Failed to update case: ${e instanceof Error ? e.message : JSON.stringify(e)}`);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const service = new WorkItemsService();
      const isCreating = snap.workItemToEdit.id === 0;
      const response = isCreating
        ? await service.createWorkItem(workItemStore.workItemToEdit)
        : await service.updateWorkItem(workItemStore.workItemToEdit);
      casesStore.caseToEdit.has_work_items = true;
      casesStore.caseToEdit.work_item_id = response.id || 0;
      casesStore.caseToEdit.work_item_status = response.status || 'Unknown';
      handleSaveCase().then();
      onActionClicked("save");
    } catch (e) {
      console.error("Error in handleSubmit:", e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = async () => {
    workItemStore.workItemToEdit = new WorkItem();
    onActionClicked("cancel");
  };

  const autoFill = async (workItem) => {
    //TODO
  };

  const renderCard = (Component, props = {}) => (
    <Card style={{ boxShadow: "0 1px 2px rgba(0,0,0,0.1)", margin: "5px", marginBottom: "10px", borderRadius: "0px" }}>
      <Card.Body>
        <Component {...props} />
      </Card.Body>
    </Card>
  );

  if (isLoading) return <CustomLoadingDots />;
  if (error) {
    return <CustomError error={error} />;
  }
  ;
  if (!snap.workItemToEdit || !prefill) return null;


  return (
    <Dialog
      fullWidth={!isMobile} fullScreen={isMobile}
      maxWidth={isMobile ? undefined : "sm"}
      open={open}
      onClose={() => onActionClicked("close")}
      TransitionComponent={ModalTransition}
    >
      {/* AppBar at the top */}
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onActionClicked("close")}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent dividers sx={{ maxHeight: isMobile ? "100vh" : "75vh", overflowY: "auto" }}>
        <div style={{ zIndex: 1000 }}>
          {renderCard(WhereAndWhatTable, { fromInspection: null, isEditing, createPrefill: autoFill })}
          {renderCard(WorkItemForm, { isEditing })}
          {renderCard(AssignedTo, { isEditing })}
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
        >
          Save Work Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CaseWorkItem;